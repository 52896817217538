import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import {
  DatePicker,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
  Tag,
} from "antd";
import { DateFormat, DisplayNotification } from "../../../../../config";
import { MakeApiCall } from "../../../../../apis/make-api-call";
import dayjs from "dayjs";
import moment from "moment";

export default function (props) {
  const {
    show,
    onHide,
    operationType,
    selectedCampaign,
    setLoading,
    onSuccess,
    portfolioList,
  } = props;
  const [sending, setSending] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("PAUSED");
  const [budgetValue, setBudgetValue] = useState("");
  const [selectedBudgetType, setSelectedBudgetType] = useState(null);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [endDate, setEndDate] = useState(dayjs());

  const UpdateCampaignStatusAction = async (data) => {
    const response = await MakeApiCall(
      `advertising/update-bulk-campaign`,
      `put`,
      data,
      true
    );
    if (response?.status) {
      setLoading(true);
      setTimeout(() => {
        setSending(false);
        onSuccess();
        DisplayNotification("success", "Success", response?.message);
      }, [5000]);
    } else {
      setSending(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Update Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {operationType === "status" && (
            <div className="row">
              <div className="col-12 mb-4">
                <label className="fw-bold fs-6">Campaign Status</label>
              </div>
              <div className="col-12 mb-7">
                <Radio.Group
                  name="radiogroup"
                  //   defaultValue={"PAUSED"}
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                  }}
                >
                  <Radio value={"ENABLED"}>
                    <Tag className="fw-bolder" color={"green"}>
                      ENABLED
                    </Tag>
                  </Radio>
                  <Radio value={"PAUSED"}>
                    <Tag className="fw-bolder" color={"orange"}>
                      PAUSED
                    </Tag>
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          )}
          {operationType === "budget" && (
            <div className="row">
              <div className="col-12 col-md-6 mb-4">
                <label className="fw-bold fs-6">Budget</label>{" "}
                <InputNumber
                  placeholder="Enter Budget"
                  changeOnWheel
                  className="w-100 mt-1"
                  onChange={(e) => {
                    setBudgetValue(e);
                  }}
                  size="large"
                />
              </div>
              <div className="col-12 col-md-6  mb-4">
                <label className="fw-bold fs-6">Budget Type</label>{" "}
                <Select
                  placeholder="Select Budget Type"
                  className="w-100 mt-1"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  onChange={(e) => {
                    setSelectedBudgetType(e);
                  }}
                  size="large"
                  options={[
                    {
                      label: "Increase Budget %",
                      value: "increase_budget_by_per",
                    },
                    {
                      label: "Increase Budget by Value",
                      value: "increase_budget_by_value",
                    },
                    { label: "Set Budget", value: "set_budget" },
                    {
                      label: "Decrease Budget %",
                      value: "decrease_budget_by_per",
                    },
                    {
                      label: "Decrease Budget by Value",
                      value: "decrease_budget_by_value",
                    },
                  ]}
                />
              </div>
            </div>
          )}
          {operationType === "move_to_portfolio" && (
            <div className="row">
              <div className="col-12 mb-7">
                <label className="fw-bold fs-6">Portfolio</label>
                <Select
                  className="w-100 mt-1"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  size="large"
                  placeholder="Select Portfolio"
                  onChange={(e) => {
                    setSelectedPortfolio(e);
                  }}
                  value={selectedPortfolio}
                  options={portfolioList?.map((d) => {
                    return {
                      label: d?.name,
                      value: d?.portfolioId,
                    };
                  })}
                />
              </div>
            </div>
          )}
          {operationType === "end_date" && (
            <div className="row">
              <div className="col-12 mb-7">
                <label className="fw-bold fs-6">End Date</label>
                <DatePicker
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  format={DateFormat}
                  onChange={(date, dateString) => {
                    setEndDate(date);
                  }}
                  style={{ width: "100%" }}
                  size="large"
                  className="ant_common_input"
                  value={endDate ? dayjs(endDate) : null}
                />
              </div>
            </div>
          )}
          {operationType === "remove_portfolio" && (
            <div className="row">
              <div className="col-12 mb-7">
                <label className="fw-bold fs-4">Remove Portfolio</label>
                <div className="w-100 mt-4 text-danger fs-5 fw-bold">
                  Are you sure to remove selected campaign from portfolio?
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          class="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary fs-7"
          disabled={sending}
          onClick={() => {
            const data = {
              ids: selectedCampaign,
            };
            if (operationType === "status") {
              data.campaign_status = selectedStatus;
            }
            if (operationType === "budget") {
              data.budget_value = budgetValue;
              data.budget_type = selectedBudgetType;
            }
            if (operationType === "move_to_portfolio") {
              data.move_to_portfolio = selectedPortfolio;
            }
            if (operationType === "remove_portfolio") {
              data.remove_from_portfolio = "removed";
            }
            if (operationType === "end_date") {
              data.end_date = endDate
                ? moment(endDate.$d).format("YYYY-MM-DD")
                : null;
            }
            setSending(true);
            UpdateCampaignStatusAction(data);
          }}
        >
          Update
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
