import { message, Popover, Select, Skeleton } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { MakeApiCall } from "../../../apis/make-api-call";
import { GlobalContext } from "../../../commonContext";
import NoData from "../../../component/no-data";
import RangePickerESF from "../../../component/range-picker";
import {
  DateFormat,
  DefaultPerPage,
  DisplayNotification,
} from "../../../config";

const AdvertisementReport = () => {
  const contextValue = useContext(GlobalContext);

  const { user_data } = contextValue?.data;

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [all_filter, set_all_filter] = useState({
    page: 1,
    "per-page": DefaultPerPage,
  });

  const [reportCardListLoading, setReportCardListLoading] = useState(true);
  const [reportCardList, setReportCardList] = useState([
    "sponsored_products",
    "sponsored _brand",
    "sponsored_display",
    "sponsored_tv",
    "product_report",
    "inventory_report",
  ]);

  const [dateRange, setDateRange] = useState({
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs(),
  });
  const [isFileDownload, setIsFileDownload] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedMarketplace, setSelectedMarketplace] =
    useState("ATVPDKIKX0DER");

  useEffect(() => {
    localStorage.setItem("token_type", "");
    if (contextValue?.data?.user_type == 2) {
      if (localStorage.getItem("sub_user_list")) {
        GetReportCardList();
      } else {
        setReportCardListLoading(false);
        setReportCardList([]);
        DisplayNotification("error", "Error", "User not found");
      }
    } else {
      GetReportCardList();
    }
  }, [contextValue?.data?.selected_sub_user]);

  const GetReportCardList = async () => {
    const response = await MakeApiCall(
      `advertisement-report-type`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      const groupedEvents = response?.data?.reduce((acc, event) => {
        const { event_type, event_name, marketplace_id } = event;
        if (!acc[event_type]) {
          acc[event_type] = {
            marketplace_id: marketplace_id,
            event_names: [],
          };
        }
        acc[event_type].event_names.push(event_name);
        return acc;
      }, {});
      console.log(groupedEvents, "-groupedEvents");
      setReportCardListLoading(false);
      setReportCardList(groupedEvents);
    } else {
      setReportCardListLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GenerateReport = () => {
    setIsFileDownload(true);
    const defaultUrl = "https://api.bidbison.com/advertising/advertising/";
    const userId = JSON.parse(user_data)?.id;
    console.log(userId, "-userId");
    const data = {
      userId: btoa(userId),
      marketplace_id: selectedMarketplace,
      isExport: 1,
      start_date: dayjs(dateRange?.start_date).format("YYYY-MM-DD"),
      end_date: dayjs(dateRange?.end_date).format("YYYY-MM-DD"),
    };

    const urlPayload = `id=${data?.userId}&start_date=${data?.start_date}&end_date=${data?.end_date}&marketplace_id=${data?.marketplace_id}`;

    const selectedUrl =
      selectedReport === "spCampaigns"
        ? "sp-campaigns-report-export"
        : selectedReport === "spAdvertisedProduct"
        ? "sp-advertised-product-report-export"
        : selectedReport === "adGroups"
        ? "sp-ad-group-report-export"
        : selectedReport === "keywords"
        ? "sp-keywords-report-export"
        : selectedReport === "spSearchTerm"
        ? "sp-search-term-report-export"
        : selectedReport === "spTargeting"
        ? "sp-targeting-report-export"
        : selectedReport === "campaignPlacement"
        ? "sp-campaign-placement-report-export"
        : selectedReport === "spPurchasedProduct"
        ? "sp-purchased-product-report-export"
        : selectedReport === "sbCampaigns"
        ? "sb-campaigns-report-export"
        : selectedReport === "sbAdGroup"
        ? "sb-ad-group-report-export"
        : selectedReport === "keywords"
        ? "sb-keywords-report-export"
        : selectedReport === "sbPurchasedProduct"
        ? "sb-purchased-product-report-export"
        : selectedReport === "sbCampaignPlacement"
        ? "sb-campaign-placement-report-export"
        : selectedReport === "sbTargeting"
        ? "sb-targeting-report-export"
        : selectedReport === "GET_MERCHANT_LISTINGS_ALL_DATA"
        ? "product-report-export"
        : selectedReport === "sdAdGroup"
        ? "sd-ad-group-report-export"
        : selectedReport === "sdCampaigns"
        ? "sd-campaigns-report-export"
        : selectedReport === "sdTargeting"
        ? "sd-targeting-report-export"
        : selectedReport === "sdAdvertisedProduct"
        ? "sd-advertised-product-report-export"
        : selectedReport === "sdPurchasedProduct"
        ? "sd-purchased-product-report-export"
        : selectedReport === "stCampaigns"
        ? "st-campaigns-report-export"
        : selectedReport === "stTargeting"
        ? "st-targeting-report-export"
        : false;

    if (!selectedUrl) {
      return false;
    }
    if (selectedUrl === "product-report-export") {
      var apiUrl = `https://api.bidbison.com/report/report/product-report-export?id=${data?.userId}&marketplace_id=${data?.marketplace_id}`;
      message.destroy();
      message.loading("Loading...", 0);
      downloadFile(apiUrl);
    } else {
      var apiUrl = `${defaultUrl}${selectedUrl}?${urlPayload}`;
      message.destroy();
      message.loading("Loading...", 0);
      downloadFile(apiUrl);
    }
  };

  function downloadFile(url) {
    fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        setIsFileDownload(false);
        console.log(blob, "-----------------");
        if (validateBlobFile(blob)) {
          // Create a temporary URL for the blob
          const fileURL = URL.createObjectURL(blob);
          console.log(fileURL, "blob");

          // Create a link element
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = `${selectedReport}_From_${dayjs(
            dateRange?.start_date
          ).format("MMM-DD-YYYY")}_To_${dayjs(dateRange?.end_date).format(
            "MMM-DD-YYYY"
          )}.xlsx`; // Specify the desired filename

          // Simulate a click on the link to initiate the download
          message.destroy();
          link.click();

          // Clean up the temporary URL
          URL.revokeObjectURL(fileURL);
        } else {
          message.destroy();
          handleBlobError(blob);
        }
      })
      .catch((error) => {
        setIsFileDownload(false);
        message.destroy();
        message.error("Error in file");
      });
  }

  function validateBlobFile(blob) {
    if (
      blob.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return false;
    }
    return true;
  }

  const handleBlobError = async (blob) => {
    try {
      console.log(blob, "blob");
      const newBlob = await blob.text();
      message.destroy();
      message.error(newBlob.split(".")?.[0]);
    } catch (error) {
      return "Invalid File";
      // Handle the error accordingly
    }
  };

  // const GetReportList = async (filters) => {
  //   const response = await MakeApiCall(
  //     `advertising/get-campaigns${ConvertParams(filters)}`,
  //     `get`,
  //     null,
  //     true
  //   );
  //   if (response?.status) {
  //     setLoading(false);
  //     setList(response?.data?.records);
  //     setTotalPage(response?.data?.pagination?.totalCount);
  //   } else {
  //     setLoading(false);
  //     DisplayNotification("error", "Error", response?.message);
  //   }
  // };

  // const columns = [
  //   {
  //     title: "Report Type",
  //     dataIndex: "reportType",
  //     key: "reportType",
  //     fixed: "left",
  //     render: (text) => {
  //       return <span className="fw-bold text-primary">{text}</span>;
  //     },
  //   },
  //   {
  //     title: "Date Range",
  //     dataIndex: "dateRange",
  //     key: "dateRange",
  //   },
  //   {
  //     title: "Requested Date",
  //     dataIndex: "requestedDate",
  //     key: "requestedDate",
  //   },
  //   {
  //     title: "Downloaded Date",
  //     dataIndex: "downloadedDate",
  //     key: "downloadedDate",
  //   },
  //   {
  //     title: "Report Status",
  //     dataIndex: "reportStatus",
  //     key: "reportStatus",
  //     fixed: "right",
  //     render: (text) => {
  //       return (
  //         <button className="btn btn-light-primary btn-sm">Download</button>
  //       );
  //     },
  //   },
  // ];
  // const onPageNo = (e) => {
  //   set_all_filter({ ...all_filter, page: e });
  //   setLoading(true);
  //   const apiObj = {
  //     ...all_filter,
  //     page: e,
  //     "per-page": all_filter?.["per-page"],
  //   };
  //   GetReportList(apiObj);
  // };

  // const onPerPage = (e) => {
  //   set_all_filter({ ...all_filter, page: 1, "per-page": e });
  //   setLoading(true);
  //   const apiObj = {
  //     ...all_filter,
  //     page: 1,
  //     "per-page": e,
  //   };
  //   GetReportList(apiObj);
  // };

  // const customUpload = async (props) => {
  //   const { file, onSuccess, onError } = props;
  //   try {
  //     const formData = new FormData();
  //     formData.append("brand_entity_id", "ENTITY2NHGSLZ6H30WJ");
  //     formData.append("asset", file);
  //     formData.append("marketplace_id", "ATVPDKIKX0DER");

  //     const response = await MakeApiCall(
  //       `advertising/create-stores-assets`,
  //       `post`,
  //       formData,
  //       true,
  //       true
  //     );
  //     if (response?.status) {
  //       message.destroy();
  //       message.success("File upload success");
  //     } else {
  //       setLoading(false);
  //       DisplayNotification("error", "Error", response?.message);
  //     }
  //   } catch (error) {
  //     message.destroy();
  //     message.error("Error");
  //   }
  // };

  const loadingArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div className="container-fluid" id="kt_content_container">
      <div className="row gy-5 g-xl-5">
        <div className="card card-xxl-stretch mb-5 mb-xl-5 mt-5">
          <div className="card-header border-bottom-0">
            <div className="card-title m-0">
                <h3 className="fw-bold m-0 ">Reports List</h3>
              </div>
            <div className="card-toolbar"></div>
          </div>
          <div className="separator separator-dashed mt-1 mb-3" />
          <div
            className="card-body py-3"
            style={{ height: "700px", overflowY: "scroll" }}
          >
            <div className="row mb-10">
              {/* <div className="col-md-12 mt-5 mb-2">
                <h3 className="fw-bold">Advertising Report</h3>
              </div> */}
              {/* <Upload className="mb-10" customRequest={customUpload}>
                Upload
              </Upload> */}
              {reportCardListLoading ? (
                loadingArr.map((d) => {
                  return (
                    <div className="col-12 col-md-4 col-lg-3 mb-6">
                      <Skeleton.Button
                        active
                        block
                        style={{ height: "100px" }}
                      />
                    </div>
                  );
                })
              ) : Object?.entries(reportCardList)?.length > 0 ? (
                Object?.entries(reportCardList)?.map((d) => {
                  console.log(d, "-d");

                  return (
                    <div className="col-12 col-md-4 col-lg-3 mb-6 position-relative cornerCardReport ">
                      <Popover
                        content={
                          <div
                            style={{
                              height: "max-content",
                              maxWidth: "400px",
                            }}
                          >
                            {d?.[0] !== "Inventory Reports" ? (
                              <RangePickerESF
                                className="ant_common_input form-control d-flex text-gray-800"
                                variant="filled"
                                id="ivcsr_date_picker"
                                size="large"
                                allowClear={false}
                                style={{ width: "290px" }}
                                format={DateFormat}
                                value={[
                                  dateRange?.start_date,
                                  dateRange?.end_date,
                                ]}
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                                onChange={(e) => {
                                  const filters_ = dateRange;
                                  filters_.start_date = e?.[0];
                                  filters_.end_date = e?.[1];
                                  setDateRange({ ...filters_ });
                                }}
                              />
                            ) : (
                              ""
                            )}

                            <Select
                              size="large"
                              variant="filled"
                              placeholder="Select Type"
                              style={{ width: "290px" }}
                              className="mt-2"
                              options={d[1]?.event_names?.map((en) => {
                                return {
                                  label: (
                                    <span>
                                      {en === "GET_MERCHANT_LISTINGS_ALL_DATA"
                                        ? "Product Report"
                                        : en?.replace(/_/g, " ")}
                                    </span>
                                  ),
                                  value: en,
                                };
                              })}
                              onChange={(e) => {
                                setSelectedReport(e);
                              }}
                              allowClear
                            />
                            <div className="text-center">
                              <button
                                className="btn btn-primary mt-3 btn-sm"
                                onClick={() => {
                                  GenerateReport();
                                }}
                              >
                                Download
                              </button>
                            </div>
                          </div>
                        }
                        placement="bottom"
                        trigger={"click"}
                      >
                        <div
                          style={{
                            background: "rgb(10 10 12)",
                            padding: "9px 12px",
                            borderRadius: "9px",
                            cursor: "pointer",
                          }}
                        >
                          <div className="d-flex align-items-center ">
                            <div className="symbol symbol-50px me-4">
                              <div className="symbol-label fs-2 fw-semibold  text-gray-100">
                                <i className="ki-duotone ki-document fs-2x text-gray-900">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            <span
                              className="text-gray-800 text-hover-gray-900 fs-5 fw-bold"
                              style={{ textTransform: "capitalize" }}
                            >
                              {d?.[0]?.replace(/_/g, " ")?.toLowerCase()}
                            </span>
                          </div>
                        </div>
                      </Popover>
                    </div>
                  );
                })
              ) : (
                <NoData type="new" />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row gy-5 g-xl-5">
        <div className="card card-xxl-stretch mb-5 mb-xl-5 mt-5">
          <div className="card-header pt-5 border-bottom-0">
            <label className="card-title align-items-start flex-column">
              <span className="fw-bold text-dark fs-2">Reports History</span>
            </label>
            <div className="card-toolbar"></div>
          </div>
          <div className="separator separator-dashed mt-1 mb-3" />
          <div className="card-body py-3">
            <div className="table-responsive">
              {loading ? (
                <NoDataTable columns={columns} type="loading" />
              ) : list?.length > 0 ? (
                <EsfTable
                  columns={columns}
                  sticky={{
                    offsetHeader: "10px",
                  }}
                  scroll={{
                    x: "max-content",
                    y: "calc(100vh - 700px)",
                  }}
                  fixed="top"
                  dataSource={list}
                  rowKey="key"
                  loading={loading}
                  pagination={false}
                />
              ) : (
                <>
                  <NoDataTable columns={columns} type="no-data" />
                </>
              )}
            </div>
            <Pagination
              loading={loading || list?.length === 0}
              pageSize={all_filter?.["per-page"]}
              page={all_filter?.page}
              totalPage={totalPage}
              onPerPage={onPerPage}
              onPageNo={onPageNo}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AdvertisementReport;
