import React, { useContext, useEffect, useState } from "react";
import Pagination from "../../../../component/esf-table/lib/pagination";
import NoDataTable from "../../../../component/no-data-table";
import EsfTable from "../../../../component/esf-table";
import { Input, Popover, Select, Tag } from "antd";
import RangePickerESF from "../../../../component/range-picker";
import { GlobalContext } from "../../../../commonContext";
import {
  ConvertParams,
  DateFormat,
  DisplayNotification,
  GetMarketplaceDropdown,
  GetMarketplaceList,
  NumberWithCommas,
} from "../../../../config";
import { MakeApiCall } from "../../../../apis/make-api-call";
import dayjs from "dayjs";
import { metrics_filter } from "../../../../config";
const CampaignList = (props) => {
  const {
    selectedPortfolioData,
    setSelectedPortfolioData,
    selctedCampaignData,
    setSelctedCampaignData,
    isVisible,
    setIsVisible,
  } = props;

  const contextValue = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [list, set_list] = useState([]);
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");

  const [all_filters, set_all_filters] = useState({
    marketplace_id: null,
    start_date: dayjs().add(-90, "d"),
    end_date: dayjs(),
    portfolioId: "",
  });

  // Metrics Filter Start
  const [isOpenMetricsPopover, setIsOpenMetricsPopover] = useState(false);
  const [metrics_filter_obj, set_metrics_filter_obj] = useState([]);

  // const [metrics_filter, set_metrics_filter] = useState({
  //   attributes_dd_data: {
  //     acos: "ACOS",
  //     roas: "ROAS",
  //     spends: "Spends",
  //     sales: "Total Sales",
  //     cpc: "CPC",
  //     cvr: "Conversion Rate",
  //     unit_ordered: "Orders",
  //     clicks: "Clicks",
  //     impressions: "Impressions",
  //     ctr: "CTR",
  //     ad_sales_percentage: "Ad Sales Percentage",
  //     total_sales: "Total Sales",
  //     total_orders: "Total Orders",
  //     total_acos: "Total ACOS",
  //   },
  //   operators_dd_data: {
  //     ">=": ">=",
  //     "<=": "<=",
  //     ">": ">",
  //     "<": "<",
  //     "==": "=",
  //   },
  // });
  // Metrics Filter Start

  useEffect(() => {
    localStorage.setItem("token_type", "");
    if (contextValue?.data?.user_type == 2) {
      if (localStorage.getItem("sub_user_list")) {
        GetMarketplace();
      } else {
        setLoading(false);
        DisplayNotification("error", "Error", "User not found");
      }
    } else {
      GetMarketplace();
    }
  }, [contextValue?.data?.selected_sub_user]);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_all_filters({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
        portfolioId: selectedPortfolioData?.portfolioId,
      });
      GetCampaignList({
        start_date: dayjs(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: dayjs(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
        portfolioId: selectedPortfolioData?.portfolioId,
        metric_filter: [],
      });
    } else {
      setLoading(false);
    }
  };

  const GetCampaignList = async (filters) => {
    setLoading(true);
    const response = await MakeApiCall(
      `advertising/portfolio-campaigns${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      set_list(response?.data?.records);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const columns = [
    // {
    //   title: "#",
    //   width: 20,
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Campaingn", // Updated title
      width: 205,
      render: (text) => (
        <span
          className="fw-bold text-info"
          onClick={() => {
            setSelctedCampaignData(text);
            setIsVisible("ad_group");
          }}
          style={{ cursor: "pointer" }}
        >
          {text?.campaign_name || "-"}
        </span>
      ),
    },
    {
      title: "Campaingn Type", // Updated title
      dataIndex: "ad_type",
      key: "ad_type",
      width: 150,
      render: (text) => {
        return (
          <Tag
            className="fw-bold"
            color={
              text === "SPONSORED_PRODUCTS"
                ? "gold"
                : text === "SPONSORED_DISPLAY"
                ? "green"
                : text === "SPONSORED_BRAND"
                ? "geekblue"
                : "purple"
            }
            style={{ textTransform: "capitalize" }}
            // bordered={false}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Bid Multiplier (Top of Search)", // Updated title
      dataIndex: "top_of_search_placement",
      key: "top_of_search_placement",
      width: 150,
      render: (text) => <span className="">{text || "-"}</span>,
    },

    {
      title: "Bid Multiplier (Rest of Search)", // Updated title
      dataIndex: "rest_of_search_placement",
      key: "rest_of_search_placement",
      width: 150,
      render: (text) => <span className="">{text || "-"}</span>,
    },

    {
      title: "Bid Multiplier (Amazon Business)",
      dataIndex: "Ad",
      key: "Ad",
      width: 150,
      render: (text) => <span className="">{text || "-"}</span>,
    },
    {
      title: "Bid Multiplier (Product Pages)",
      dataIndex: "product_pages_placement",
      key: "product_pages_placement",
      width: 150,
      render: (text) => <span className="">{text || "-"}</span>,
    },
    {
      title: "Bid Multiplier (Other)",
      dataIndex: "Ad",
      key: "Ad",
      width: 150,
      render: (text) => <span className="">{text || "-"}</span>,
    },
    {
      title: "Campaingn State",
      dataIndex: "state",
      key: "state",
      width: 150,
      render: (text) => <span className="">{text || "-"}</span>,
    },
    {
      title: "Campaingn Budget",
      dataIndex: "budget_amount",
      key: "budget_amount",
      width: 150,
      render: (text) => <span className="">{text || "-"}</span>,
    },
    {
      title: "Active Budget",
      dataIndex: "Ad",
      key: "Ad",
      width: 150,
      render: (text) => <span className="">{text || "-"}</span>,
    },
    {
      title: "Campaingn End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: 150,
      render: (text) => <span className="">{text || "-"}</span>,
    },
    {
      title: "Campaingn Start Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 200,
      render: (created_at) => (
        <span>
          {dayjs(new Date(created_at * 1000)).format("MMM DD, YYYY hh:mm A")}
        </span>
      ),
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: 100,
      sorter: (a, b) => a.clicks - b.clicks,
      render: (text) => <span className="">{NumberWithCommas(text)}</span>,
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      width: 120,
      sorter: (a, b) => a.cost - b.cost,
      render: (text) => (
        <span className="">
          {selected_currency_sign}
          {NumberWithCommas(text)}
        </span>
      ),
    },
    {
      title: "ACoS",
      dataIndex: "acos",
      key: "acos",
      width: 100,
      sorter: (a, b) => a.cost - b.cost,
      render: (text) => <span className="">{NumberWithCommas(text)}%</span>,
    },
    {
      title: "Cost-per-click (CPC)",
      dataIndex: "CPC",
      key: "CPC",
      width: 200,
      sorter: (a, b) => a.CPC - b.CPC,
      render: (text) => {
        return (
          <span>
            {selected_currency_sign}
            {text || "0"}
          </span>
        );
      },
    },
    {
      title: "Cost-per-mile(CPM)",
      dataIndex: "CPM",
      key: "CPM",
      width: 200,
      sorter: (a, b) => a.CPM - b.CPM,
      render: (text) => {
        return <span>{NumberWithCommas(text)}</span>;
      },
    },
    {
      title: "Click-through rate for views (CTR)",
      dataIndex: "CTR",
      key: "CTR",
      width: 200,
      sorter: (a, b) => a.CTR - b.CTR,
      render: (text) => {
        return <span>{NumberWithCommas(text)}</span>;
      },
    },
    {
      title: "Return on ad spend (ROAS)",
      dataIndex: "RoAS",
      key: "RoAS",
      width: 200,
      sorter: (a, b) => a.RoAS - b.RoAS,
      render: (text, record) => {
        return (
          <span>
            {selected_currency_sign}
            {text}
          </span>
        );
      },
    },
    {
      title: "RoAS_per",
      dataIndex: "RoAS_per",
      key: "RoAS_per",
      width: 200,
      sorter: (a, b) => a.RoAS_per - b.RoAS_per,
      render: (text, record) => {
        return <span>{NumberWithCommas(text)}%</span>;
      },
    },
    {
      title: "SPC",
      dataIndex: "SPC",
      key: "SPC",
      width: 200,
      sorter: (a, b) => a.SPC - b.SPC,
      render: (text, record) => {
        return <span>{NumberWithCommas(text)}%</span>;
      },
    },
    {
      title: "SPM",
      dataIndex: "SPM",
      key: "SPM",
      width: 200,
      sorter: (a, b) => a.SPM - b.SPM,
      render: (text, record) => {
        return <span>{NumberWithCommas(text)}%</span>;
      },
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      width: 150,
      sorter: (a, b) => a.impressions - b.impressions,
      render: (text) => <span className="">{NumberWithCommas(text)}</span>,
    },
    {
      title: "Attributed Sales (14 Day)",
      dataIndex: "sales14d",
      key: "sales14d",
      width: 180,
      sorter: (a, b) => a.sales14d - b.sales14d,
      render: (text) => (
        <span className="">
          {selected_currency_sign}
          {NumberWithCommas(text)}
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid" id="kt_content_container">
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            <div className="card mb-3">
              {/*begin::Header*/}
              <div className="card-header border-0">
                <div className="d-flex flex-stack flex-wrap gap-4 p-2">
                  <div className="position-relative">
                    <h3 class="card-title align-items-start flex-column">
                      <span class="card-label fw-bold fs-3 mb-1">
                        <div className="btn btn-icon btn-primary btn-outline w-40px h-40px position-relative border-0 me-2">
                          <span onClick={() => setIsVisible("portfolio")}>
                            <i className="ki-outline ki-arrow-left fs-1 fadeInLeftInfinite" />
                          </span>
                        </div>
                        <Tag className="fs-5 py-1" color="geekblue">
                          Portfolio - {selectedPortfolioData?.name}
                        </Tag>
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0">
                <div className="d-flex flex-stack flex-wrap gap-4 p-2">
                  <div className="position-relative">
                    <h3 class="card-title align-items-start flex-column">
                      <span class="card-label fw-bold fs-3 mb-1">
                        Campaign List
                      </span>
                    </h3>
                  </div>

                  {/* <div className="position-relative">
                    <Input
                      prefix={
                        <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                      }
                      placeholder="Search by ASIN or SKU"
                      size="large"
                      value={all_filters?.search}
                      onChange={(e) => {
                        set_all_filters({
                          ...all_filters,
                          search: e.target.value,
                        });
                      }}
                      onPressEnter={(e) => {
                        const apiObj = {
                          ...all_filters,
                          page: 1,
                          "per-page": DefaultPerPage,
                          search: e.target.value,
                          start_date: dayjs(
                            all_filters?.start_date?.$d
                          ).format("YYYY-MM-DD"),
                          end_date: dayjs(all_filters?.end_date?.$d).format(
                            "YYYY-MM-DD"
                          ),
                        };
                        setLoading(true);
                        GetCampaignList(apiObj);
                      }}
                    />
                  </div> */}
                </div>
                <div className="card-toolbar">
                  <div class="d-flex flex-stack flex-wrap gap-4">
                    <div className="position-relative ">
                      <RangePickerESF
                        className="ant_common_input"
                        id="aalacart_date_picker"
                        size="large"
                        allowClear={false}
                        style={{ width: "290px" }}
                        format={DateFormat}
                        value={[all_filters?.start_date, all_filters?.end_date]}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onChange={(e) => {
                          const filters_ = all_filters;
                          filters_.start_date = e?.[0];
                          filters_.end_date = e?.[1];

                          const apiObj = {
                            ...all_filters,
                            start_date: dayjs(e?.[0]?.$d).format("YYYY-MM-DD"),
                            end_date: dayjs(e?.[1]?.$d).format("YYYY-MM-DD"),
                            metric_filter: metrics_filter_obj || [],
                          };
                          setLoading(true);
                          GetCampaignList(apiObj);
                        }}
                      />
                    </div>
                    <div className="position-relative me-2">
                      <Select
                        className="w-200px"
                        size="large"
                        placeholder="Select Marketplace"
                        options={GetMarketplaceList(marketplace_list)}
                        value={selected_marketplace}
                        onChange={(e) => {
                          set_selected_marketplace(e);
                          set_selected_currency_sign(currency_list[e]);
                          set_all_filters({
                            ...all_filters,
                            marketplace_id: e,
                          });
                          const apiObj = {
                            ...all_filters,
                            marketplace_id: e,
                            start_date: dayjs(
                              all_filters?.start_date?.$d
                            ).format("YYYY-MM-DD"),
                            end_date: dayjs(all_filters?.end_date?.$d).format(
                              "YYYY-MM-DD"
                            ),
                            metric_filter: metrics_filter_obj || [],
                          };
                          setLoading(true);
                          GetCampaignList(apiObj);
                        }}
                      />
                    </div>
                    <div className="position-relative me-2">
                      <Popover
                        placement="bottom"
                        trigger="click"
                        open={isOpenMetricsPopover}
                        content={
                          <div className="">
                            {metrics_filter_obj?.map((d, index) => {
                              return (
                                <div className="mt-3 d-flex">
                                  <Select
                                    placeholder="Select Metric"
                                    allowClear
                                    options={Object.entries(
                                      metrics_filter?.attributes_dd_data
                                    )?.map((d) => {
                                      return {
                                        label: d[1],
                                        value: d[0],
                                      };
                                    })}
                                    value={d?.attribute}
                                    onChange={(e) => {
                                      set_metrics_filter_obj((prev) => {
                                        return prev?.map((d, mindex) => {
                                          if (mindex === index) {
                                            return {
                                              ...d,
                                              attribute: e,
                                            };
                                          }
                                          return d;
                                        });
                                      });
                                    }}
                                    style={{ width: "200px" }}
                                  />
                                  <Select
                                    className="ms-1"
                                    allowClear
                                    options={Object.entries(
                                      metrics_filter?.operators_dd_data
                                    )?.map((d) => {
                                      return {
                                        label: d[1],
                                        value: d[0],
                                      };
                                    })}
                                    value={d?.operator}
                                    onChange={(e) => {
                                      set_metrics_filter_obj((prev) => {
                                        return prev?.map((d, mindex) => {
                                          if (mindex === index) {
                                            return {
                                              ...d,
                                              operator: e,
                                            };
                                          }
                                          return d;
                                        });
                                      });
                                    }}
                                    style={{ width: "100px" }}
                                    placeholder="Select Condition"
                                  />
                                  <Input
                                    style={{ width: "120px" }}
                                    className="ms-1"
                                    value={d?.value}
                                    onChange={(e) => {
                                      set_metrics_filter_obj((prev) => {
                                        return prev?.map((d, mindex) => {
                                          if (mindex === index) {
                                            return {
                                              ...d,
                                              value: e.target.value,
                                            };
                                          }
                                          return d;
                                        });
                                      });
                                    }}
                                    placeholder="Enter Value"
                                  />
                                  {metrics_filter_obj?.length > 1 && (
                                    <span
                                      className="btn btn-icon btn-light-primary btn-outline w-30px h-30px position-relative border-0 cursor-pointer ms-1"
                                      onClick={() => {
                                        const deletedData =
                                          metrics_filter_obj?.filter(
                                            (d, i) => i !== index
                                          );
                                        set_metrics_filter_obj(deletedData);
                                      }}
                                    >
                                      <i className="ki-outline ki-cross fs-2" />
                                    </span>
                                  )}
                                </div>
                              );
                            })}
                            <div className="text-center">
                              <button
                                className="btn btn-light-primary btn-sm mt-5"
                                onClick={() => {
                                  set_metrics_filter_obj([
                                    ...metrics_filter_obj,
                                    {
                                      attribute: null,
                                      operator: null,
                                      value: "",
                                    },
                                  ]);
                                }}
                              >
                                <i className="ki-outline ki-plus fs-2" />
                                Add another
                              </button>
                            </div>

                            <div className="separator separator-dashed my-2"></div>
                            <div className="d-flex justify-content-between">
                              <button
                                className="btn btn-light-danger btn-sm"
                                onClick={() => {
                                  set_metrics_filter_obj([]);
                                  setIsOpenMetricsPopover(false);
                                }}
                              >
                                {/* <i className="ki-outline ki-cross fs-2" /> */}
                                Clear all
                              </button>
                              <button
                                disabled={
                                  metrics_filter_obj.filter(
                                    (item) =>
                                      !item.attribute ||
                                      !item.operator ||
                                      !item.value
                                  )?.length > 0
                                }
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  const apiObj = {
                                    ...all_filters,
                                    metric_filter: metrics_filter_obj || [],
                                  };
                                  set_all_filters({
                                    ...all_filters,
                                  });
                                  setLoading(true);
                                  GetCampaignList({
                                    ...apiObj,
                                    start_date: dayjs(
                                      all_filters?.start_date?.$d
                                    ).format("YYYY-MM-DD"),
                                    end_date: dayjs(
                                      all_filters?.end_date?.$d
                                    ).format("YYYY-MM-DD"),
                                    metric_filter: metrics_filter_obj || [],
                                  });
                                  setIsOpenMetricsPopover(false);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        }
                      >
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            if (metrics_filter_obj?.length === 0) {
                              set_metrics_filter_obj([
                                {
                                  attribute: "",
                                  operator: "",
                                  value: "",
                                },
                              ]);
                            }
                            setIsOpenMetricsPopover(true);
                          }}
                        >
                          <i className="ki-outline ki-plus fs-2" />
                          Metrics{" "}
                        </button>
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
              {/*end::Header*/}{" "}
              <div className="separator separator-dashed my-2"></div>
              {/*begin::Body*/}
              <div className="card-body py-2">
                {/*begin::Table container*/}

                <div className="table-responsive">
                  {loading ? (
                    <NoDataTable columns={columns} type="loading" />
                  ) : list?.length > 0 ? (
                    <EsfTable
                      columns={columns?.map((d) => ({
                        ...d,
                        // width: d?.title?.length * 13,
                      }))}
                      sticky={{
                        offsetHeader: "10px",
                      }}
                      scroll={{
                        x: "max-content",
                        y: "calc(100vh - 300px)",
                      }}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={true}
                    />
                  ) : (
                    <>
                      <NoDataTable columns={columns} type="no-data" />
                    </>
                  )}
                </div>
                {/* <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={all_filters?.["per-page"]}
                  page={all_filters?.page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                /> */}
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </div>
    </>
  );
};

export default CampaignList;
