import React, { useState } from "react";
import PortfolioList from "./portfolio-list/portfolio-list";
import CampaignList from "./campaign-list/campaign-list";
import CampaignSubDetails from "./campaign-sub-details/campaign-sub-details";

const Portfolio = () => {
  const [isVisible, setIsVisible] = useState("portfolio");
  const [selectedPortfolioData, setSelectedPortfolioData] = useState({});
  const [selctedCampaignData, setSelctedCampaignData] = useState({});
  return (
    <>
      {isVisible === "portfolio" ? (
        <PortfolioList
          selectedPortfolioData={selectedPortfolioData}
          setSelectedPortfolioData={setSelectedPortfolioData}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        />
      ) : isVisible === "campaign" ? (
        <CampaignList
          selectedPortfolioData={selectedPortfolioData}
          setSelectedPortfolioData={setSelectedPortfolioData}
          selctedCampaignData={selctedCampaignData}
          setSelctedCampaignData={setSelctedCampaignData}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        />
      ) : (
        <CampaignSubDetails
          selectedPortfolioData={selectedPortfolioData}
          setSelectedPortfolioData={setSelectedPortfolioData}
          selctedCampaignData={selctedCampaignData}
          setSelctedCampaignData={setSelctedCampaignData}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        />
      )}
    </>
  );
};

export default Portfolio;
