import styled from "styled-components";

export const Wrapper = styled.div`

.ant-tabs-top >.ant-tabs-nav::before, .ant-tabs-bottom >.ant-tabs-nav::before, .ant-tabs-top >div>.ant-tabs-nav::before, .ant-tabs-bottom >div>.ant-tabs-nav::before {
    border-bottom: 1px dashed var(--bs-border-color);
}

.ant-tabs .ant-tabs-tab {
    padding: 12px 0;
    font-size: 15.6px;
    color: rgb(255 255 255 / 88%);
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
text-shadow:none !important;
}
  // .ant-tabs-tab {
  //   background-color: #fff !important;
  //   border-radius: 0.475rem !important;
  //   color: var(--bs-nav-link-color);
  //   font-size: 0.95rem !important;
  // }
  // .ant-tabs-tab-active {
  //   background-color: var(--bs-primary) !important;
  //   .ant-tabs-tab-btn {
  //     color: #fff !important;
  //   }
  // }
`;
