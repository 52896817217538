// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/campaign-management",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-chart-simple-2 fs-1"></i>
      </span>
    ),
    label: "Campaign Management",
    children: [
      {
        key: "/campaign",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/campaign", "Campaign"),
      },
      {
        key: "/portfolio",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/portfolio", "Portfolio"),
      },
      {
        key: "/ad-groups",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/ad-groups", "Ad groups"),
      },
      // {
      //   key: "/product-ads",
      //   icon: (
      //     <span className="menu-bullet">
      //       <span className="bullet bullet-dot" />
      //     </span>
      //   ),
      //   label: GetLinks("/product-ads", "Product Ads"),
      // },
      {
        key: "/targrting-reports",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: "Targeting",
        children: [
          {
            key: "/keyword-report",
            icon: (
              <span className="menu-bullet">
                <span className="bullet bullet-dot" />
              </span>
            ),
            label: GetLinks("/keyword-report", "Keyword Report"),
          },
          {
            key: "/keyword-search-terms-report",
            icon: (
              <span className="menu-bullet">
                <span className="bullet bullet-dot" />
              </span>
            ),
            label: GetLinks(
              "/keyword-search-terms-report",
              "Keyword Search Terms Report"
            ),
          },
          {
            key: "/product-audience-targeting",
            icon: (
              <span className="menu-bullet">
                <span className="bullet bullet-dot" />
              </span>
            ),
            label: GetLinks(
              "/product-audience-targeting",
              "Product & Audience Targeting"
            ),
          },
          {
            key: "/product-target-search-term",
            icon: (
              <span className="menu-bullet">
                <span className="bullet bullet-dot" />
              </span>
            ),
            label: GetLinks(
              "/product-target-search-term",
              "Product Target Search Term"
            ),
          },
        ],
      },
      {
        key: "/product-ads",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: "Product Ads",
        children: [
          {
            key: "/product-ads-list",
            icon: (
              <span className="menu-bullet">
                <span className="bullet bullet-dot" />
              </span>
            ),
            label: GetLinks("/product-ads-list", "Product Ads List"),
          },
          {
            key: "/product-list",
            icon: (
              <span className="menu-bullet">
                <span className="bullet bullet-dot" />
              </span>
            ),
            label: GetLinks("/product-list", "Product List"),
          },
        ],
      },

      {
        key: "/placement",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/placement", "Placement"),
      },
    ],
  },
];
