import { createGlobalStyle } from "styled-components";

export const AppStyleWrapper = createGlobalStyle`
  .card{
    // background-color: #000 !important;
    // border:1px solid #000 !important;
  }
 .shinnerLoader {
    -webkit-mask: linear-gradient(-60deg, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0.11), rgb(0, 0, 0) 70%) right center / 300% 100%;
    background-repeat: no-repeat;
    animation: 1.5s ease 0s infinite normal none running shinnerLoader;
  }

  @keyframes shinnerLoader{
    100% {
      -webkit-mask-position: left center;
    }
  }

  .fadeInLeftInfinite {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: fadeInLeftInfinite;
    animation-timing-function: alternate;
    z-index : 99
  }

  @keyframes fadeInLeftInfinite {
    from {
      opacity: 1;
      transform: translateX(25%);
    }
    to {
      opacity: 1;
    }
  }

  .fadeInLeftAllPage {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: fadeInLeftAllPage;
  }

  @keyframes fadeInLeftAllPage {
    0% {
      opacity: 0;
      transform: translateX(100px);
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }

  .authFadeInBottomRuleFilter {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: authFadeInBottomRuleFilter;
    z-index : 99
  }

  @keyframes authFadeInBottomRuleFilter {
    from {
      opacity: 0;
      transform: translateY(-10%);
    }
    to {
      opacity: 1;
    }
  }


.apexcharts-xaxistooltip.apexcharts-theme-light{
      background:#fff !important;
    }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100% !important;
    height: 100% !important;
    background-color: #000;
  }

  .popoverActionIconSingleLine {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    svg {
      font-size: 24px;
      color: #f8a3a8;
      cursor: pointer;
      &:hover {
        color: #f082b0;
      }
    }
  }

  .popoverActionIconTwoLine {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    svg {
      font-size: 24px;
      color: #f8a3a8;
      cursor: pointer;
      &:hover {
        color: #f082b0;
      }
    }
  }

  .ant-input{
    font-size: 14px !important;     
    font-weight: 500 !important;
    border-color: #363843;
  }

  .ant-input-sm {
    padding: 0px 7px;
  }

  .ant-input-lg {
    padding: 7px 11px !important;  
    line-height: 1.7 !important;
 }
  .ant-input::placeholder {
    color:#99a1b7 !important; 
  }
  .ant-input-affix-wrapper {
    padding: 0px 11px !important;
  }
  .ant-select-single .ant-select-lg .ant-select-selector{
    font-size: 13px !important;     
    font-weight: 500 !important;
    border-color: #dbdfe9;
    color:#99a1b7 !important; 
  }
  // .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector{
  //   border-color: #dbdfe9 !important;
  // }
  .ant-select-selection-placeholder{
    font-size: 13px !important;     
    font-weight: 500 !important;
    color:#99a1b7 !important; 
  }
  .ant-select-selection-item{
    font-size: 13px !important;     
    font-weight: 500 !important;
  }
  .active-keyword-div div div .active-keyword-hover{
    text-decoration: underline !important; /* Change this to the desired text color */
    color :blue;
  }

  .at-item {
		color: #3079ed; font-weight:bold; font-size:3em;
		
		animation-name: focus-in-expand-top;
		animation-duration: 0.9s;
		animation-timing-function: linear;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: none;
			
		/* shorthand
		animation: focus-in-expand-top 1s linear 0s 1 normal none;*/
	}
	@keyframes focus-in-expand-top {
			
		0% {
			letter-spacing:-.5em;
			transform:translateY(-300px);
			filter:blur(12px);
			opacity:0;
		}
		0% {
			transform:translateY(0);
			filter:blur(0);
			opacity:1;
		}
	}

  .tracking-in-expand-fwd {
	-webkit-animation: tracking-in-expand-fwd 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-expand-fwd 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  }

@-webkit-keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

.slit-in-vertical {
	-webkit-animation: slit-in-vertical 0.45s ease-out both;
	        animation: slit-in-vertical 0.45s ease-out both;
}

@-webkit-keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
}
@keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
}

  .cornerCardReport::before{
    content: '';
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 25px 25px 0;
    border-color: transparent #15171c transparent transparent;
    position: absolute;
    top: 0;
    right: 9px;
    border-radius: 0px 6px 0px 0px;   
  }

  .cornerCardReport::after{
    content: '';
    width: 25px;
    height: 25px;
    // border-style: solid;
    // border-width: 0 25px 25px 0;
    // border-color: transparent #ffc5c2 transparent transparent;
    background-image:linear-gradient(226deg, #7a7a7a, transparent, transparent);
    position: absolute;
    top: 0;
    right: 9px;
    border-radius: 0px 6px 0px 0px;   
    animation: flip-diagonal-1-bl 0.7s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  }

// ------------------------------------------------------------------------------------------------------------  animation 1
.flip-diagonal-1-bl {
	-webkit-animation: flip-diagonal-1-bl 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: flip-diagonal-1-bl 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}
@-webkit-keyframes flip-diagonal-1-bl {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, -180deg);
            transform: rotate3d(1, 1, 0, -180deg);
  }
}
@keyframes flip-diagonal-1-bl {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, -180deg);
            transform: rotate3d(1, 1, 0, -180deg);
  }
}



// ------------------------------------------------------------------------------------------------------------  animation 2
.flip-scale-down-diag-1 {
	-webkit-animation: flip-scale-down-diag-1 0.5s linear both;
	        animation: flip-scale-down-diag-1 0.5s linear both;
} 

@-webkit-keyframes flip-scale-down-diag-1 {
  0% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, 0deg);
            transform: scale(1) rotate3d(1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(0.4) rotate3d(1, 1, 0, -90deg);
            transform: scale(0.4) rotate3d(1, 1, 0, -90deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, -180deg);
            transform: scale(1) rotate3d(1, 1, 0, -180deg);
  }
}
@keyframes flip-scale-down-diag-1 {
  0% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, 0deg);
            transform: scale(1) rotate3d(1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(0.4) rotate3d(1, 1, 0, -90deg);
            transform: scale(0.4) rotate3d(1, 1, 0, -90deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, -180deg);
            transform: scale(1) rotate3d(1, 1, 0, -180deg);
  }
}


// ------------------------------------------------------------------------------------------------------------  animation 3
.swing-top-left-fwd {
	-webkit-animation: swing-top-left-fwd 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: swing-top-left-fwd 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes swing-top-left-fwd {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, -180deg);
            transform: rotate3d(-1, 1, 0, -180deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes swing-top-left-fwd {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, -180deg);
            transform: rotate3d(-1, 1, 0, -180deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}


// ------------------------------------------------------------------------------------------------------------  animation 3

.jello-horizontal {
	-webkit-animation: jello-horizontal 0.9s both;
	        animation: jello-horizontal 0.9s both;
}
          @-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
// ------------------------------------------------------------------------------------------------------------  animation 4

.active_campaign_boxshadow{
  box-shadow: 0px 0px 7px -5px #035091;
}

@media print {
  .active_campaign_boxshadow {
    background: #f6f6f6;
    border-radius: 12px;
    border: 1px solid #e7ebef,
  }
}

// .ant-upload-wrapper .ant-upload-list{
//   display: none !important;
//   }

  .ant-input-number-outlined.ant-input-number-disabled{
    color:#898989 !important;
  }

  .export-button {
    background: transparent !important;
    padding: 0 calc(1.5rem + 1px) !important;
    color: green !important;
    transition: 0.4s;
    height: 40px;
    padding-bottom: 2px !important;
    border: 1px dashed lightgreen !important;
    &:hover {
      color: darkgreen !important;
      background-color: lightgreen !important;
    }
    .anticon-cloud-upload {
      font-size: 20px;
      margin-right: 7px;
      position: relative;
      top: 4px;
    }
  }

  .ant-select-selection-item-content div div{
  display:none !important;}



   .ant-select .ant-select-selector {
       background: #15171c;
    border-radius: 0.65rem;
    border: 1px solid #2c2e36 !important;
    color: #fff !important;
  }

.ant-select-filled:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    background: #15171c !important;
    border-color: #e6332a;
    outline: 0;
}
  .ant-select-single.ant-select-lg.ant-select-open .ant-select-selection-item {
    color: rgba(255, 255, 255, 1);
}
  .ant-pagination-item-link span{
    top : 0px !important
  }
.ant-select .ant-select-arrow{
    color: #fff !important;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
    color: rgba(255, 255, 255, 1);
}

.ant-select-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 4px;
    color: rgba(255, 255, 255, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: Inter, Helvetica, sans-serif;
    position: absolute;
    top: -9999px;
    z-index: 1050;
    overflow: hidden;
    font-variant: initial;
    background-color:#1C1D22;
    border-radius: 8px;
    outline: none;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
}
  .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgb(255 255 255 / 88%);
    font-weight: 600;
    background-color: #0f1014a6;
}
.ant-select-dropdown .ant-select-item{
    color: rgb(255 255 255 / 88%);
    }

.ant-empty-normal .ant-empty-description {
    color: rgb(255 255 255 / 45%);
}
.ant-picker .ant-picker-suffix {
    color: rgb(255 255 255 / 54%);
}
.ant-picker-input input {
    color: #ffffff94 !important;
    font-weight: 600;
    font-size: 1rem !important;
}
.ant-picker .ant-picker-separator {
    color: rgb(255 255 255 / 25%);
}
.ant-picker-filled:focus, .ant-picker-filled:focus-within {
    outline: 0;
    border-color: var(--bs-gray-500);
    background-color: #1B1C22;
}

.ant-picker-dropdown .ant-picker-panel-container {
   background-color:#1C1D22;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
}
.ant-picker-dropdown .ant-picker-range-arrow::before {
    background: #1C1D22;
   
}
  .ant-picker-dropdown .ant-picker-cell-in-view {
    color: rgb(169 169 169 / 88%);
}
  .ant-picker-dropdown {
    color: rgb(255 255 255 / 88%);
}
    .ant-picker-cell-in-range::before {
    background: #0a0a0c !important;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff !important;
    background: #0a0a0c !important;
}
  
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled):before, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled):before {
    background: #0a0a0c;
}

.ant-picker-dropdown .ant-picker-header-view >button {
    color: rgb(255 255 255 / 88%);
    font-weight: inherit;
}

.ant-picker-dropdown .ant-picker-content th {
    height: 36px;
    color: rgb(255 255 255 / 88%);
    vertical-align: middle;
}
.ant-table-wrapper .ant-table-cell-fix-left, .ant-table-wrapper .ant-table-cell-fix-right {
    position: sticky !important;
    z-index: 2;
    background: #15171c !important;
} 
.ant-table-cell{
    background: #15171c !important;
    }

.ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td {
    background: #15171c !important;
}

.ant-table-wrapper .ant-table-ping-right .ant-table-cell-fix-right-first::after, .ant-table-wrapper .ant-table-ping-right .ant-table-cell-fix-right-last::after {
    box-shadow: inset -12px 0 16px -8px rgb(0 0 0 / 40%);
}
.ant-table-wrapper .ant-table-ping-left .ant-table-cell-fix-left-first::after, .ant-table-wrapper .ant-table-ping-left .ant-table-cell-fix-left-last::after{
    box-shadow:inset 12px 0 16px -8px rgb(0 0 0 / 40%)
}

.ant-drawer .ant-drawer-content {
    background: #15171C;
}

.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
    font-size: 15px;
}
  .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    background: #15171c;
}
`;
