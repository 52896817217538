import React from "react";
import { NumberWithCommas } from "../../../../config";
import { Skeleton } from "antd";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function (props) {
  const {
    currency_sign,
    activeCampaignCardData,
    activeCampaignCardLoading,
    performanceMetricsCardData,
    performanceMetricsCardLoading,
    boxStyle,
  } = props;

  return (
    <>
      {/* ------------------------------------------------------------------------------------------ Active Campaign Card */}
      <div className="row">
        <div className="col-12">
          <div
            className="card card-xxl-stretch mb-5 mb-xl-5 theme-dark-bg-body"
            style={{}}
          >
            <div className="card-body d-flex flex-column">
              <div className="d-flex flex-column mb-7">
                <span className="text-primary fw-bold fs-3">
                  Active Campaign
                </span>
              </div>

              {activeCampaignCardLoading && (
                <div className="row g-5 gy-7">
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block />
                  </div>
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block />
                  </div>
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block />
                  </div>
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block />
                  </div>
                </div>
              )}
              {!activeCampaignCardLoading && (
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-3 my-3">
                    <div
                      className="p-5"
                      style={{
                        ...boxStyle,
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <div className="mb-3">
                            <label
                              className="fs-5"
                              style={{ fontWeight: "500" }}
                            >
                              SP
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold fs-2">
                              {" "}
                              {NumberWithCommas(
                                activeCampaignCardData?.SP?.current_count || 0
                              )}
                            </label>
                          </div>
                        </div>
                        <div>
                          <Icon
                            icon="fluent-mdl2:product"
                            className={`ki-duotone ki-chart-line-up ${
                              activeCampaignCardData?.SP?.change_percentage > 0
                                ? "text-success"
                                : "text-danger "
                            } fs-1`}
                          />
                        </div>
                      </div>
                      <div className="separator separator-dashed my-1"></div>

                      <div className=" mt-2 d-flex justify-content-between">
                        <span className="text-muted fs-6">
                          {NumberWithCommas(
                            activeCampaignCardData?.SP?.prev_count || 0
                          )}
                        </span>
                        {activeCampaignCardData?.SP?.change > 0 ? (
                          <span className="badge badge-light-success fs-8">
                            <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                            {NumberWithCommas(
                              activeCampaignCardData?.SP?.change || 0
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-light-danger fs-8">
                            <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                            {NumberWithCommas(
                              activeCampaignCardData?.SP?.change || 0
                            )}
                            %
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 my-3">
                    <div className="p-5 " style={{ ...boxStyle }}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <div className="mb-3">
                            <label
                              className="fs-5"
                              style={{ fontWeight: "500" }}
                            >
                              SB
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold fs-2">
                              {NumberWithCommas(
                                activeCampaignCardData?.SB?.current_count || 0
                              )}
                            </label>
                          </div>
                        </div>
                        <div>
                          <i
                            className={`ki-duotone ki-chart-line-up ${
                              activeCampaignCardData?.SB?.change_percentage > 0
                                ? "text-success"
                                : "text-danger"
                            } fs-1`}
                          >
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-1"></div>
                      <div className=" mt-2 d-flex justify-content-between">
                        <span className="text-muted fs-6">
                          {NumberWithCommas(
                            activeCampaignCardData?.SB?.prev_count || 0
                          )}
                        </span>
                        {activeCampaignCardData?.SB?.change > 0 ? (
                          <span className="badge badge-light-success fs-8">
                            <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                            {NumberWithCommas(
                              activeCampaignCardData?.SB?.change || 0
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-light-danger fs-8">
                            <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                            {NumberWithCommas(
                              activeCampaignCardData?.SB?.change || 0
                            )}
                            %
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 my-3">
                    <div className="p-5 " style={{ ...boxStyle }}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <div className="mb-3">
                            <label
                              className="fs-5"
                              style={{ fontWeight: "500" }}
                            >
                              SD
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold fs-2">
                              {NumberWithCommas(
                                activeCampaignCardData?.SD?.current_count || 0
                              )}
                            </label>
                          </div>
                        </div>
                        <div>
                          {" "}
                          <Icon
                            icon="solar:display-broken"
                            className={`ki-duotone ki-chart-line-up ${
                              activeCampaignCardData?.SD?.change_percentage > 0
                                ? "text-success "
                                : "text-danger "
                            } fs-1`}
                          />
                        </div>
                      </div>
                      <div className="separator separator-dashed my-1"></div>
                      <div className=" mt-2 d-flex justify-content-between">
                        <span className="text-muted fs-6">
                          {NumberWithCommas(
                            activeCampaignCardData?.SD?.prev_count || 0
                          )}
                        </span>
                        {activeCampaignCardData?.SD?.change > 0 ? (
                          <span className="badge badge-light-success fs-8">
                            <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                            {NumberWithCommas(
                              activeCampaignCardData?.SD?.change || 0
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-light-danger fs-8">
                            <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                            {NumberWithCommas(
                              activeCampaignCardData?.SD?.change || 0
                            )}
                            %
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 my-3">
                    <div className="p-5 " style={{ ...boxStyle }}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <div className="mb-3">
                            <label
                              className="fs-5"
                              style={{ fontWeight: "500" }}
                            >
                              ST
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold fs-2">
                              {NumberWithCommas(
                                activeCampaignCardData?.ST?.current_count || 0
                              )}
                            </label>
                          </div>
                        </div>
                        <div>
                          {" "}
                          <Icon
                            icon="material-symbols:tv-outline"
                            className={`ki-duotone ki-chart-line-up ${
                              activeCampaignCardData?.ST?.change_percentage > 0
                                ? "text-success"
                                : "text-danger"
                            } fs-1`}
                          />
                        </div>
                      </div>
                      <div className="separator separator-dashed my-1"></div>
                      <div className=" mt-2 d-flex justify-content-between">
                        <span className="text-muted fs-6">
                          {NumberWithCommas(
                            activeCampaignCardData?.ST?.prev_count || 0
                          )}
                        </span>
                        {activeCampaignCardData?.ST?.change > 0 ? (
                          <span className="badge badge-light-success fs-8">
                            <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                            {NumberWithCommas(
                              activeCampaignCardData?.ST?.change || 0
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-light-danger fs-8">
                            <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                            {NumberWithCommas(
                              activeCampaignCardData?.ST?.change || 0
                            )}
                            %
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="separator separator-dashed my-5"></div> */}
              {/* {!activeCampaignCardLoading && (
                <div className="row g-5 gy-7">
                  <div className="col-6 col-md-6 col-lg-3">
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #ffbb18" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-bill text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                            <span className="path6" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-info fs-3">SP</span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              activeCampaignCardData?.SP?.current_count || 0
                            )}
                          </span>
                          <div className="m-0">
                            {activeCampaignCardData?.SP?.change_percentage >
                            0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {NumberWithCommas(
                                  activeCampaignCardData?.SP
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  activeCampaignCardData?.SP
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-md-6 col-lg-3">
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #ffbb18" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-chart-simple-3 text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-info fs-3">SB</span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              activeCampaignCardData?.SB?.current_count || 0
                            )}
                          </span>
                          <div className="m-0">
                            {activeCampaignCardData?.SB?.change_percentage >
                            0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {NumberWithCommas(
                                  activeCampaignCardData?.SB
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  activeCampaignCardData?.SB
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-md-6 col-lg-3">
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #ffbb18" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-screen text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-info fs-3">SD</span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              activeCampaignCardData?.SD?.current_count || 0
                            )}
                          </span>
                          <div className="m-0">
                            {activeCampaignCardData?.SD?.change_percentage >
                            0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {NumberWithCommas(
                                  activeCampaignCardData?.SD
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  activeCampaignCardData?.SD
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-md-6 col-lg-3">
                    <div
                      className="d-flex flex-stack"
                      style={{ borderLeft: "2px solid #ffbb18" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-screen text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="fw-bold text-info fs-3">SBV</span>
                        </div>

                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              activeCampaignCardData?.SBV?.current_count || 0
                            )}
                          </span>
                          <div className="m-0">
                            {activeCampaignCardData?.SBV?.change_percentage >
                            0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {NumberWithCommas(
                                  activeCampaignCardData?.SBV
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  activeCampaignCardData?.SBV
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------------------------------------------ Performance Card */}

      <div className="row ">
        <div className="col-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-5 theme-dark-bg-body">
            <div className="card-body d-flex flex-column">
              <div className="d-flex flex-column mb-7">
                <span className="text-primary fw-bold fs-3">
                  Performance
                </span>
              </div>

              {performanceMetricsCardLoading && (
                <div className="row g-5 gy-7">
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                  <div className="col-6 col-md-6 col-lg-3">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                </div>
              )}
              {!performanceMetricsCardLoading && (
                <div className="row">
                  <div className="col-12 col-lg-6 col-xl-3">
                    {" "}
                    <div
                      style={{
                        border: "1px dashed rgb(255 255 255 / 12%)",
                        background:'rgb(15, 16, 20)',
                        borderRadius: "8px",
                      }}
                      className="p-4 my-2"
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <div>
                            <label className="fw-semibold fs-5 text-gray-600">
                              Ad Spend
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold fs-2">
                              {currency_sign}
                              {NumberWithCommas(
                                performanceMetricsCardData?.spend?.current || 0
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="symbol symbol-40px me-4">
                          <span
                            className={`symbol-label ${
                              performanceMetricsCardData?.spend
                                ?.change_percentage > 0
                                ? `bg-light-success`
                                : "bg-light-danger"
                            }`}
                          >
                            <Icon
                              icon="iconoir:coin"
                              className={`${
                                performanceMetricsCardData?.spend
                                  ?.change_percentage > 0
                                  ? "text-success"
                                  : " text-danger"
                              } ki-chart-pie-3 fs-1`}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-2"></div>
                      <div className="d-flex justify-content-between">
                        <span className="text-muted fs-6">
                          {currency_sign}
                          {NumberWithCommas(
                            performanceMetricsCardData?.spend?.prev || 0
                          )}
                        </span>
                        {performanceMetricsCardData?.spend?.change_percentage >
                        0 ? (
                          <span className="badge badge-light-success fs-8">
                            <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.spend
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-light-danger fs-8">
                            <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.spend
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-6 col-xl-3">
                    {" "}
                    <div
                      style={{
                        border: "1px dashed rgb(255 255 255 / 12%)",
                        background:'rgb(15, 16, 20)',
                        borderRadius: "8px",
                      }}
                      className="p-4 my-2"
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <div>
                            <label className="fw-semibold fs-5 text-gray-600">
                              Ad Sales
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold fs-2">
                              {currency_sign}
                              {NumberWithCommas(
                                performanceMetricsCardData?.sales?.current || 0
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="symbol symbol-40px me-4">
                          <span
                            className={`symbol-label ${
                              performanceMetricsCardData?.spend
                                ?.change_percentage > 0
                                ? `bg-light-success`
                                : "bg-light-danger"
                            }`}
                          >
                            {" "}
                            <Icon
                              icon="icon-park-outline:sales-report"
                              className={` ${
                                performanceMetricsCardData?.spend
                                  ?.change_percentage > 0
                                  ? "text-success"
                                  : " text-danger"
                              } fs-1`}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-2"></div>
                      <div className="d-flex justify-content-between">
                        <span className="text-muted fs-6">
                          {currency_sign}
                          {NumberWithCommas(
                            performanceMetricsCardData?.sales?.prev || 0
                          )}
                        </span>
                        {performanceMetricsCardData?.sales?.change_percentage >
                        0 ? (
                          <span className="badge badge-light-success fs-8">
                            <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.sales
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-light-danger fs-8">
                            <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.sales
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-6 col-xl-3">
                    {" "}
                    <div
                      style={{
                        border: "1px dashed rgb(255 255 255 / 12%)",
                        background:'rgb(15, 16, 20)',
                        borderRadius: "8px",
                      }}
                      className="p-4 my-2"
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <div>
                            <label className="fw-semibold fs-5 text-gray-600">
                              Total Sales
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold fs-2">
                              {currency_sign}
                              {NumberWithCommas(
                                performanceMetricsCardData?.total_sales
                                  ?.current || 0
                              )}
                            </label>
                          </div>
                        </div>
                        <div className="symbol symbol-40px me-4">
                          <span
                            className={`symbol-label ${
                              performanceMetricsCardData?.total_sales
                                ?.change_percentage > 0
                                ? `bg-light-success`
                                : "bg-light-danger"
                            }`}
                          >
                            <i
                              className={`ki-duotone ${
                                performanceMetricsCardData?.total_sales
                                  ?.change_percentage > 0
                                  ? "text-success"
                                  : " text-danger"
                              } ki-chart-pie-3 fs-1`}
                            >
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                            </i>
                          </span>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-2"></div>
                      <div className="d-flex justify-content-between">
                        <span className="text-muted fs-6">
                          {currency_sign}
                          {NumberWithCommas(
                            performanceMetricsCardData?.total_sales?.prev || 0
                          )}
                        </span>
                        {performanceMetricsCardData?.total_sales
                          ?.change_percentage > 0 ? (
                          <span className="badge badge-light-success fs-8">
                            <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.total_sales
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-light-danger fs-8">
                            <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.total_sales
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-6 col-xl-3">
                    {" "}
                    <div
                      style={{
                        border: "1px dashed rgb(255 255 255 / 12%)",
                        background:'rgb(15, 16, 20)',
                        borderRadius: "8px",
                      }}
                      className="p-4 my-2"
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <div>
                            <label className="fw-semibold fs-5 text-gray-600">
                              ACoS
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold fs-2">
                              {NumberWithCommas(
                                performanceMetricsCardData?.acos?.current || 0
                              )}
                              %
                            </label>
                          </div>
                        </div>
                        <div className="symbol symbol-40px me-4">
                          <span
                            className={`symbol-label ${
                              performanceMetricsCardData?.acos
                                ?.change_percentage > 0
                                ? `bg-light-success`
                                : "bg-light-danger"
                            }`}
                          >
                            {" "}
                            <Icon
                              icon="ic:outline-percent"
                              className={` ${
                                performanceMetricsCardData?.acos
                                  ?.change_percentage > 0
                                  ? "text-success"
                                  : " text-danger"
                              } fs-1`}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-2"></div>
                      <div className="d-flex justify-content-between">
                        <span className="text-muted fs-6">
                          {NumberWithCommas(
                            performanceMetricsCardData?.acos?.prev || 0
                          )}{" "}
                          %
                        </span>
                        {performanceMetricsCardData?.acos?.change_percentage >
                        0 ? (
                          <span className="badge badge-light-success fs-8">
                            <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.acos
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-light-danger fs-8">
                            <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.acos
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <div style={{ borderRight: "1px solid #dbdfe9" }}></div> */}
                  <div
                    style={{
                      width: "280px",
                    }}
                    className="p-2 my-2 d-none"
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                        <div>
                          <label className="fw-semibold fs-5 text-gray-600">
                            TACoS
                          </label>
                        </div>
                        <div>
                          <label className="fw-bold fs-2">
                            {currency_sign}
                            {NumberWithCommas(
                              performanceMetricsCardData?.tacos?.current || 0
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="symbol symbol-40px me-4">
                        <span
                          className={`symbol-label ${
                            performanceMetricsCardData?.tacos
                              ?.change_percentage > 0
                              ? `bg-light-success`
                              : "bg-light-danger"
                          }`}
                        >
                          <i
                            className={`ki-duotone ${
                              performanceMetricsCardData?.tacos
                                ?.change_percentage > 0
                                ? "text-success"
                                : " text-danger"
                            } ki-chart-pie-3 fs-1`}
                          >
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-2"></div>
                    <div>
                      {performanceMetricsCardData?.tacos?.change_percentage >
                      0 ? (
                        <span className="badge badge-light-success fs-8">
                          <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                          {NumberWithCommas(
                            performanceMetricsCardData?.tacos
                              ?.change_percentage || 0
                          )}
                          %
                        </span>
                      ) : (
                        <span className="badge badge-light-danger fs-8">
                          <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                          {NumberWithCommas(
                            performanceMetricsCardData?.tacos
                              ?.change_percentage || 0
                          )}
                          %
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="separator separator-dashed my-5"></div>
              {!performanceMetricsCardLoading && (
                <div className="row g-5 gy-7">
                  <div
                    className="col-6 col-md-3 col-lg-2"
                    style={{ borderLeft: "2px solid #4318ff" }}
                  >
                    <div className="d-flex flex-stack ">
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-dollar text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            $
                            {NumberWithCommas(
                              performanceMetricsCardData?.spend?.current || 0
                            )}
                          </span>
                          <span className="fw-bold text-info fs-3">
                            Ad Spend
                          </span>
                          <div className="d-flex align-items-center">
                            {performanceMetricsCardData?.spend
                              ?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.spend
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.spend
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-6 col-md-3 col-lg-2"
                    style={{ borderLeft: "2px solid #4318ff" }}
                  >
                    <div className="d-flex flex-stack ">
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-chart-pie-3  text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            $
                            {NumberWithCommas(
                              performanceMetricsCardData?.sales?.current || 0
                            )}
                          </span>
                          <span className="fw-bold text-info fs-3">
                            Ad Sales
                          </span>
                          <div className="d-flex align-items-center">
                            {performanceMetricsCardData?.sales
                              ?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.sales
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.sales
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-6 col-md-3 col-lg-2"
                    style={{ borderLeft: "2px solid #4318ff" }}
                  >
                    <div className="d-flex flex-stack ">
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-chart-pie-3  text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            $
                            {NumberWithCommas(
                              performanceMetricsCardData?.total_sales
                                ?.current || 0
                            )}
                          </span>
                          <span className="fw-bold text-info fs-3">
                            Total Sales
                          </span>
                          <div className="d-flex align-items-center">
                            {performanceMetricsCardData?.total_sales
                              ?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.total_sales
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.total_sales
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-6 col-md-3 col-lg-2"
                    style={{ borderLeft: "2px solid #4318ff" }}
                  >
                    <div className="d-flex flex-stack ">
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-graph-up text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                            <span className="path6" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              performanceMetricsCardData?.acos?.current || 0
                            )}
                            %
                          </span>
                          <span className="fw-bold text-info fs-3">ACOS</span>
                          <div className="d-flex align-items-center">
                            {performanceMetricsCardData?.acos
                              ?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.acos
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.acos
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-6 col-md-3 col-lg-2"
                    style={{ borderLeft: "2px solid #4318ff" }}
                  >
                    <div className="d-flex flex-stack ">
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-graph-up text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                            <span className="path6" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              performanceMetricsCardData?.tacos?.current || 0
                            )}
                            %
                          </span>
                          <span className="fw-bold text-info fs-3">TACoS</span>
                          <div className="d-flex align-items-center">
                            {performanceMetricsCardData?.tacos
                              ?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.tacos
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.tacos
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------------------------------------------ Conversion Card */}
      <div className="row ">
        <div className="col-sm-6 col-md-6 col-xxl-6">
          <div className="card card-xxl-stretch">
            {/* <div className="card-header border-bottom-0 min-h-45px">
              <h3 className="card-title align-items-start flex-column">
                <span className="text-primary fw-bold fs-3">
                  
                </span>
              </h3>
              <div className="card-toolbar"></div>
            </div> */}

            <div className="card-body py-2 pt-5">
            <div className="d-flex flex-column mb-7">
                <span className="text-primary fw-bold fs-3">
                Conversion
                </span>
              </div>
              {performanceMetricsCardLoading && (
                <div className="row g-5 gy-7">
                  <div className="col-6 col-md-6 col-lg-4">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                  <div className="col-6 col-md-6 col-lg-4">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                  <div className="col-6 col-md-6 col-lg-4">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                </div>
              )}
              {!performanceMetricsCardLoading && (
                <div className="row mb-5">
                  <div className="col-12 col-lg-12 col-xl-4 my-3">
                    <div
                      style={{
                        border: "1px dashed rgb(255 255 255 / 12%)",
                        background:'rgb(15, 16, 20)',
                        borderRadius: "8px",
                      }}
                      className="p-3 position-relative"
                    >
                      <div className="d-flex">
                        <div>
                          <div>
                            <label className="fw-semibold fs-5 text-gray-600">
                              Clicks
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold fs-2">
                              {NumberWithCommas(
                                performanceMetricsCardData?.clicks?.current || 0
                              )}
                            </label>
                          </div>
                        </div>
                        <div
                          className="symbol symbol-40px me-4 position-absolute"
                          style={{ right: "0px", top: "-15px" }}
                        >
                          <span
                            className={`symbol-label ${
                              performanceMetricsCardData?.clicks
                                ?.change_percentage > 0
                                ? "bg-light-success"
                                : "bg-light-danger"
                            }`}
                          >
                            <Icon
                              icon="mynaui:click"
                              className={` ${
                                performanceMetricsCardData?.clicks
                                  ?.change_percentage > 0
                                  ? "text-success"
                                  : "text-danger"
                              } fs-1`}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-2"></div>
                      <div className="d-flex justify-content-between">
                        <span className="text-muted fs-6">
                          {NumberWithCommas(
                            performanceMetricsCardData?.clicks?.prev || 0
                          )}{" "}
                        </span>
                        {performanceMetricsCardData?.clicks?.change_percentage >
                        0 ? (
                          <span className="badge badge-light-success fs-8">
                            <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.clicks
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-light-danger fs-8">
                            <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.clicks
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-12 col-xl-4 my-3">
                    <div
                      style={{
                        border: "1px dashed rgb(255 255 255 / 12%)",
                        background:'rgb(15, 16, 20)',
                        borderRadius: "8px",
                      }}
                      className="p-3 position-relative"
                    >
                      <div className="d-flex">
                        <div>
                          <div>
                            <label className="fw-semibold fs-5 text-gray-600">
                              Orders
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold fs-2">
                              {NumberWithCommas(
                                performanceMetricsCardData?.orders?.current || 0
                              )}
                            </label>
                          </div>
                        </div>
                        <div
                          className="symbol symbol-40px me-4 position-absolute"
                          style={{ right: "0px", top: "-15px" }}
                        >
                          <span
                            className={`symbol-label ${
                              performanceMetricsCardData?.orders
                                ?.change_percentage > 0
                                ? "bg-light-success"
                                : "bg-light-danger"
                            }`}
                          >
                            {" "}
                            <Icon
                              icon="material-symbols:orders-outline"
                              className={` ${
                                performanceMetricsCardData?.orders
                                  ?.change_percentage > 0
                                  ? "text-success"
                                  : "text-danger"
                              } fs-1`}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-2"></div>
                      <div className="d-flex justify-content-between">
                        <span className="text-muted fs-6">
                          {NumberWithCommas(
                            performanceMetricsCardData?.orders?.prev || 0
                          )}{" "}
                        </span>
                        {performanceMetricsCardData?.orders?.change_percentage >
                        0 ? (
                          <span className="badge badge-light-success fs-8">
                            <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.orders
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-light-danger fs-8">
                            <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.orders
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-12 col-xl-4 my-3">
                    <div
                      style={{
                        border: "1px dashed rgb(255 255 255 / 12%)",
                        background:'rgb(15, 16, 20)',
                        borderRadius: "8px",
                      }}
                      className="p-3 position-relative"
                    >
                      <div className="d-flex">
                        <div>
                          <div>
                            <label className="fw-semibold fs-5 text-gray-600">
                              CVR
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold fs-2">
                              {NumberWithCommas(
                                performanceMetricsCardData?.cvr?.current || 0
                              )}
                            </label>
                          </div>
                        </div>
                        <div
                          className="symbol symbol-40px me-4 position-absolute"
                          style={{ right: "0px", top: "-15px" }}
                        >
                          <span
                            className={`symbol-label ${
                              performanceMetricsCardData?.cvr
                                ?.change_percentage > 0
                                ? "bg-light-success"
                                : "bg-light-danger"
                            }`}
                          >
                            {" "}
                            <Icon
                              icon="material-symbols-light:star-rate-outline"
                              style={{ fontSize: "35px" }}
                              className={` ${
                                performanceMetricsCardData?.cvr
                                  ?.change_percentage > 0
                                  ? "text-success"
                                  : " text-danger"
                              }`}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-2"></div>
                      <div className="d-flex justify-content-between">
                        <span className="text-muted fs-6">
                          {NumberWithCommas(
                            performanceMetricsCardData?.cvr?.prev || 0
                          )}{" "}
                        </span>
                        {performanceMetricsCardData?.cvr?.change_percentage >
                        0 ? (
                          <span className="badge badge-light-success fs-8">
                            <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.cvr
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-light-danger fs-8">
                            <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.cvr
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="separator separator-dashed my-5"></div>
              {!performanceMetricsCardLoading && (
                <div className="row g-4 mb-5">
                  <div className="col-md-4">
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4caf50" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-click text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              performanceMetricsCardData?.clicks?.current || 0
                            )}
                          </span>
                          <span className="fw-bold text-info fs-3">Clicks</span>
                          <div className="d-flex align-items-center">
                            {performanceMetricsCardData?.clicks
                              ?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.clicks
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.clicks
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4caf50" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-click text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              performanceMetricsCardData?.orders?.current || 0
                            )}
                          </span>
                          <span className="fw-bold text-info fs-3">Orders</span>
                          <div className="d-flex align-items-center">
                            {performanceMetricsCardData?.orders
                              ?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.orders
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.orders
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #4caf50" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-click text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              performanceMetricsCardData?.cvr?.current || 0
                            )}
                            %
                          </span>
                          <span className="fw-bold text-info fs-3">CVR</span>
                          <div className="d-flex align-items-center">
                            {performanceMetricsCardData?.cvr
                              ?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.cvr
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.cvr
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-6 col-xxl-6">
          <div className="card card-xxl-stretch">
            <div className="card-body py-2 pt-5">
            <div className="d-flex flex-column mb-7">
                <span className="text-primary fw-bold fs-3">
                Efficiency
                </span>
              </div>
              {performanceMetricsCardLoading && (
                <div className="row g-5 gy-7">
                  <div className="col-6 col-md-6 col-lg-4">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                  <div className="col-6 col-md-6 col-lg-4">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                  <div className="col-6 col-md-6 col-lg-4">
                    <Skeleton.Button active block style={{ height: "100px" }} />
                  </div>
                </div>
              )}
              {!performanceMetricsCardLoading && (
                <div className="row mb-5">
                  <div className="col-12 col-lg-12 col-xl-4 my-3">
                    <div
                      style={{
                        border: "1px dashed rgb(255 255 255 / 12%)",
                        background:'rgb(15, 16, 20)',
                        borderRadius: "8px",
                      }}
                      className="p-3 position-relative"
                    >
                      <div className="d-flex">
                        <div>
                          <div>
                            <label className="fw-semibold fs-5 text-gray-600">
                              CPC
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold fs-2">
                              {currency_sign}
                              {NumberWithCommas(
                                performanceMetricsCardData?.cpc?.current || 0
                              )}
                            </label>
                          </div>
                        </div>
                        <div
                          className="symbol symbol-40px me-4 position-absolute"
                          style={{ right: "0px", top: "-15px" }}
                        >
                          <span
                            className={`symbol-label ${
                              performanceMetricsCardData?.cpc
                                ?.change_percentage > 0
                                ? "bg-light-success"
                                : "bg-light-danger"
                            }`}
                          >
                            {" "}
                            <Icon
                              icon="carbon:cost-total"
                              className={` ${
                                performanceMetricsCardData?.cpc
                                  ?.change_percentage > 0
                                  ? "text-success"
                                  : " text-danger"
                              } fs-1`}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-2"></div>
                      <div className="d-flex justify-content-between">
                        <span className="text-muted fs-6">
                          {currency_sign}{" "}
                          {NumberWithCommas(
                            performanceMetricsCardData?.cpc?.prev || 0
                          )}{" "}
                        </span>
                        {performanceMetricsCardData?.cpc?.change_percentage >
                        0 ? (
                          <span className="badge badge-light-success fs-8">
                            <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.cpc
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-light-danger fs-8">
                            <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.cpc
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        )}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-12 col-lg-12 col-xl-4 my-3">
                    <div
                      style={{
                        border: "1px dashed rgb(255 255 255 / 12%)",
                        background:'rgb(15, 16, 20)',
                        borderRadius: "8px",
                      }}
                      className="p-3 position-relative"
                    >
                      <div className="d-flex">
                        <div>
                          <div>
                            <label className="fw-semibold fs-5 text-gray-600">
                              SPC
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold fs-2">
                              {currency_sign}
                              {NumberWithCommas(
                                performanceMetricsCardData?.spc?.current || 0
                              )}
                            </label>
                          </div>
                        </div>
                        <div
                          className="symbol symbol-40px me-4 position-absolute"
                          style={{ right: "0px", top: "-15px" }}
                        >
                          <span
                            className={`symbol-label ${
                              performanceMetricsCardData?.spc
                                ?.change_percentage > 0
                                ? "bg-light-success"
                                : "bg-light-danger"
                            }`}
                          >
                            {" "}
                            <Icon
                              icon="hugeicons:hot-price"
                              className={` ${
                                performanceMetricsCardData?.spc
                                  ?.change_percentage > 0
                                  ? "text-success"
                                  : " text-danger"
                              } fs-1`}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-2"></div>
                      <div className="d-flex justify-content-between">
                        <span className="text-muted fs-6">
                          {currency_sign}{" "}
                          {NumberWithCommas(
                            performanceMetricsCardData?.spc?.prev || 0
                          )}{" "}
                        </span>
                        {performanceMetricsCardData?.spc?.change_percentage >
                        0 ? (
                          <span className="badge badge-light-success fs-8">
                            <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.spc
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-light-danger fs-8">
                            <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.spc
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-12 col-xl-4 my-3">
                    <div
                      style={{
                        border: "1px dashed rgb(255 255 255 / 12%)",
                        background:'rgb(15, 16, 20)',
                        borderRadius: "8px",
                      }}
                      className="p-3 position-relative"
                    >
                      <div className="d-flex">
                        <div>
                          <div>
                            <label className="fw-semibold fs-5 text-gray-600">
                              CTR
                            </label>
                          </div>
                          <div>
                            <label className="fw-bold fs-2">
                              {currency_sign}
                              {NumberWithCommas(
                                performanceMetricsCardData?.ctr?.current || 0
                              )}
                            </label>
                          </div>
                        </div>
                        <div
                          className="symbol symbol-40px me-4 position-absolute"
                          style={{ right: "0px", top: "-15px" }}
                        >
                          <span
                            className={`symbol-label ${
                              performanceMetricsCardData?.ctr
                                ?.change_percentage > 0
                                ? "bg-light-success"
                                : "bg-light-danger"
                            }`}
                          >
                            <Icon
                              icon="tdesign:gesture-click"
                              className={` ${
                                performanceMetricsCardData?.ctr
                                  ?.change_percentage > 0
                                  ? "text-success"
                                  : " text-danger"
                              } fs-1`}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-2"></div>
                      <div className="d-flex justify-content-between">
                        <span className="text-muted fs-6">
                          {currency_sign}{" "}
                          {NumberWithCommas(
                            performanceMetricsCardData?.ctr?.prev || 0
                          )}{" "}
                        </span>
                        {performanceMetricsCardData?.ctr?.change_percentage >
                        0 ? (
                          <span className="badge badge-light-success fs-8">
                            <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.ctr
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        ) : (
                          <span className="badge badge-light-danger fs-8">
                            <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                            {NumberWithCommas(
                              performanceMetricsCardData?.ctr
                                ?.change_percentage || 0
                            )}
                            %
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {!performanceMetricsCardLoading && (
                <div className="row g-4 mb-5">
                  <div className="col-md-4">
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #f44336" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-click text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            $
                            {NumberWithCommas(
                              performanceMetricsCardData?.cpc?.current || 0
                            )}
                          </span>
                          <span className="fw-bold text-info fs-3">CPC</span>
                          <div className="d-flex align-items-center">
                            {performanceMetricsCardData?.cpc
                              ?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.cpc
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.cpc
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #f44336" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-click text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            $
                            {NumberWithCommas(
                              performanceMetricsCardData?.spc?.current || 0
                            )}
                          </span>
                          <span className="fw-bold text-info fs-3">SPC</span>
                          <div className="d-flex align-items-center">
                            {performanceMetricsCardData?.spc
                              ?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.spc
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.spc
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="d-flex flex-stack "
                      style={{ borderLeft: "2px solid #f44336" }}
                    >
                      <div className="symbol symbol-40px me-4">
                        <span className="symbol-label">
                          <i className="ki-duotone ki-click text-gray-600 fs-1">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                          </i>
                        </span>
                      </div>

                      <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                        <div className="me-5">
                          <span className="text-gray-800 fw-bolder fs-4 me-3 d-block">
                            {NumberWithCommas(
                              performanceMetricsCardData?.ctr?.current || 0
                            )}
                            %
                          </span>
                          <span className="fw-bold text-info fs-3">CTR</span>
                          <div className="d-flex align-items-center">
                            {performanceMetricsCardData?.ctr
                              ?.change_percentage > 0 ? (
                              <span className="badge badge-light-success fs-8">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.ctr
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            ) : (
                              <span className="badge badge-light-danger fs-8">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                {NumberWithCommas(
                                  performanceMetricsCardData?.ctr
                                    ?.change_percentage || 0
                                )}
                                %
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
