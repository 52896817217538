import { Checkbox, Input, Radio, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MakeApiCall } from "../../../../apis/make-api-call";
import { DisplayNotification, PhotoUrl } from "../../../../config";
import { AddUserWrapper } from "../../admin/manage-user/style";
export default function ({ show, onHide, data, parent, onSuccess }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [password, set_password] = useState("");
  const [confirm_pass, set_confirm_pass] = useState("");
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    // contact_no: "",
    company_name: "",
    seller_name: "",
  });

  useEffect(() => {
    if (data?.id) {
      setUserId(data?.id);
      setUserData({
        name: data?.name,
        email: data?.email,
        // contact_no: data?.contact_no,
        company_name: data?.company_name,
        seller_name: data?.seller_name,
      });
    }
  }, [data]);

  const onSubmitUser = async () => {
    localStorage.setItem("token_type", "main_token");
    message.destroy();
    message.loading("Loading", 0);
    const body = {
      ...userData,
      password: password,
    };
    if (userId) {
      delete body.password;
    }
    console.log(userData, body, "-------userData");
    let formData = new FormData();

    // formData.append("contact_no", body?.contact_no);
    formData.append("name", body?.name);
    formData.append("company_name", body?.company_name);
    formData.append("seller_name", body?.seller_name);
    formData.append("user_type", 3);
    formData.append("parent", parent);
    if (!userId) {
      formData.append("email", body?.email);
      formData.append("password", body?.password);
    }
    const response = await MakeApiCall(
      userId ? `user/${userId}` : "user",
      "post",
      formData,
      true,
      true
    );
    if (response?.status) {
      setSending(false);
      message.destroy();
      DisplayNotification("success", "Success", response?.message);
      onSuccess();
      onHide();
    } else {
      message.destroy();
      DisplayNotification("error", "Error", response?.message);
      setSending(false);
      localStorage.setItem("token_type", "");
    }
  };

  const setUserDataValue = (key, value) => {
    const common_data = {
      ...userData,
      [key]: value,
    };
    setUserData(common_data);
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{userId ? "Update " : "Add "} User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddUserWrapper>
            <div style={{ minHeight: "300px" }}>
              <div>
                <div className="row g-9 mb-5 align-items-center">
                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2 required">Name</label>
                    <Input
                      type="text"
                      className="ant_common_input"
                      size="large"
                      value={userData?.name}
                      onChange={(e) => {
                        setUserDataValue("name", e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2 required">
                      Company Name
                    </label>
                    <Input
                      type="text"
                      className="ant_common_input"
                      size="large"
                      value={userData?.company_name}
                      onChange={(e) => {
                        setUserDataValue("company_name", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row g-9 mb-5 align-items-center">
                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2 required">
                      Seller Name
                    </label>
                    <Input
                      type="text"
                      className="ant_common_input"
                      size="large"
                      value={userData?.seller_name}
                      onChange={(e) => {
                        setUserDataValue("seller_name", e.target.value);
                      }}
                    />
                  </div>
                  {/* <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2 required">
                      Contact No
                    </label>
                    <Input
                      type="text"
                      className="ant_common_input"
                      size="large"
                      value={userData?.contact_no}
                      onChange={(e) => {
                        setUserDataValue("contact_no", e.target.value);
                      }}
                    />
                  </div> */}
                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2 required">Email</label>
                    <Input
                      type="text"
                      className="ant_common_input"
                      size="large"
                      disabled={userId}
                      value={userData?.email}
                      onChange={(e) => {
                        !userId && setUserDataValue("email", e.target.value);
                      }}
                    />
                  </div>
                </div>
                {!userId && (
                  <div className="row g-9 mb-5 align-items-center">
                    <div className="col-md-6 fv-row">
                      <label className="fs-6 fw-bold mb-2 required">
                        Passowrd
                      </label>
                      <Input.Password
                        className="ant_common_input"
                        size="large"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => {
                          set_password(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6 fv-row">
                      <label className="fs-6 fw-bold mb-2 required">
                        Confirm Passowrd
                      </label>
                      <Input.Password
                        className="ant_common_input"
                        size="large"
                        value={confirm_pass}
                        onChange={(e) => {
                          set_confirm_pass(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (password !== e.target.value) {
                            set_confirm_pass("");
                            message.destroy();
                            DisplayNotification(
                              "info",
                              "Info",
                              "Password and Confirm password not matched"
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </AddUserWrapper>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-light-danger fs-7"
            onClick={onHide}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary fs-7"
            onClick={() => {
              if (password !== confirm_pass) {
                message.destroy();
                message.warning("Password and Confirm password not matched");
              } else {
                setSending(true);
                // console.log({
                //   ...userData,
                //   selected: userData?.platforms?.[0],
                // });
                onSubmitUser();
              }
            }}
            disabled={
              sending ||
              !userData?.name ||
              // !userData?.contact_no ||
              !userData?.email ||
              !userData?.seller_name ||
              !userData?.company_name
            }
          >
            Submit
            {sending && (
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
