import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Page from "./modules/pages";
import Login from "./modules/pages/auth/index";
import PageIndex from "./modules/pages";
import ClientInvitation from "./modules/pages/client-invitation/client-invitation";
import SignUp from "./modules/pages/auth/sign-up";
import Forgotpassword from "./modules/pages/reset-password/forgot-password";
import Resetpassword from "./modules/pages/reset-password/reset-password";
// import SignUp from "./modules/pages/auth/lib/sign-up";

function AppRouter() {
  return (
    // basename="/egas"
    <BrowserRouter>
      <Routes>
        <Route path="/sign-in" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/forgot-password" element={<Forgotpassword />} />
        <Route path="/reset-password" element={<Resetpassword />} />
        {/* <Route path="/client-invitation" element={<ClientInvitation />} /> */}
        <Route path="/callback-ads" element={<ClientInvitation />} />
        {/* <Route path="/sign-up" component={SignUp} exact /> */}
        <Route path="/" element={<PageIndex />} />
        <Route path="*" element={<PageIndex />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
