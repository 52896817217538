import React, { useState } from "react";
import RangePickerESF from "../../../../../component/range-picker";
import { DateFormat } from "../../../../../config";
import { Checkbox, Input, Select } from "antd";
import { metrics_filter } from "../../../../../config";
const NegativeKeywordSearchtermFilter = (props) => {
  const {
    search_term_filters,
    set_search_term_filters,
    metrics_filter_obj,
    set_metrics_filter_obj,
    GetApiCall,
  } = props;

  // const [metrics_filter, set_metrics_filter] = useState({
  //   attributes_dd_data: {
  //     clicks: "Clicks",
  //     impressions: "Impressions",
  //     cost: "Cost",
  //     sales: "Sales",
  //     RoAS: "RoAS",
  //     RoAS_per: "RoAS %",
  //     ACos: "ACOS",
  //     SPC: "SPC",
  //     CPC: "CPC",
  //     CPM: "CPM",
  //     CTR: "CTR",
  //   },

  //   operators_dd_data: {
  //     ">=": ">=",
  //     "<=": "<=",
  //     ">": ">",
  //     "<": "<",
  //     "=": "=",
  //   },
  // });

  return (
    <div className="row">
      <div className="col-12">
        <Checkbox
          onChange={(e) => {
            console.log(e, "-e");
            if (e.target.checked) {
              GetApiCall();
            }
          }}
        />
        <label className="text-primary fw-bold ms-2">Clear all filters</label>
      </div>
      <div className="col-12 mt-10">
        <label className="fw-bold fs-6">Date Range </label>
        <RangePickerESF
          className="ant_common_input"
          id="ivcsr_date_picker"
          size="large"
          allowClear={false}
          style={{ width: "100%" }}
          format={DateFormat}
          value={[
            search_term_filters?.start_date,
            search_term_filters?.end_date,
          ]}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          onChange={(e) => {
            const filters_ = search_term_filters;
            filters_.start_date = e?.[0];
            filters_.end_date = e?.[1];
            set_search_term_filters({ ...filters_ });
            // GetSearchtermlist(filters_);
          }}
        />
      </div>
      <div className="col-12 my-5">
        <label className="fw-bold fs-6">Campaign Type</label>
        <Select
          options={[
            {
              label: "SPONSORED PRODUCTS",
              value: "SPONSORED_PRODUCTS",
            },
            { label: "SPONSORED BRANDS", value: "SPONSORED_BRANDS" },
            {
              label: "SPONSORED DISPLAY",
              value: "SPONSORED_DISPLAY",
            },
          ]}
          value={search_term_filters?.campaign_type}
          onChange={(e) => {
            set_search_term_filters({
              ...search_term_filters,
              campaign_type: e,
            });
          }}
          style={{ width: "100%" }}
          placeholder="Campaign type"
          size="large"
        />
      </div>
      <div className="col-12 my-5">
        <label className="fw-bold fs-6">Campaign State</label>
        <Select
          size="large"
          allowClear={false}
          style={{ width: "100%" }}
          options={[
            { label: "Enabled", value: "ENABLED" },
            { label: "Paused", value: "PAUSED" },
          ]}
          placeholder="Campaign State"
          value={search_term_filters?.campaign_state}
          onChange={(e) => {
            set_search_term_filters({
              ...search_term_filters,
              campaign_state: e,
            });
          }}
        />
      </div>
      <div className="col12 my-5">
        <label className="fw-bold fs-6">Match Type</label>
        <Select
          size="large"
          allowClear={false}
          style={{ width: "100%" }}
          options={[
            { label: "Broad", value: "BROAD" },
            { label: "Exact", value: "EXACT" },
            { label: "Phrase", value: "PHRASE" },
          ]}
          placeholder="Match Type"
          value={search_term_filters?.match_type}
          onChange={(e) => {
            set_search_term_filters({
              ...search_term_filters,
              match_type: e,
            });
          }}
        />
      </div>
      <div className="col-12 my-5">
        <label className="fw-bold fs-6">Keyword state</label>
        <Select
          options={[
            { label: "ENABLED", value: "ENABLED" },
            { label: "PAUSED", value: "PAUSED" },
            { label: "DISABLED", value: "DISABLED" },
          ]}
          value={search_term_filters?.keyword_state}
          onChange={(e) => {
            set_search_term_filters({
              ...search_term_filters,
              keyword_state: e,
            });
          }}
          style={{ width: "100%" }}
          placeholder="Keyword state"
          size="large"
        />
      </div>
      <div className="col-12 my-5">
        <label className="fw-bold fs-6">Targeting Type</label>
        <Select
          size="large"
          allowClear={false}
          style={{ width: "100%" }}
          options={[
            { label: "Manual", value: "MANUAL" },
            { label: "Auto", value: "AUTO" },
          ]}
          value={search_term_filters?.targeting_type}
          onChange={(e) => {
            set_search_term_filters({
              ...search_term_filters,
              targeting_type: e,
            });
          }}
        />
      </div>

      <div className="col-12 my-3">
        <label className="fw-bold">Metrics</label>
        {metrics_filter_obj?.map((d, index) => {
          return (
            <div className="mt-2">
              <Select
                size="large"
                options={Object.entries(
                  metrics_filter?.attributes_dd_data
                )?.map((d) => {
                  return {
                    label: d[1],
                    value: d[0],
                  };
                })}
                value={d?.attribute}
                onChange={(e) => {
                  set_metrics_filter_obj((prev) => {
                    return prev?.map((d, mindex) => {
                      if (mindex === index) {
                        return {
                          ...d,
                          attribute: e,
                        };
                      }
                      return d;
                    });
                  });
                }}
                style={{ width: "40%" }}
              />
              <Select
                className="ms-1"
                size="large"
                options={Object.entries(metrics_filter?.operators_dd_data)?.map(
                  (d) => {
                    return {
                      label: d[1],
                      value: d[0],
                    };
                  }
                )}
                value={d?.operator}
                onChange={(e) => {
                  set_metrics_filter_obj((prev) => {
                    return prev?.map((d, mindex) => {
                      if (mindex === index) {
                        return {
                          ...d,
                          operator: e,
                        };
                      }
                      return d;
                    });
                  });
                }}
                style={{ width: "15%" }}
              />
              <Input
                size="large"
                style={{ width: "25%" }}
                className="ms-1"
                value={d?.value}
                onChange={(e) => {
                  set_metrics_filter_obj((prev) => {
                    return prev?.map((d, mindex) => {
                      if (mindex === index) {
                        return {
                          ...d,
                          value: e.target.value,
                        };
                      }
                      return d;
                    });
                  });
                }}
              />
              {metrics_filter_obj?.length > 1 && (
                <span
                  className="btn btn-icon btn-light-primary btn-outline w-40px h-40px position-relative border-0 cursor-pointer ms-1"
                  onClick={() => {
                    const deletedData = metrics_filter_obj?.filter(
                      (d, i) => i !== index
                    );
                    set_metrics_filter_obj(deletedData);
                  }}
                >
                  <i className="ki-outline ki-cross fs-2" />
                </span>
              )}
            </div>
          );
        })}

        <div className="mt-2 text-center">
          <button
            className="btn btn-light-primary"
            onClick={() => {
              set_metrics_filter_obj([
                ...metrics_filter_obj,
                {
                  attribute: null,
                  operator: null,
                  value: "",
                },
              ]);
            }}
          >
            <i className="ki-outline ki-plus fs-2" />
            Add another
          </button>{" "}
        </div>
      </div>
    </div>
  );
};

export default NegativeKeywordSearchtermFilter;
