import { Button, Popover, Table, Tooltip, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../../../commonContext";
import {
  DisplayNotification,
  getTableLoading,
  urlDecode,
} from "../../../../config";
import { MakeApiCall } from "../../../../apis/make-api-call";
import NoData from "../../../../component/no-data";
import NoDataTable from "../../../../component/no-data-table";
import EsfTable from "../../../../component/esf-table";
import SpApiLwaModal from "./sp-api-lwa-modal";

const SpApi = (props) => {
  const { loading, list, getList, cred, setLoading } = props;
  const location = useLocation();
  const contextValue = useContext(GlobalContext);
  const [isOpenLwaModal, setIsOpenLwaModal] = useState(false);
  const { credentials, user_data } = contextValue?.data;

  const GenerateSPRefreshToken = async (data) => {
    const response = await MakeApiCall(
      `user-credential/generate-refresh-token`,
      "post",
      data,
      true
    );
    if (response?.status) {
      message.destroy();
      message.success(response?.message || "-");
      setTimeout(() => {
        window.location.replace("/callbacksp");
      }, 1000);
    } else {
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    if (location.pathname.includes("/callbacksp")) {
      localStorage.setItem("token_type", "");
      console.log(contextValue?.data?.selected_sub_user, "-subUserToken");
      if (contextValue?.data?.user_type == 2) {
        if (localStorage.getItem("sub_user_list")) {
          NextApiCall();
        } else {
          setLoading(false);
          DisplayNotification("error", "Error", "User not found");
        }
      } else {
        NextApiCall();
      }
    }
  }, [location || contextValue?.data?.selected_sub_user]);

  const NextApiCall = () => {
    if (location?.search) {
      const { selling_partner_id, spapi_oauth_code, state } =
        urlDecode(location);
      const dataView = {
        spapi_oauth_code: spapi_oauth_code,
        selling_partner_id: selling_partner_id,
        seller_name: state?.split("!!")?.[1] || "",
        marketplace_id: state?.split("!!")?.[3] || "",
      };
      message.destroy();
      message.loading("Loading...");
      GenerateSPRefreshToken(dataView);
    } else {
      getList("SP-API");
    }
  };

  const columns = [
    {
      title: "Seller Account Name",
      width: 300,
      render: (record) =>
        JSON.parse(record.credential_details)?.seller_name || "-",
    },
    {
      title: "ARN",
      width: 150,
      render: (record) => (
        <div>{JSON.parse(record.credential_details)?.role_arn || "-"}</div>
      ),
    },
    {
      title: "Region",
      width: 120,
      render: (record) => (
        <div>{JSON.parse(record.credential_details)?.region || "-"}</div>
      ),
    },
    {
      title: "Marketplace",
      width: 150,
      render: (record) => record?.marketplace || "-",
    },
    {
      title: "AWS Access Key",
      width: 200,
      render: (record) =>
        JSON.parse(record.credential_details)?.access_key || "-",
    },
    {
      title: "AWS Secret Key",
      width: 200,
      render: (record) => (
        <Popover
          content={
            <div
              style={{
                height: "max-content",
                maxWidth: "300px",
                wordBreak: "break-all",
              }}
            >
              {JSON.parse(record.credential_details)?.secret_key || "-"}
            </div>
          }
          placement="bottomLeft"
        >
          <div
            className="mb-1 text-one fs-6 popoverActionIconSingleLine"
            style={{ minWidth: "200px", wordBreak: "break-all" }}
          >
            {JSON.parse(record.credential_details)?.secret_key || "-"}
          </div>
        </Popover>
      ),
    },
    {
      title: "LWA ClientID",
      width: 200,
      render: (record) => (
        <Popover
          content={
            <div
              style={{
                height: "max-content",
                maxWidth: "300px",
                wordBreak: "break-all",
              }}
            >
              {JSON.parse(record.credential_details)?.client_id || "-"}
            </div>
          }
          placement="bottomLeft"
        >
          <div
            className="mb-1 text-one fs-6 popoverActionIconSingleLine"
            style={{ minWidth: "200px", wordBreak: "break-all" }}
          >
            {JSON.parse(record.credential_details)?.client_id || "-"}
          </div>
        </Popover>
      ),
    },
    {
      title: "LWA Secret",
      width: 200,
      render: (record) => (
        <Popover
          content={
            <div
              style={{
                height: "max-content",
                maxWidth: "300px",
                wordBreak: "break-all",
              }}
            >
              {JSON.parse(record.credential_details)?.client_secret || "-"}
            </div>
          }
          placement="bottomLeft"
        >
          <div
            className="mb-1 text-one fs-6 popoverActionIconSingleLine"
            style={{ minWidth: "200px", wordBreak: "break-all" }}
          >
            {JSON.parse(record.credential_details)?.client_secret || "-"}
          </div>
        </Popover>
      ),
    },
    {
      title: "Refresh Token",
      width: 200,
      render: (record) => (
        <Popover
          content={
            <div
              style={{
                height: "max-content",
                maxWidth: "300px",
                wordBreak: "break-all",
              }}
            >
              {JSON.parse(record.credential_details)?.refresh_token || "-"}
            </div>
          }
          placement="bottomLeft"
        >
          <div
            className="mb-1 text-one fs-6 popoverActionIconSingleLine"
            style={{ minWidth: "200px", wordBreak: "break-all" }}
          >
            {JSON.parse(record.credential_details)?.refresh_token || "-"}
          </div>
        </Popover>
      ),
    },
  ];

  return (
    <div
      className="row mt-0 gy-7 g-xl-7 "
      data-select2-id="select2-data-10-18hq"
    >
      <div className="col-xxl-12" data-select2-id="select2-data-9-e17g">
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          <div className="card-header border-bottom-dashed">
            <div className="card-title m-0">
              <h3 className="fw-bold m-0 "> Amazon SP API Credentials List</h3>
            </div>
            <div className="card-toolbar" data-select2-id="select2-data-8-sylk">
              <span
                onClick={() => {
                  setIsOpenLwaModal(true);
                }}
                className="btn btn-warning fs-7 fw-bolder text-dark"
              >
                <i className="fab fa-amazon text-dark fs-2" /> Login with Amazon
              </span>
            </div>
          </div>
          <div className="card-body py-2">
            <div className="table-responsive">
              {loading ? (
                <NoDataTable columns={columns} type="loading" />
              ) : list?.length > 0 ? (
                <EsfTable
                  columns={columns?.map((d) => ({
                    ...d,
                    // width: d?.title?.length * 13,
                  }))}
                  sticky={{
                    offsetHeader: "10px",
                  }}
                  scroll={{
                    x: "max-content",
                    y: "calc(100vh - 430px)",
                  }}
                  fixed="top"
                  dataSource={list}
                  rowKey="key"
                  loading={loading}
                  pagination={false}
                />
              ) : (
                <>
                  <NoDataTable columns={columns} type="no-data" />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {isOpenLwaModal && (
        <SpApiLwaModal
          cred={cred}
          show={isOpenLwaModal}
          onHide={() => {
            setIsOpenLwaModal(false);
          }}
        />
      )}
    </div>
  );
};

export default SpApi;
