import { GetLinks } from "../../../../core";

export default [
  {
    key: "/targrting-reports",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-book fs-1"></i>
      </span>
    ),
    label: "Targeting Reports",
    children: [
      {
        key: "/targrting-reports/keyword-report",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/targrting-reports/keyword-report", "Keyword Report"),
      },
      {
        key: "/targrting-reports/keyword-search-terms-report",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks(
          "/targrting-reports/keyword-search-terms-report",
          "Keyword Search Terms Report"
        ),
      },
      {
        key: "/targrting-reports/product-audience-targeting",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks(
          "/targrting-reports/product-audience-targeting",
          "Product & Audience Targeting"
        ),
      },
      {
        key: "/targrting-reports/product-target-search-term",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks(
          "/targrting-reports/product-target-search-term",
          "Product Target Search Term"
        ),
      },
    ],
  },
];
