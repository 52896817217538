import React, { useContext, useEffect, useState } from "react";
import {
  ConvertParams,
  DefaultPerPage,
  DisplayNotification,
  GetMarketplaceDropdown,
  GetMarketplaceList,
  getTableLoading,
  NumberWithCommas,
  timeSince,
} from "../../../../config";
import { MakeApiCall } from "../../../../apis/make-api-call";
import NoData from "../../../../component/no-data";
import moment from "moment";
import { Input, Popover, Select, Tag } from "antd";
import NoDataTable from "../../../../component/no-data-table";
import EsfTable from "../../../../component/esf-table";
import Pagination from "../../../../component/esf-table/lib/pagination";
import { SearchOutlined } from "@ant-design/icons";
import { render } from "@testing-library/react";
import { GlobalContext } from "../../../../commonContext";

const ProductDetails = () => {
  const contextValue = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [list, set_list] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");

  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    search: "",
    marketplace_id: null,
  });

  useEffect(() => {
    localStorage.setItem("token_type", "");
    if (contextValue?.data?.user_type == 2) {
      if (localStorage.getItem("sub_user_list")) {
        GetMarketplace();
      } else {
        setLoading(false);
        DisplayNotification("error", "Error", "User not found");
      }
    } else {
      GetMarketplace();
    }
  }, [contextValue?.data?.selected_sub_user]);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_all_filters({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      GetProductList({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
    } else {
      setLoading(false);
    }
  };

  const GetProductList = async (filters) => {
    setLoading(true);
    const response = await MakeApiCall(
      `report/product-list${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      set_list(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: e,
      "per-page": all_filters?.["per-page"],
    };
    GetProductList(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: 1,
      "per-page": e,
    };
    GetProductList(apiObj);
  };

  const columns = [
    {
      title: "#",
      width: 20,
      render: (text) => (
        <span>
          {(all_filters?.page - 1) * all_filters?.["per-page"] + 1 + text?.key}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          className="fw-bold"
          color={
            status === "Active"
              ? "#73b956"
              : status === "Inactive"
              ? "#f00"
              : "#f50"
          }
        >
          {status}
        </Tag>
      ),
      width: 125,
    },
    {
      title: "Product",
      dataIndex: "item_name",
      key: "item_name",
      render: (text, record) => (
        <div className="d-flex align-items-center border border-dashed border-gray-300 p-3 rounded bdr-left-user1">
          <div className="symbol symbol-50px overflow-hidden me-3 shadow-sm">
            <img src={record?.image_url} />
          </div>
          <div className="d-flex flex-column">
            <Popover
              content={
                <div
                  style={{
                    height: "max-content",
                    maxWidth: "400px",
                  }}
                >
                  {record?.item_name}
                </div>
              }
              placement="bottom"
            >
              <span
                className="text-gray-900 mb-1 fw-bolder popoverActionIconSingleLine fs-6"
                style={{ width: "300px" }}
              >
                {record?.item_name}
              </span>
            </Popover>

            <div>
              <span
                className="fs-7 cursor-pointer"
                onClick={() => {
                  window.open(`https://www.amazon.com/dp/${record?.asin1}`);
                }}
              >
                <b>ASIN:</b>{" "}
                <span className="text-primary">{record?.asin1}</span>
              </span>{" "}
              |
              <span className="fs-7">
                <b>SKU:</b>{" "}
                <span className="text-primary">{record?.seller_sku}</span>
              </span>
            </div>
          </div>
        </div>
      ),
      width: 300,
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      width: 100,
    },
    {
      title: "Product Ad State",
      width: 150,
      render: (record) =>
        record?.is_non_advertised ? (
          <Tag className="fw-bold" color={"#574e21"}>
            Not Advertised
          </Tag>
        ) : (
          <Tag className="fw-bold" color={"#4e8935"}>
            Advertised
          </Tag>
        ),
    },
    {
      title: "Product Id",
      dataIndex: "product_id",
      key: "product_id",
      width: 120,
    },
    {
      title: "Sales Price",
      dataIndex: "price",
      key: "price",
      width: 120,
      render: (price) => (
        <div>
          <span className="fw-bold">
            {selected_currency_sign}
            {NumberWithCommas(price)}
          </span>
        </div>
      ),
    },

    // {
    //   title: "Total Fees",
    //   dataIndex: "total_fees",
    //   key: "total_fees",
    //   width: 100,
    //   render: (fees) => (
    //     <div>
    //       <span className="fw-bold">${fees}</span>
    //     </div>
    //   ),
    // },
    // {
    //   title: "Stock Availability",
    //   dataIndex: "stock_availability",
    //   key: "stock_availability",
    //   width: 120,
    //   render: (text) => (
    //     <div>
    //       <span className="fw-bold">{text}</span>
    //     </div>
    //   ),
    // },
    {
      title: "Stock Quantity",
      dataIndex: "stock_quantity",
      key: "stock_quantity",
      width: 150,
      render: (text) => (
        <div>
          <span className="fw-bold">{text}</span>
        </div>
      ),
    },
    // {
    //   title: "Product Weight",
    //   dataIndex: "product_weight",
    //   key: "product_weight",
    //   width: 120,
    // },

    {
      title: "Marketplace Id",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      width: 120,
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
      width: 120,
    },
    // {
    //   title: "Listing Id",
    //   dataIndex: "listing_id",
    //   key: "listing_id",
    //   width: 120,
    // },
    // {
    //   title: "Item Condition",
    //   dataIndex: "item_condition",
    //   key: "item_condition",
    //   width: 120,
    // },

    {
      title: "Fulfillment Channel",
      dataIndex: "fulfillment_channel",
      key: "fulfillment_channel",
      width: 200,
      render: (text) => {
        return (
          <Tag
            className="fw-bold"
            color={
              text === "DEFAULT"
                ? "#f50"
                : text === "AMAZON_NA"
                ? "#531dab"
                : "volcano"
            }
          >
            {text === "DEFAULT" ? "FBM" : text === "AMAZON_NA" ? "FBA" : ""}
          </Tag>
        );
      },
    },
    // {
    //   title: "Sales Rank",
    //   dataIndex: "salesRank",
    //   key: "salesRank",
    // },
    // {
    //   title: "Organic Sales",
    //   dataIndex: "organicSales",
    //   key: "organicSales",
    // },
    // {
    //   title: "Spend",
    //   dataIndex: "spend",
    //   key: "spend",
    // },
    // {
    //   title: "Ratings",
    //   dataIndex: "ratings",
    //   key: "ratings",
    // },
    // {
    //   title: "Reviews",
    //   dataIndex: "reviews",
    //   key: "reviews",
    // },
    // {
    //   title: "Clicks",
    //   dataIndex: "clicks",
    //   key: "clicks",
    // },
    // {
    //   title: "Impression",
    //   dataIndex: "impression",
    //   key: "impression",
    // },
    // {
    //   title: "CPC",
    //   dataIndex: "cpc",
    //   key: "cpc",
    // },
    // {
    //   title: "CTR",
    //   dataIndex: "ctr",
    //   key: "ctr",
    // },
    // {
    //   title: "Unit Sold",
    //   dataIndex: "unitSold",
    //   key: "unitSold",
    // },
    // {
    //   title: "Campaign Name",
    //   dataIndex: "campaignName",
    //   key: "campaignName",
    // },
    // {
    //   title: "Description",
    //   dataIndex: "item_description",
    //   key: "item_description",
    //   width: 150,
    //   render: (text) => {
    //     return !text ? (
    //       "-"
    //     ) : (
    //       <Popover
    //         content={
    //           <div
    //             style={{
    //               height: "max-content",
    //               maxWidth: "600px",
    //             }}
    //           >
    //             {text}
    //           </div>
    //         }
    //         placement="bottom"
    //       >
    //         <span
    //           className="text-dark mb-1 fw-bolder popoverActionIconSingleLine fs-6"
    //           style={{ width: "150px" }}
    //         >
    //           {text || "-"}
    //         </span>
    //       </Popover>
    //     );
    //   },
    // },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => (
        <span>
          {moment(new Date(created_at * 1000)).format("MMM DD, YYYY hh:mm A")}
          <br />
          <small className="text-gray-500">
            ({moment(created_at * 1000).fromNow()})
          </small>
        </span>
      ),
      width: 200,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      render: (updated_at) => (
        <span>
          {moment(new Date(updated_at * 1000)).format("MMM DD, YYYY hh:mm A")}
          <br />
          <small className="text-gray-500">
            ({moment(updated_at * 1000).fromNow()})
          </small>
        </span>
      ),
      width: 200,
    },
  ];

  return (
    <>
      <div className="container-fluid" id="kt_content_container">
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header">
                <div className="d-flex flex-stack flex-wrap gap-4 p-2">
                  <div className="position-relative">
                    <Input
                      prefix={
                        <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                      }
                      placeholder="Search by ASIN or SKU"
                      className="form-control d-flex"
                      variant="filled"
                      size="large"
                      value={all_filters?.search}
                      onChange={(e) => {
                        set_all_filters({
                          ...all_filters,
                          search: e.target.value,
                        });
                      }}
                      onPressEnter={(e) => {
                        const apiObj = {
                          ...all_filters,
                          page: 1,
                          "per-page": DefaultPerPage,
                          search: e.target.value,
                        };
                        setLoading(true);
                        GetProductList(apiObj);
                      }}
                    />
                  </div>
                </div>
                <div className="card-toolbar">
                  <div class="d-flex flex-stack flex-wrap gap-4">
                    <div className="position-relative me-2">
                      <Select
                        className="w-200px"
                        size="large"
                        variant="filled"
                        placeholder="Select Marketplace"
                        options={GetMarketplaceList(marketplace_list)}
                        value={selected_marketplace}
                        onChange={(e) => {
                          set_selected_marketplace(e);
                          set_selected_currency_sign(currency_list[e]);
                          const apiObj = {
                            ...all_filters,
                            page: 1,
                            "per-page": DefaultPerPage,
                            marketplace_id: e,
                          };
                          set_all_filters(apiObj);
                          setLoading(true);
                          GetProductList(apiObj);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body py-2">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  {loading ? (
                    <NoDataTable columns={columns} type="loading" />
                  ) : list?.length > 0 ? (
                    <EsfTable
                      columns={columns?.map((d) => ({
                        ...d,
                        // width: d?.title?.length * 13,
                      }))}
                      sticky={{
                        offsetHeader: "10px",
                      }}
                      scroll={{
                        x: "max-content",
                        y: "calc(100vh - 300px)",
                      }}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                    />
                  ) : (
                    <>
                      <NoDataTable columns={columns} type="no-data" />
                    </>
                  )}
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={all_filters?.["per-page"]}
                  page={all_filters?.page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </div>
    </>
  );
};

export default ProductDetails;
