import {
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  Radio,
  Select,
  Space,
  theme,
} from "antd";
import React, { useState } from "react";
import { Step1Wrapper } from "./style";
import { campaignBiddingStrategy } from "../../../../../../config/select-dropdown-list";
import { DateFormat } from "../../../../../../config";
import dayjs from "dayjs";
import moment from "moment";
const { useToken } = theme;

const SPStep1 = (props) => {
  const {
    campaign_data_list,
    updateCampaignDataList,
    // run_indefinitely_end_time,
    // set_run_indefinitely_end_time,
    campaign_bidding_strategy_label,
    set_campaign_bidding_strategy_label,
    portfolioList,
    manual_sub_targeting_type,
    set_manual_sub_targeting_type,
    selected_marketplace,
    selected_currency_sign,
  } = props;

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const token = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    minWidth: "150px",
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < new Date().setHours(0, 0, 0, 0);
  };

  return (
    <Step1Wrapper>
      <div
        className="flex-column current"
        data-kt-stepper-element="content"
        style={{ minHeight: 500 }}
      >
        <div className>
          <div className="row gy-5 g-xl-5">
            <div className="col-xl-12">
              <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
                <div className="card-header border-0 cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Create Campaign</h3>
                  </div>

                  <div className="card-toolbar"></div>
                </div>

                <div className="card-body border-top border-top-dashed pb-2 pt-6">
                  <div className="row mb-0">
                    <div
                      className="col-8"
                      // style={{ borderRight: "1px solid #e6332a" }}
                    >
                      <label className="col-auto col-form-label required fw-semibold fs-6">
                        Campaign Name
                      </label>

                      <Input
                        value={campaign_data_list?.name}
                        className="form-control"
                        onChange={(e) => {
                          updateCampaignDataList("name", e.target.value);
                        }}
                        size="large"
                      />
                    </div>
                    <div className="col-3">
                      <label className="col-auto col-form-label fw-semibold fs-6">
                        Portfolio
                      </label>
                      <Select
                        style={{ width: "100%" }}
                        size="large"
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        value={campaign_data_list?.portfolio_id}
                        placeholder="Select Portfolio"
                        onChange={(e) => {
                          updateCampaignDataList("portfolio_id", e);
                        }}
                        options={portfolioList?.map((d) => {
                          return { label: d?.name, value: d?.portfolioId };
                        })}
                      />
                    </div>
                    {/* <div className="col-12 col-lg-4">
                      <div className="row">
                        <label className="col-auto col-form-label required fw-semibold fs-6">
                          Status
                        </label>

                        <div className="col-lg-6">
                          <Select
                            options={[
                              { label: "ENABLED", value: "ENABLED" },
                              { label: "PAUSED", value: "PAUSED" },
                            ]}
                            value={campaign_data_list?.state}
                            onChange={(e) => {
                              updateCampaignDataList("state", e);
                            }}
                            size="large"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="separator separator-dashed border-gray-300 mt-6 mb-6" />
                  <div className="row g-5 align-items-center">
                    <div className="col-md-3">
                      <h5 className="mb-0 required">
                        Select Targeting Tactic:
                      </h5>
                    </div>
                    <div className="col-md-9">
                      <div className="d-flex gap-4 align-items-center">
                        <Radio.Group
                          name="radiogroup"
                          onChange={(e) => {
                            updateCampaignDataList(
                              "targeting_type",
                              e.target.value
                            );
                          }}
                          value={campaign_data_list?.targeting_type}
                        >
                          <Radio value={"AUTO"}>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">Automatic</span>
                            </span>
                          </Radio>
                          <Radio value={"MANUAL"}>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">Manual</span>
                            </span>
                          </Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center mt-0">
                    <div className="col-md-9 ms-auto ">
                      {campaign_data_list?.targeting_type === "AUTO" && (
                        <div className="automaticDiv">
                          {/* Content for automatic selection */}
                          <p className="text-gray-700 mt-3 mb-0">
                            Amazon will target keywords and products that are
                            similar to the product in your ad.
                          </p>
                        </div>
                      )}
                      {campaign_data_list?.targeting_type === "MANUAL" && (
                        <div className="manualDiv">
                          {/* Content for manual selection */}
                          <p className="text-gray-700 mt-3 mb-0">
                            Choose keywords or products to target shopper
                            searches and set custom bids.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {campaign_data_list?.targeting_type === "MANUAL" && (
                    <div className="row ">
                      <div className="separator separator-dashed border-gray-300 mt-6 mb-6" />
                      <div className="col-md-3">
                        <h5 className="mb-0">Manual Targeting:</h5>
                      </div>
                      <div className="col-md-9">
                        <div className="d-flex gap-4 align-items-center">
                          <Radio.Group
                            name="radiogroup"
                            onChange={(e) => {
                              set_manual_sub_targeting_type(e.target.value);
                            }}
                            value={manual_sub_targeting_type}
                          >
                            <Radio value={"keyword_targeting"}>
                              <span className="d-flex flex-column">
                                <span className="fw-bold fs-6">
                                  Keyword Targeting
                                </span>
                              </span>
                            </Radio>
                            <Radio value={"product_targeting"}>
                              <span className="d-flex flex-column">
                                <span className="fw-bold fs-6">
                                  Product Targeting
                                </span>
                              </span>
                            </Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-xl-12">
              <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
                <div className="card-header border-0 cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Scheduling &amp; Budget</h3>
                  </div>
                </div>

                <div className="card-body border-top border-top-dashed pb-2 pt-6">
                  <div className="row mb-0 ">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor className="fw-bolder fs-6 mb-1 required">
                          Start Date
                        </label>
                        <DatePicker
                          format={DateFormat}
                          onChange={(date, dateString) => {
                            updateCampaignDataList(
                              "start_date",
                              moment(date?.$d)?.format("YYYY-MM-DD")
                            );
                          }}
                          style={{ width: "100%" }}
                          size="large"
                          className="ant_common_input form-control"
                          value={dayjs(campaign_data_list?.start_date)}
                          disabledDate={disabledDate}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor className="fw-bolder fs-6 mb-1">
                          End Date
                        </label>
                        <DatePicker
                          format={DateFormat}
                          onChange={(date, dateString) => {
                            updateCampaignDataList(
                              "end_date",
                              date ? moment(date.$d).format("YYYY-MM-DD") : null
                            );
                          }}
                          style={{ width: "100%" }}
                          size="large"
                          className="ant_common_input form-control"
                          value={
                            campaign_data_list.end_date
                              ? dayjs(campaign_data_list.end_date)
                              : null
                          }
                          disabledDate={disabledDate}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor className="fw-bolder fs-6 mb-1 required">
                          Daily Budget
                        </label>
                        <Input
                          prefix={selected_currency_sign}
                          value={campaign_data_list?.daily_budget}
                          onChange={(e) => {
                            const newVal = e.target.value.split(".").join("");
                            const isNumeric = /^[0-9]+$/.test(newVal);
                            if (isNumeric || e.target.value === "") {
                              updateCampaignDataList(
                                "daily_budget",
                                e.target.value
                              );
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target.value < 1) {
                              updateCampaignDataList("daily_budget", "");
                            }
                          }}
                          size="large"
                          className="form-control d-flex"
                        />
                        {campaign_data_list?.daily_budget < 1 && (
                          <label className="text-danger mt-2">
                            Enter a budget of at least {selected_currency_sign}
                            1.00
                          </label>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor className="fw-bolder fs-6 mb-1 required">
                          Daily Limit
                        </label>
                        <Input
                          value={campaign_data_list?.daily_limit}
                          onChange={(e) => {
                            const newVal = e.target.value.split(".").join("");
                            const isNumeric = /^[0-9]+$/.test(newVal);
                            if (isNumeric || e.target.value === "") {
                              updateCampaignDataList(
                                "daily_limit",
                                e.target.value
                              );
                            }
                          }}
                          size="large"
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-md-12">
                      <div className="mt-4">
                        <Checkbox
                          onChange={(e) => {
                            set_run_indefinitely_end_time(e.target.checked);
                            if (e.target.checked) {
                              updateCampaignDataList("end_date", "");
                            } else {
                              updateCampaignDataList(
                                "end_date",
                                dayjs().add(30, "d")?.format("YYYY-MM-DD")
                              );
                            }
                          }}
                          checked={run_indefinitely_end_time}
                        />
                        <label className="form-check-label fs-6 fw-bold text-dark ms-2">
                          Run indefinitely from start date
                        </label>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-xl-12">
              <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
                <div className="card-header border-0 cursor-pointer">
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">Campaign Bidding Strategy</h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>

                <div className="card-body border-top border-top-dashed pb-2 pt-6">
                  <div className="d-flex w-100 mb-7">
                    <div className="row">
                      {campaignBiddingStrategy?.map((d) => {
                        return (
                          <Radio.Group
                            key={d?.key}
                            onChange={(e) => {
                              set_campaign_bidding_strategy_label(
                                d?.display_label
                              );
                              updateCampaignDataList(
                                "bidding_strategy",
                                e.target.value
                              );
                            }}
                            value={
                              campaign_data_list?.bidding_strategy === d?.key
                                ? d?.key
                                : ""
                            }
                          >
                            {" "}
                            <Space direction="vertical">
                              <Radio
                                key={d?.key}
                                className="mb-2"
                                value={d?.key}
                              >
                                <div style={{ padding: "10px" }}>
                                  <div className="d-flex align-items-center w-100">
                                    <div className="d-flex flex-column w-100">
                                      <span
                                        className={`fs-6 mb-2 fw-bold lh-1 ${
                                          d?.key ===
                                          campaign_data_list?.bidding_strategy
                                            ? "text-primary"
                                            : ""
                                        }`}
                                      >
                                        {d?.display_label?.header}
                                      </span>
                                      <span className="text-gray-600 fs-8 w-75">
                                        {d?.display_label?.content}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="separator border-gray-200" />
                              </Radio>
                            </Space>
                          </Radio.Group>
                        );
                      })}
                    </div>
                    {console.log(
                      campaign_data_list,
                      "-campaign_data_listcampaign_data_listcampaign_data_listcampaign_data_list"
                    )}
                  </div>

                  <div className="row mb-0 ">
                    <div className="col-lg-12">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-4 gy-4">
                        <thead>
                          <tr className="fw-bolder text-dark ">
                            <th className="fs-6" colSpan={3}>
                              Adjust Bids by Placement
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="min-w-200px">
                              <label
                                className="form-check-label text-gray-800 fs-6 fw-bold"
                                htmlFor
                              >
                                Top of search (First Page)
                              </label>
                            </td>
                            <td className="min-w-200px  text-center">
                              <div className="w-100px mx-auto">
                                <Input
                                  value={
                                    campaign_data_list?.top_of_search_placement
                                  }
                                  onChange={(e) => {
                                    const newVal = e.target.value
                                      .split(".")
                                      .join("");
                                    const isNumeric = /^[0-9]+$/.test(newVal);
                                    if (isNumeric || e.target.value === "") {
                                      updateCampaignDataList(
                                        "top_of_search_placement",
                                        e.target.value
                                      );
                                    }
                                  }}
                                  addonAfter={
                                    <span className=" bg-white fw-bolder fs-5">
                                      %
                                    </span>
                                  }
                                  size="large"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="fs-7 text-gray-600">
                                Example: A $2.46 bid will remain $2.46 for this
                                placement, Dynamic bidding could increase it up
                                to $4.92
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="min-w-200px">
                              <label
                                className="form-check-label text-gray-800 fs-6 fw-bold"
                                htmlFor
                              >
                                Rest of search
                              </label>
                            </td>
                            <td className="min-w-200px  text-center">
                              <div className="w-100px mx-auto">
                                <Input
                                  value={
                                    campaign_data_list?.rest_of_search_placement
                                  }
                                  onChange={(e) => {
                                    const newVal = e.target.value
                                      .split(".")
                                      .join("");
                                    const isNumeric = /^[0-9]+$/.test(newVal);
                                    if (isNumeric || e.target.value === "") {
                                      updateCampaignDataList(
                                        "rest_of_search_placement",
                                        e.target.value
                                      );
                                    }
                                  }}
                                  addonAfter={
                                    <span className=" bg-white fw-bolder fs-5">
                                      %
                                    </span>
                                  }
                                  size="large"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="fs-7 text-gray-600">
                                Example: A $2.46 bid will remain $2.46 for this
                                placement, Dynamic bidding could increase it up
                                to $3.69
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="min-w-200px">
                              <label
                                className="form-check-label text-gray-800 fs-6 fw-bold"
                                htmlFor
                              >
                                Product Pages
                              </label>
                            </td>
                            <td className="min-w-200px text-center">
                              <div className="w-100px mx-auto">
                                <Input
                                  value={
                                    campaign_data_list?.product_pages_placement
                                  }
                                  onChange={(e) => {
                                    const newVal = e.target.value
                                      .split(".")
                                      .join("");
                                    const isNumeric = /^[0-9]+$/.test(newVal);
                                    if (isNumeric || e.target.value === "") {
                                      updateCampaignDataList(
                                        "product_pages_placement",
                                        e.target.value
                                      );
                                    }
                                  }}
                                  addonAfter={
                                    <span className=" bg-white fw-bolder fs-5">
                                      %
                                    </span>
                                  }
                                  size="large"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="fs-7 text-gray-600">
                                Example: A $2.46 bid will remain $2.46 for this
                                placement, Dynamic bidding could increase it up
                                to $3.69
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Step1Wrapper>
  );
};

export default SPStep1;
