import advertisementReport from "./advertisement-report";
import applicationLogs from "./application-logs";
import campaignManagement from "./campaign-management";
import dashboard from "./dashboard";
import dayParting from "./day-parting";
import keywordManagement from "./keyword-management";
import manageUser from "./manage-user";
import masterTable from "./master-table";
import productAnalytics from "./product-analytics";
import productDetails from "./product-details";
import recommendationAlerts from "./recommendation-alerts";
import ruleBook from "./rule-book";
import ruleManagement from "./rule-management";
import setting from "./setting";
import targetingReport from "./targeting-report";
import userAccounts from "./user-accounts";
const usertype = localStorage.getItem("user_type");
const payload = {
  users: [
    ...dashboard,
    ...campaignManagement,
    // ...ruleBook,
    ...ruleManagement,
    // ...masterTable,
    // ...productAnalytics,
    // ...productDetails,
    ...recommendationAlerts,
    // ...keywordManagement,
    // ...targetingReport,
    ...advertisementReport,
    ...setting,
    ...applicationLogs,
    ...userAccounts,

    // ...dayParting
  ],
  admin: [...manageUser],
};

export default payload;
