import styled from "styled-components";

export const SidebarWrapper = styled.div`
  .ant-menu-root.ant-menu-inline {
    border-inline-end: none !important;
  }
  .ant-menu-light {
    background: transparent !important;
  }
  .ant-menu-item-selected {
    background-color: #434343 !important;
    color: #fff !important;
    animation: pulsate-bck 0.5s ease-in-out 1 both;
        box-shadow: 0 0 40px #0000007d;
  }
  .ant-menu-item-selected .ant-menu-item-icon i {
    color: #fff !important;
  }
  .ant-menu .ant-menu-item {
    transition: border-color 0.1s, background 0.1s, color 0.1s,
      padding 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
      z
  .ant-menu-item-icon i {
    color: #e6332a !important;
  }
  .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-item-icon i {
    color: #fff !important;
  }
  .ant-menu-submenu-selected .ant-menu-submenu-title {
    background-color: #5c5c5c40 !important;
  }
  .ant-menu-sub {
    background: transparent !important;
  }
  .ant-menu-title-content {
    color: #fff !important;
        opacity: .7 !important;
  }
.ant-menu-item-selected .ant-menu-title-content {
    opacity: 1 !important;
}
  .ant-menu .ant-menu-submenu-arrow {
    color: #fff !important;
  }
  .ant-menu-light:not(.ant-menu-horizontal)
    .ant-menu-item:not(.ant-menu-item-selected):active {
    background-color: #5c5c5c !important;
  }
  .ant-menu-light:not(.ant-menu-horizontal)
    .ant-menu-item:not(.ant-menu-item-selected):hover {
    background-color: #5c5c5c40 !important;
  }
  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active, .ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
    background-color: #5c5c5c40;
}
  .pulsate-bck {
    -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
    animation: pulsate-bck 0.5s ease-in-out infinite both;
  }
  @keyframes pulsate-bck {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .fadeInLeftSidebar {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: fadeInLeftSidebar;
  }

  @keyframes fadeInLeftSidebar {
    0% {
      opacity: 0;
      transform: translateX(-200px);
    }
    100% {
      opacity: 1;
    }
  }
  .sm-new-drawer {
    border-radius: 0px 25px 25px 0px !important;
    box-shadow: 0px 0px 11px -2px red !important;
  }
  .ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: none;
  }

  .ant-menu-inline .ant-menu-item{height:45px}
`;
