import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../commonContext";
import { useLocation, useNavigate } from "react-router-dom";
import { HeaderWrapper } from "./style";
import { Select, Tooltip } from "antd";
import { MakeApiCall } from "../../../apis/make-api-call";
import { DisplayNotification } from "../../../config";

const Header = (props) => {
  const { sidebarToggle, setSidebarToggle } = props;
  const contextValue = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [full_path, set_full_path] = useState([]);
  const [updatingSelectedUser, setUpdatingSelectedUser] = useState(false);
  useEffect(() => {
    const path_name = location.pathname.split("/");
    set_full_path(path_name);
  }, [location]);

  useEffect(() => {
    GetSubUserList();
  }, []);

  const getUserList = () =>
    contextValue?.sub_user_list?.user_list ||
    localStorage.getItem("sub_user_list");

  const GetSubUserList = async () => {
    //  const data = {
    //    user_type: 2,
    //    parent: 2,
    //  };
    localStorage.setItem("token_type", "main_token");
    const response = await MakeApiCall(
      `user/get-all-sub-user`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      if (response?.data?.length > 0) {
        localStorage.setItem("token_type", "");
        localStorage.setItem("sub_user_list", JSON.stringify(response?.data));
        contextValue?.updateCommonUserListVal({
          user_list: JSON.stringify(response?.data),
        });
      } else {
        localStorage.removeItem("sub_user_list");
      }
    } else {
      localStorage.setItem("token_type", "");
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const backToAdmin = () => {
    const admin_data = localStorage.getItem("admin_data");
    const data = JSON.parse(admin_data);
    const response = JSON.parse(data?.user_data);
    console.log(response, "-----Back to admin");
    const newRes = {
      auth_token: data?.token,
      user_data: JSON.parse(data?.user_data),
    };

    contextValue?.updateCommonGlobalVal({
      isAdmin: 0,
      user_data: JSON.stringify(newRes?.user_data),
      user_type: newRes?.user_data?.user_type,
      sub_user_list: null,
      selected_sub_user: null,
    });
    localStorage.setItem("isAdmin", 0);
    localStorage.setItem("user_data", JSON.stringify(newRes?.user_data));
    localStorage.setItem("token", newRes?.auth_token);
    localStorage.setItem("user_type", newRes?.user_data?.user_type);
    localStorage.setItem("sub_user_list", null);
    localStorage.setItem("selected_sub_user", null);
    localStorage.removeItem("sub_user_list");
    navigate("/manage-user");
  };

  const UpdateSelectedSubUser = async (id) => {
    localStorage.setItem("token_type", "main_token");
    const response = await MakeApiCall(
      `user/switch-sub-user/${id}?parent_user_id=${
        JSON.parse(contextValue?.data?.user_data)?.id
      }`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      localStorage.setItem(
        "sub_user_token",
        response?.data?.user_data?.sub_user?.auth_token
      );
      localStorage.setItem("selected_sub_user", id || null);
      localStorage.setItem("token_type", "");
      contextValue?.updateCommonGlobalVal({
        selected_sub_user: id,
      });
      setUpdatingSelectedUser(false);
    } else {
      localStorage.setItem("token_type", "");
      DisplayNotification("error", "Error", response?.message);
      setUpdatingSelectedUser(false);
    }
  };

  return (
    <>
      <div
        id="kt_header"
        className="header mt-0 mt-lg-0 pt-lg-0 "
        data-kt-sticky="true"
        data-kt-sticky-name="header"
        data-kt-sticky-offset="{lg: '300px'}"
        style={{
          zIndex: "100",
          position: "fixed",
          left: window.innerWidth >= 992 ? "320px" : "0px",
          right: "20px",
          top: "0px",
          borderRadius: "0px 0px 20px 20px",
          background: "rgb(10 10 12)",
          alignItems: "center",
          border:'1px solid rgb(31, 33, 42)'
          // backdropFilter: "blur(3px)",
        }}
      >
        <div
          className="container-fluid d-flex flex-stack flex-wrap gap-4"
          id="kt_header_container"
        >
          <div
            className="page-title d-flex flex-row align-items-start justify-content-center flex-wrap me-lg-2 pb-10 pb-lg-0"
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
          >
            <h1
              className="d-flex flex-column text-gray-900 fw-bold fs-3 mb-0 me-2"
              style={{ textTransform: "capitalize" }}
            >
              {full_path?.[1] === "callbacksp"
                ? "Amazon SP API Credentials"
                : full_path?.[1] === "callbackads"
                ? "Amazon Advertising Credentials"
                : full_path?.[1]?.replaceAll("-", " ")}
            </h1>
              <div className="me-2 fs-4">/</div>
            <ul className="breadcrumb breadcrumb-dot fw-semibold fs-base my-1">
              <li className="breadcrumb-item">
                <span
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                  className="text-gray-900 cursor-pointer"
                >
                  Home
                </span>
              </li>
              {full_path?.map((d, i) => {
                if (d) {
                  const newPath = full_path.slice(0, i + 1)?.join("/");
                  console.log(newPath, d, "-full_path");
                  return (
                    <>
                      <li
                        className="breadcrumb-item"
                        style={{ paddingLeft: "0.5rem" }}
                      >
                        {full_path?.length - 1 === i ? (
                          <span
                            className={`breadcrumb-item text-gray-800`}
                            style={{ textTransform: "capitalize" }}
                          >
                            {d === "callbacksp"
                              ? "Amazon SP API Credentials"
                              : d === "callbackads"
                              ? "Amazon Advertising Credentials"
                              : d?.replaceAll("-", " ")}
                          </span>
                        ) : (
                          <span
                            // to={{ pathname: newPath }}
                            className={`breadcrumb-item text-gray-800`}
                            style={{ textTransform: "capitalize" }}
                          >
                            {d === "callbacksp"
                              ? "Amazon SP API Credentials"
                              : d === "callbackads"
                              ? "Amazon Advertising Credentials"
                              : d?.replaceAll("-", " ")}
                          </span>
                        )}
                      </li>
                    </>
                  );
                }
              })}
            </ul>
          </div>

          <div
            className="d-flex d-lg-none align-items-center ms-n3 me-2"
            onClick={() => {
              setSidebarToggle(!sidebarToggle);
            }}
          >
            <div
              className="btn btn-icon btn-active-icon-primary"
              id="kt_aside_toggle"
            >
              <i className="ki-duotone ki-abstract-14 fs-1 mt-1">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>

            <span className="d-flex align-items-center ">
              <img
                alt="Logo"
                src="assets/media/esf_favicon.png"
                className="theme-light-show h-35px"
              />
              <img
                alt="Logo"
                src="assets/media/esf_favicon.png"
                className="theme-dark-show h-35px"
              />
            </span>
          </div>

          <div className="d-flex align-items-center flex-shrink-0 mb-0 mb-lg-0 ">
            {contextValue?.data?.user_type == 2 && (
              <div className="d-flex align-items-center ms-3 ms-lg-4">
                <Select
                  options={JSON.parse(getUserList())?.map((d) => {
                    return { label: d?.name, value: d?.id };
                  })}
                  value={contextValue?.data?.selected_sub_user || null}
                  onChange={(e) => {
                    localStorage.setItem("user_type", 2);
                    setUpdatingSelectedUser(true);
                    UpdateSelectedSubUser(e);
                  }}
                  placeholder="Select"
                  loading={updatingSelectedUser}
                  disabled={updatingSelectedUser}
                  style={{ width: "200px" }}
                  size="large"
                />
              </div>
            )}

            {contextValue?.data?.isAdmin == 1 && (
              <div className="d-flex align-items-center ms-3 ms-lg-4">
                <Tooltip title="Back to admin" placement="left">
                  <span
                    onClick={() => {
                      backToAdmin();
                    }}
                    className="btn btn-icon btn-primary btn-outline w-40px h-40px position-relative border-0"
                  >
                    <i className="ki-outline ki-arrow-left fs-1 fadeInLeftInfinite" />
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
