import React from "react";
import { Modal } from "react-bootstrap";

export default function (props) {
  const { show, onHide, selectedCamp } = props;
  console.log(selectedCamp, "-selectedCamp");

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        size="md"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Campaigns List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              height: "350px",
              overflow: "auto",
            }}
          >
            <div className="row">
              {selectedCamp?.map((d, i) => {
                return (
                  <>
                    <div className="col-12">
                      {d?.ad_type} -
                      <label className="fw-bold fs-6 ms-2">
                        {d?.campaign_name}
                      </label>
                    </div>
                    <div className="separator separator-dashed my-3" />
                  </>
                );
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-light-danger me-2"
              onClick={() => {
                onHide();
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
