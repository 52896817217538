import { Tabs, Tag } from "antd";
import React from "react";
import AdGroupList from "../campaign-analytics-details/ad_group";
import KeywordList from "../campaign-analytics-details/keyword";
import ProductAdsList from "../campaign-analytics-details/products_ads";

const CampaignDetails = (props) => {
  const {
    isVisible,
    setIsVisible,
    selectedPortfolioData,
    selctedCampaignData,
  } = props;
  const items = [
    {
      key: "ad_group",
      label: "Ad Group",
    },
    {
      key: "keywords",
      label: "Keywords",
    },
    {
      key: "product_ads",
      label: "Product Ads",
    },
  ];
  return (
    <>
      <div className="container-fluid" id="kt_content_container">
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5 px-0 mx-0">
          <div className="col-12 ">
            <div className="card mb-3">
              {/*begin::Header*/}
              <div className="card-header border-0">
                <div className="d-flex flex-stack flex-wrap p-2">
                  <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bold fs-3 mb-1">
                      <div className="btn btn-icon btn-primary btn-outline w-35px h-35px position-relative border-0 ">
                        <span onClick={() => setIsVisible("campaign")}>
                          <i className="ki-outline ki-arrow-left fs-1 fadeInLeftInfinite" />
                        </span>
                      </div>
                    </span>
                  </h3>

                  <div>
                    {/* <Tag className="fs-5 py-1" color="geekblue">
                      Portfolio - {selectedPortfolioData?.name}
                    </Tag>
                    <Tag className="fs-5 py-1" color="purple">
                      Campaign - {selctedCampaignData?.campaign_name}
                    </Tag> */}
                  </div>
                </div>
                <div className="card-toolbar">
                  <Tabs
                    defaultActiveKey="ad_group"
                    items={items}
                    onChange={(e) => {
                      setIsVisible(e);
                    }}
                    className="fw-bold px-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isVisible === "ad_group" ? (
        <AdGroupList {...props} />
      ) : isVisible === "keywords" ? (
        <KeywordList {...props} />
      ) : isVisible === "product_ads" ? (
        <ProductAdsList {...props} />
      ) : (
        ""
      )}
    </>
  );
};

export default CampaignDetails;
