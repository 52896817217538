import styled from "styled-components";

export const AddUserWrapper = styled.div`
  .ant-upload-list-item-container .ant-tooltip {
    display: none !important;
  }
  .ant-transfer .ant-transfer-list-content-item-remove {
    color: #e6332a !important;
  }
 
`;
export const Wrapper = styled.div`
  .ant-input-affix-wrapper{display:flex}
 
`;