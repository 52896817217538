import { Input } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MakeApiCall } from "../../../apis/make-api-call";
import { DisplayNotification } from "../../../config";

function Forgotpassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const submit = async () => {
    setLoading(true);
    const response = await MakeApiCall("forgot-password", "post", {
      email: email,
    });
    if (response?.status) {
      DisplayNotification("success", "Success", response?.message);
      setLoading(false);
      console.log("Forgot password:-",response);
      
      navigate("/reset-password");
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  return (
    <div
      className="d-flex flex-column flex-column-fluid flex-lg-row bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat"
      style={{
        height: "100%",
        backgroundImage: `url(/assets/media/login_bg_3.jpg)`,
        // backgroundSize: "auto",
        // backgroundRepeat: "repeat",
      }}
    >
      <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-40 mx-auto position-relative">
        <div className="b1" />
        <div className="b2" />

        <div
          className="d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20 "
          style={{
            backgroundColor: "#a3a3a3a3",
            backdropFilter: "blur(11px)",
            border: "2px solid #6c6c6c",
          }}
        >
          <div className=" text-center">
            <img
              src="/assets/media/bidbison_logo.png"
              // className="h-40px"
              style={{ height: "150px" }}
              alt
            />
          </div>
          <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-10">
            <div className="form w-100">
              <div className="text-center mb-18">
                <h1 className="tracking-in-expand-fwd  text-gray-100 fw-bold fs-2x mb-3">
                  Forgot Your Password?
                </h1>

                <div className="text-gray-200 fw-semibold fs-6">
                  Enter your registered email address, and we’ll send you a link
                  to reset your password, if your account exists.
                </div>
              </div>

              <div className="fv-row mb-8">
                <Input
                  type="text"
                  placeholder="Email"
                  name="email"
                  autoComplete="off"
                  className="form-control h-50px "
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>

              <div className="d-grid mb-10">
                <button
                  disabled={!email}
                  className="btn btn-primary h-50px"
                  onClick={() => {
                    submit();
                  }}
                >
                  {loading ? (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  ) : (
                    <span className="indicator-label">Submit</span>
                  )}
                </button>
              </div>
              {/* <div className="d-flex flex-end  mt-5 flex-wrap gap-3 fs-base fw-semibold mb-8 text-white">
                <Link to="/sign-in">
                  <b className="fw-boldest text-white"> Sign in</b>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgotpassword;
