export const campaignBiddingStrategy = [
  {
    label: <span>Dynamic bids - down only</span>,
    key: "LEGACY_FOR_SALES",
    display_label: {
      header: "Dynamic bids - down only",
      content:
        "Lowers your bids in real time when your ad may be less likely to convert to a sale. Any campaign created before October 2018 uses this setting by default.",
    },
  },
  {
    label: <span>Dynamic bids - up and down</span>,
    key: "AUTO_FOR_SALES",
    display_label: {
      header: "Dynamic bids - up and down",
      content:
        "Increases your bids (by a maximum of 100%) in real time when your ad may be more likely to convert to a sale, and lowers your bids when less likely to convert to a sale.",
    },
  },
  {
    label: <span>Fixed bid</span>,
    key: "MANUAL",
    display_label: {
      header: "Fixed bid",
      content:
        "Uses your exact bid and any manual adjustments you set, and is not subject to adjusted bidding.",
    },
  },
];

export const staticAllKeywordList = [
  {
    keyword: "aclivia",
    search_term_impression_rank: 1,
    search_term_impression_share: 100,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 1,
        suggested_bid: 2.08,
        suggested_bid_range: {
          start_range: 1.56,
          median_range: 2.08,
          end_range: 2.6,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 1,
        suggested_bid: 1.95,
        suggested_bid_range: {
          start_range: 1.46,
          median_range: 1.95,
          end_range: 2.44,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 1,
        suggested_bid: 2.08,
        suggested_bid_range: {
          start_range: 1.56,
          median_range: 2.08,
          end_range: 2.6,
        },
      },
    },
  },
  {
    keyword: "seamoss capsules",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 2,
        suggested_bid: 4.71,
        suggested_bid_range: {
          start_range: 3.53,
          median_range: 4.71,
          end_range: 5.89,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 2,
        suggested_bid: 2.39,
        suggested_bid_range: {
          start_range: 1.79,
          median_range: 2.39,
          end_range: 2.99,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 2,
        suggested_bid: 3.45,
        suggested_bid_range: {
          start_range: 2.38,
          median_range: 3.45,
          end_range: 3.9,
        },
      },
    },
  },
  {
    keyword: "seamoss bladderwrack burdock capsules",
    search_term_impression_rank: 15,
    search_term_impression_share: 0.04658963846440552,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 3,
        suggested_bid: 4.61,
        suggested_bid_range: {
          start_range: 3.46,
          median_range: 4.61,
          end_range: 5.76,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 3,
        suggested_bid: 4.01,
        suggested_bid_range: {
          start_range: 3.01,
          median_range: 4.01,
          end_range: 5.01,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 3,
        suggested_bid: 4.5,
        suggested_bid_range: {
          start_range: 3.38,
          median_range: 4.5,
          end_range: 5.63,
        },
      },
    },
  },
  {
    keyword: "irish moss",
    search_term_impression_rank: 19,
    search_term_impression_share: 0.31992687385740404,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 4,
        suggested_bid: 3.48,
        suggested_bid_range: {
          start_range: 2.72,
          median_range: 3.48,
          end_range: 4,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 4,
        suggested_bid: 4.3,
        suggested_bid_range: {
          start_range: 2.95,
          median_range: 4.3,
          end_range: 5.26,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 4,
        suggested_bid: 2.56,
        suggested_bid_range: {
          start_range: 1.92,
          median_range: 2.56,
          end_range: 3.2,
        },
      },
    },
  },
  {
    keyword: "irish sea moss",
    search_term_impression_rank: 137,
    search_term_impression_share: 0.0005038041795594736,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 5,
        suggested_bid: 3.69,
        suggested_bid_range: {
          start_range: 2.76,
          median_range: 3.69,
          end_range: 4.79,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 5,
        suggested_bid: 4.01,
        suggested_bid_range: {
          start_range: 2.81,
          median_range: 4.01,
          end_range: 4.52,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 5,
        suggested_bid: 4,
        suggested_bid_range: {
          start_range: 2.91,
          median_range: 4,
          end_range: 4.51,
        },
      },
    },
  },
  {
    keyword: "irish seamoss",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 6,
        suggested_bid: 3,
        suggested_bid_range: {
          start_range: 2,
          median_range: 3,
          end_range: 3.8,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 6,
        suggested_bid: 1.87,
        suggested_bid_range: {
          start_range: 1.4,
          median_range: 1.87,
          end_range: 2.34,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 6,
        suggested_bid: 3.49,
        suggested_bid_range: {
          start_range: 3.12,
          median_range: 3.49,
          end_range: 4.5,
        },
      },
    },
  },
  {
    keyword: "organic irish moss capsules",
    search_term_impression_rank: 1,
    search_term_impression_share: 66.66666666666667,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 7,
        suggested_bid: 2.55,
        suggested_bid_range: {
          start_range: 1.91,
          median_range: 2.55,
          end_range: 3.19,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 7,
        suggested_bid: 3.64,
        suggested_bid_range: {
          start_range: 2.64,
          median_range: 3.64,
          end_range: 4.09,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 7,
        suggested_bid: 2.13,
        suggested_bid_range: {
          start_range: 1.91,
          median_range: 2.13,
          end_range: 2.36,
        },
      },
    },
  },
  {
    keyword: "irish moss capsules",
    search_term_impression_rank: 14,
    search_term_impression_share: 0.5520504731861199,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 8,
        suggested_bid: 2.9,
        suggested_bid_range: {
          start_range: 2.17,
          median_range: 2.9,
          end_range: 3.63,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 8,
        suggested_bid: 2.93,
        suggested_bid_range: {
          start_range: 2.2,
          median_range: 2.93,
          end_range: 3.66,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 8,
        suggested_bid: 4.14,
        suggested_bid_range: {
          start_range: 2.96,
          median_range: 4.14,
          end_range: 5.23,
        },
      },
    },
  },
  {
    keyword: "certified organic sea moss",
    search_term_impression_rank: 6,
    search_term_impression_share: 2.7522935779816513,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 9,
        suggested_bid: 5.45,
        suggested_bid_range: {
          start_range: 4.09,
          median_range: 5.45,
          end_range: 6.81,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 9,
        suggested_bid: 5.43,
        suggested_bid_range: {
          start_range: 4.07,
          median_range: 5.43,
          end_range: 6.79,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 9,
        suggested_bid: 5.39,
        suggested_bid_range: {
          start_range: 4.04,
          median_range: 5.39,
          end_range: 6.74,
        },
      },
    },
  },
  {
    keyword: "irish sea moss capsule",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 10,
        suggested_bid: 3.76,
        suggested_bid_range: {
          start_range: 2.82,
          median_range: 3.76,
          end_range: 4.7,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 10,
        suggested_bid: 4.14,
        suggested_bid_range: {
          start_range: 2.96,
          median_range: 4.14,
          end_range: 5.23,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 10,
        suggested_bid: 3.61,
        suggested_bid_range: {
          start_range: 2.71,
          median_range: 3.61,
          end_range: 4.51,
        },
      },
    },
  },
  {
    keyword: "organic irish sea moss",
    search_term_impression_rank: 21,
    search_term_impression_share: 0.17002176278563655,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 11,
        suggested_bid: 3.63,
        suggested_bid_range: {
          start_range: 2.72,
          median_range: 3.63,
          end_range: 4.54,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 11,
        suggested_bid: 4.01,
        suggested_bid_range: {
          start_range: 3.01,
          median_range: 4.01,
          end_range: 5.01,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 11,
        suggested_bid: 3.72,
        suggested_bid_range: {
          start_range: 2.67,
          median_range: 3.72,
          end_range: 4.27,
        },
      },
    },
  },
  {
    keyword: "seamoss",
    search_term_impression_rank: 73,
    search_term_impression_share: 0.002610544314805539,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 12,
        suggested_bid: 2.49,
        suggested_bid_range: {
          start_range: 1.87,
          median_range: 2.49,
          end_range: 3.11,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 12,
        suggested_bid: 6.11,
        suggested_bid_range: {
          start_range: 4.58,
          median_range: 6.11,
          end_range: 7.64,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 12,
        suggested_bid: 2.55,
        suggested_bid_range: {
          start_range: 1.91,
          median_range: 2.55,
          end_range: 3.19,
        },
      },
    },
  },
  {
    keyword: "organic sea moss supplement",
    search_term_impression_rank: 1,
    search_term_impression_share: 100,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 13,
        suggested_bid: 5.51,
        suggested_bid_range: {
          start_range: 2.94,
          median_range: 5.51,
          end_range: 6.7,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 13,
        suggested_bid: 2.85,
        suggested_bid_range: {
          start_range: 2.47,
          median_range: 2.85,
          end_range: 3.66,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 13,
        suggested_bid: 3.34,
        suggested_bid_range: {
          start_range: 2.57,
          median_range: 3.34,
          end_range: 3.9,
        },
      },
    },
  },
  {
    keyword: "sea moss bladderwrack",
    search_term_impression_rank: 28,
    search_term_impression_share: 0.08486082824168364,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 14,
        suggested_bid: 4.83,
        suggested_bid_range: {
          start_range: 3.26,
          median_range: 4.83,
          end_range: 5.35,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 14,
        suggested_bid: 5.74,
        suggested_bid_range: {
          start_range: 4.31,
          median_range: 5.74,
          end_range: 7.18,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 14,
        suggested_bid: 5.66,
        suggested_bid_range: {
          start_range: 4.25,
          median_range: 5.66,
          end_range: 7.08,
        },
      },
    },
  },
  {
    keyword: "organic seamoss",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 15,
        suggested_bid: 2.5,
        suggested_bid_range: {
          start_range: 1.69,
          median_range: 2.5,
          end_range: 2.78,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 15,
        suggested_bid: 3.21,
        suggested_bid_range: {
          start_range: 1.8,
          median_range: 3.21,
          end_range: 4.01,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 15,
        suggested_bid: 2.85,
        suggested_bid_range: {
          start_range: 2.14,
          median_range: 2.85,
          end_range: 3.56,
        },
      },
    },
  },
  {
    keyword: "organic irish sea moss capsules",
    search_term_impression_rank: 42,
    search_term_impression_share: 0.0007867387319345118,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 16,
        suggested_bid: 3.51,
        suggested_bid_range: {
          start_range: 2.81,
          median_range: 3.51,
          end_range: 4.15,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 16,
        suggested_bid: 3.38,
        suggested_bid_range: {
          start_range: 2.56,
          median_range: 3.38,
          end_range: 3.85,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 16,
        suggested_bid: 3.51,
        suggested_bid_range: {
          start_range: 2.86,
          median_range: 3.51,
          end_range: 5,
        },
      },
    },
  },
  {
    keyword: "sea moss supplement",
    search_term_impression_rank: 55,
    search_term_impression_share: 0.022573187723299422,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 17,
        suggested_bid: 5.68,
        suggested_bid_range: {
          start_range: 4.26,
          median_range: 5.68,
          end_range: 7.1,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 17,
        suggested_bid: 5.74,
        suggested_bid_range: {
          start_range: 4.31,
          median_range: 5.74,
          end_range: 7.18,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 17,
        suggested_bid: 4.89,
        suggested_bid_range: {
          start_range: 3.67,
          median_range: 4.89,
          end_range: 6.11,
        },
      },
    },
  },
  {
    keyword: "organic sea moss capsule",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 18,
        suggested_bid: 3.55,
        suggested_bid_range: {
          start_range: 3.18,
          median_range: 3.55,
          end_range: 4.53,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 18,
        suggested_bid: 4.09,
        suggested_bid_range: {
          start_range: 3,
          median_range: 4.09,
          end_range: 5.58,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 18,
        suggested_bid: 4.33,
        suggested_bid_range: {
          start_range: 3.25,
          median_range: 4.33,
          end_range: 5.29,
        },
      },
    },
  },
  {
    keyword: "sea moss bladderwrack burdock root",
    search_term_impression_rank: 27,
    search_term_impression_share: 0.15308577177098368,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 19,
        suggested_bid: 5.01,
        suggested_bid_range: {
          start_range: 3.76,
          median_range: 5.01,
          end_range: 6.26,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 19,
        suggested_bid: 5.69,
        suggested_bid_range: {
          start_range: 4.27,
          median_range: 5.69,
          end_range: 7.11,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 19,
        suggested_bid: 5.6,
        suggested_bid_range: {
          start_range: 4.2,
          median_range: 5.6,
          end_range: 7,
        },
      },
    },
  },
  {
    keyword: "certified organic sea moss capsules",
    search_term_impression_rank: 14,
    search_term_impression_share: 0.030816640986132512,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 20,
        suggested_bid: 4.83,
        suggested_bid_range: {
          start_range: 3.62,
          median_range: 4.83,
          end_range: 6.04,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 20,
        suggested_bid: 5.04,
        suggested_bid_range: {
          start_range: 3.78,
          median_range: 5.04,
          end_range: 6.3,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 20,
        suggested_bid: 4.65,
        suggested_bid_range: {
          start_range: 2.52,
          median_range: 4.65,
          end_range: 5.22,
        },
      },
    },
  },
  {
    keyword: "sea moss supplement capsules",
    search_term_impression_rank: 4,
    search_term_impression_share: 6.818181818181818,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 21,
        suggested_bid: 3.63,
        suggested_bid_range: {
          start_range: 2.72,
          median_range: 3.63,
          end_range: 4.54,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 21,
        suggested_bid: 4.61,
        suggested_bid_range: {
          start_range: 3.46,
          median_range: 4.61,
          end_range: 5.76,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 21,
        suggested_bid: 3.83,
        suggested_bid_range: {
          start_range: 2.87,
          median_range: 3.83,
          end_range: 4.79,
        },
      },
    },
  },
  {
    keyword: "seamoss pills",
    search_term_impression_rank: 24,
    search_term_impression_share: 0.12235842377822745,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 22,
        suggested_bid: 3.77,
        suggested_bid_range: {
          start_range: 2.83,
          median_range: 3.77,
          end_range: 4.71,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 22,
        suggested_bid: 3.76,
        suggested_bid_range: {
          start_range: 2.82,
          median_range: 3.76,
          end_range: 4.7,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 22,
        suggested_bid: 2.09,
        suggested_bid_range: {
          start_range: 1.75,
          median_range: 2.09,
          end_range: 2.36,
        },
      },
    },
  },
  {
    keyword: "sea moss capsule",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 23,
        suggested_bid: 5.02,
        suggested_bid_range: {
          start_range: 3.76,
          median_range: 5.02,
          end_range: 6.28,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 23,
        suggested_bid: 5,
        suggested_bid_range: {
          start_range: 3.75,
          median_range: 5,
          end_range: 6.25,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 23,
        suggested_bid: 3.22,
        suggested_bid_range: {
          start_range: 2.76,
          median_range: 3.22,
          end_range: 3.57,
        },
      },
    },
  },
  {
    keyword: "seamos",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 24,
        suggested_bid: 3.87,
        suggested_bid_range: {
          start_range: 2.9,
          median_range: 3.87,
          end_range: 4.84,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 24,
        suggested_bid: 3.87,
        suggested_bid_range: {
          start_range: 2.9,
          median_range: 3.87,
          end_range: 4.84,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 24,
        suggested_bid: 3.89,
        suggested_bid_range: {
          start_range: 2.92,
          median_range: 3.89,
          end_range: 4.86,
        },
      },
    },
  },
  {
    keyword: "bladderwrack capsules",
    search_term_impression_rank: 6,
    search_term_impression_share: 3.695514845230575,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 25,
        suggested_bid: 4.1,
        suggested_bid_range: {
          start_range: 3.08,
          median_range: 4.1,
          end_range: 5.13,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 25,
        suggested_bid: 4.36,
        suggested_bid_range: {
          start_range: 3.27,
          median_range: 4.36,
          end_range: 5.45,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 25,
        suggested_bid: 4.3,
        suggested_bid_range: {
          start_range: 3.22,
          median_range: 4.3,
          end_range: 5.38,
        },
      },
    },
  },
  {
    keyword: "bladder rack sea moss",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 26,
        suggested_bid: 4.26,
        suggested_bid_range: {
          start_range: 2.17,
          median_range: 4.26,
          end_range: 4.98,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 26,
        suggested_bid: 4.03,
        suggested_bid_range: {
          start_range: 3.02,
          median_range: 4.03,
          end_range: 5.04,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 26,
        suggested_bid: 4.26,
        suggested_bid_range: {
          start_range: 2.17,
          median_range: 4.26,
          end_range: 4.98,
        },
      },
    },
  },
  {
    keyword: "organic sea moss",
    search_term_impression_rank: 56,
    search_term_impression_share: 0.06988768970897322,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 27,
        suggested_bid: 4.15,
        suggested_bid_range: {
          start_range: 3.11,
          median_range: 4.15,
          end_range: 5.49,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 27,
        suggested_bid: 2.77,
        suggested_bid_range: {
          start_range: 2.08,
          median_range: 2.77,
          end_range: 3.46,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 27,
        suggested_bid: 2.84,
        suggested_bid_range: {
          start_range: 2.13,
          median_range: 2.84,
          end_range: 3.55,
        },
      },
    },
  },
  {
    keyword: "best sea moss capsules",
    search_term_impression_rank: 18,
    search_term_impression_share: 0.5303760848601736,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 28,
        suggested_bid: 5.5,
        suggested_bid_range: {
          start_range: 4.13,
          median_range: 5.5,
          end_range: 6.88,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 28,
        suggested_bid: 5.5,
        suggested_bid_range: {
          start_range: 4.13,
          median_range: 5.5,
          end_range: 6.88,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 28,
        suggested_bid: 5.48,
        suggested_bid_range: {
          start_range: 4.11,
          median_range: 5.48,
          end_range: 6.85,
        },
      },
    },
  },
  {
    keyword: "sea moss pill",
    search_term_impression_rank: 12,
    search_term_impression_share: 0.618921308576481,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 29,
        suggested_bid: 8.15,
        suggested_bid_range: {
          start_range: 6.11,
          median_range: 8.15,
          end_range: 10.19,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 29,
        suggested_bid: 5.56,
        suggested_bid_range: {
          start_range: 4.17,
          median_range: 5.56,
          end_range: 6.95,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 29,
        suggested_bid: 4.18,
        suggested_bid_range: {
          start_range: 2.46,
          median_range: 4.18,
          end_range: 4.99,
        },
      },
    },
  },
  {
    keyword: "sea moss",
    search_term_impression_rank: 219,
    search_term_impression_share: 0.000011959343494230753,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 30,
        suggested_bid: 5.75,
        suggested_bid_range: {
          start_range: 4.31,
          median_range: 5.75,
          end_range: 7.19,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 30,
        suggested_bid: 5.06,
        suggested_bid_range: {
          start_range: 3.8,
          median_range: 5.06,
          end_range: 6.32,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 30,
        suggested_bid: 5.74,
        suggested_bid_range: {
          start_range: 4.31,
          median_range: 5.74,
          end_range: 7.18,
        },
      },
    },
  },
  {
    keyword: "pure organic irish sea moss",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 31,
        suggested_bid: 3.49,
        suggested_bid_range: {
          start_range: 2.54,
          median_range: 3.49,
          end_range: 3.92,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 31,
        suggested_bid: 3.49,
        suggested_bid_range: {
          start_range: 2.54,
          median_range: 3.49,
          end_range: 3.92,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 31,
        suggested_bid: 2.37,
        suggested_bid_range: {
          start_range: 1.64,
          median_range: 2.37,
          end_range: 2.96,
        },
      },
    },
  },
  {
    keyword: "bladderwrack",
    search_term_impression_rank: 48,
    search_term_impression_share: 0.004134452391780709,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 32,
        suggested_bid: 4.37,
        suggested_bid_range: {
          start_range: 3.28,
          median_range: 4.37,
          end_range: 5.46,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 32,
        suggested_bid: 4.49,
        suggested_bid_range: {
          start_range: 3.54,
          median_range: 4.49,
          end_range: 5.73,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 32,
        suggested_bid: 4.5,
        suggested_bid_range: {
          start_range: 3.55,
          median_range: 4.5,
          end_range: 5.74,
        },
      },
    },
  },
  {
    keyword: "sea moss supplement women",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 33,
        suggested_bid: 5.28,
        suggested_bid_range: {
          start_range: 3.96,
          median_range: 5.28,
          end_range: 6.6,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 33,
        suggested_bid: 4.59,
        suggested_bid_range: {
          start_range: 3.49,
          median_range: 4.59,
          end_range: 5.56,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 33,
        suggested_bid: 4.95,
        suggested_bid_range: {
          start_range: 3.77,
          median_range: 4.95,
          end_range: 5.58,
        },
      },
    },
  },
  {
    keyword: "organic wildcrafted sea moss",
    search_term_impression_rank: 5,
    search_term_impression_share: 0.08583690987124463,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 34,
        suggested_bid: 2,
        suggested_bid_range: {
          start_range: 1.5,
          median_range: 2,
          end_range: 2.5,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 34,
        suggested_bid: 1.82,
        suggested_bid_range: {
          start_range: 1.37,
          median_range: 1.82,
          end_range: 2.28,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 34,
        suggested_bid: 4.76,
        suggested_bid_range: {
          start_range: 2.57,
          median_range: 4.76,
          end_range: 5.41,
        },
      },
    },
  },
  {
    keyword: "burdock root capsule",
    search_term_impression_rank: 3,
    search_term_impression_share: 10,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 35,
        suggested_bid: 3.12,
        suggested_bid_range: {
          start_range: 2.07,
          median_range: 3.12,
          end_range: 4.35,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 35,
        suggested_bid: 3.03,
        suggested_bid_range: {
          start_range: 1.96,
          median_range: 3.03,
          end_range: 3.75,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 35,
        suggested_bid: 3.1,
        suggested_bid_range: {
          start_range: 2.06,
          median_range: 3.1,
          end_range: 4.35,
        },
      },
    },
  },
  {
    keyword: "bladderwrack organic",
    search_term_impression_rank: 27,
    search_term_impression_share: 0.059940981187753595,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 36,
        suggested_bid: 3.54,
        suggested_bid_range: {
          start_range: 2.66,
          median_range: 3.54,
          end_range: 4.43,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 36,
        suggested_bid: 3.6,
        suggested_bid_range: {
          start_range: 2.96,
          median_range: 3.6,
          end_range: 4.07,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 36,
        suggested_bid: 4.42,
        suggested_bid_range: {
          start_range: 3.32,
          median_range: 4.42,
          end_range: 5.53,
        },
      },
    },
  },
  {
    keyword: "see moss",
    search_term_impression_rank: 8,
    search_term_impression_share: 2.1634615384615383,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 37,
        suggested_bid: 1.96,
        suggested_bid_range: {
          start_range: 1.63,
          median_range: 1.96,
          end_range: 2.45,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 37,
        suggested_bid: 3.74,
        suggested_bid_range: {
          start_range: 2.81,
          median_range: 3.74,
          end_range: 4.68,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 37,
        suggested_bid: 3.75,
        suggested_bid_range: {
          start_range: 2.81,
          median_range: 3.75,
          end_range: 4.69,
        },
      },
    },
  },
  {
    keyword: "sea moss pills women",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 38,
        suggested_bid: 4.78,
        suggested_bid_range: {
          start_range: 3.22,
          median_range: 4.78,
          end_range: 5.3,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 38,
        suggested_bid: 4.96,
        suggested_bid_range: {
          start_range: 3.72,
          median_range: 4.96,
          end_range: 6.2,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 38,
        suggested_bid: 5.59,
        suggested_bid_range: {
          start_range: 4.19,
          median_range: 5.59,
          end_range: 6.99,
        },
      },
    },
  },
  {
    keyword: "sea moss tablets",
    search_term_impression_rank: 10,
    search_term_impression_share: 0.22321428571428573,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 39,
        suggested_bid: 5.36,
        suggested_bid_range: {
          start_range: 4.02,
          median_range: 5.36,
          end_range: 6.7,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 39,
        suggested_bid: 5.59,
        suggested_bid_range: {
          start_range: 4.19,
          median_range: 5.59,
          end_range: 6.99,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 39,
        suggested_bid: 5.6,
        suggested_bid_range: {
          start_range: 4.2,
          median_range: 5.6,
          end_range: 7,
        },
      },
    },
  },
  {
    keyword: "wildcrafted sea moss",
    search_term_impression_rank: 31,
    search_term_impression_share: 0.003801457478797371,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 40,
        suggested_bid: 4.3,
        suggested_bid_range: {
          start_range: 2.32,
          median_range: 4.3,
          end_range: 4.82,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 40,
        suggested_bid: 1.91,
        suggested_bid_range: {
          start_range: 1.43,
          median_range: 1.91,
          end_range: 2.39,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 40,
        suggested_bid: 4.31,
        suggested_bid_range: {
          start_range: 2.3,
          median_range: 4.31,
          end_range: 5.1,
        },
      },
    },
  },
  {
    keyword: "wildcrafted sea moss capsules",
    search_term_impression_rank: 3,
    search_term_impression_share: 6.293706293706293,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 41,
        suggested_bid: 3.82,
        suggested_bid_range: {
          start_range: 2.68,
          median_range: 3.82,
          end_range: 4.56,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 41,
        suggested_bid: 3.25,
        suggested_bid_range: {
          start_range: 2.64,
          median_range: 3.25,
          end_range: 4.83,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 41,
        suggested_bid: 3.39,
        suggested_bid_range: {
          start_range: 2.58,
          median_range: 3.39,
          end_range: 4.58,
        },
      },
    },
  },
  {
    keyword: "best sea moss women",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 42,
        suggested_bid: 2.48,
        suggested_bid_range: {
          start_range: 1.86,
          median_range: 2.48,
          end_range: 2.88,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 42,
        suggested_bid: 1.92,
        suggested_bid_range: {
          start_range: 1.44,
          median_range: 1.92,
          end_range: 2.4,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 42,
        suggested_bid: 2.6,
        suggested_bid_range: {
          start_range: 1.93,
          median_range: 2.6,
          end_range: 3.06,
        },
      },
    },
  },
  {
    keyword: "sea moss pills men",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 43,
        suggested_bid: 5.04,
        suggested_bid_range: {
          start_range: 3.12,
          median_range: 5.04,
          end_range: 5.59,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 43,
        suggested_bid: 4.87,
        suggested_bid_range: {
          start_range: 3.16,
          median_range: 4.87,
          end_range: 5.6,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 43,
        suggested_bid: 5.07,
        suggested_bid_range: {
          start_range: 3.13,
          median_range: 5.07,
          end_range: 5.59,
        },
      },
    },
  },
  {
    keyword: "wild sea moss",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 44,
        suggested_bid: 3.89,
        suggested_bid_range: {
          start_range: 2.51,
          median_range: 3.89,
          end_range: 4.6,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 44,
        suggested_bid: 3.83,
        suggested_bid_range: {
          start_range: 2.36,
          median_range: 3.83,
          end_range: 4.4,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 44,
        suggested_bid: 3.97,
        suggested_bid_range: {
          start_range: 2.51,
          median_range: 3.97,
          end_range: 4.58,
        },
      },
    },
  },
  {
    keyword: "sea moss transformation capsules",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 45,
        suggested_bid: 3.79,
        suggested_bid_range: {
          start_range: 2.26,
          median_range: 3.79,
          end_range: 4.96,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 45,
        suggested_bid: 3.25,
        suggested_bid_range: {
          start_range: 2.13,
          median_range: 3.25,
          end_range: 4.09,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 45,
        suggested_bid: 3.79,
        suggested_bid_range: {
          start_range: 2.26,
          median_range: 3.79,
          end_range: 4.96,
        },
      },
    },
  },
  {
    keyword: "wild crafted sea moss",
    search_term_impression_rank: 9,
    search_term_impression_share: 1.3181818181818181,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 46,
        suggested_bid: 4.34,
        suggested_bid_range: {
          start_range: 2.5,
          median_range: 4.34,
          end_range: 4.89,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 46,
        suggested_bid: 3.81,
        suggested_bid_range: {
          start_range: 2.86,
          median_range: 3.81,
          end_range: 4.76,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 46,
        suggested_bid: 2.15,
        suggested_bid_range: {
          start_range: 1.23,
          median_range: 2.15,
          end_range: 3.01,
        },
      },
    },
  },
  {
    keyword: "wild crafted sea moss capsules",
    search_term_impression_rank: 9,
    search_term_impression_share: 0.7142857142857143,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 47,
        suggested_bid: 3.72,
        suggested_bid_range: {
          start_range: 2.79,
          median_range: 3.72,
          end_range: 4.65,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 47,
        suggested_bid: 3.7,
        suggested_bid_range: {
          start_range: 2.78,
          median_range: 3.7,
          end_range: 4.63,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 47,
        suggested_bid: 3.03,
        suggested_bid_range: {
          start_range: 2.23,
          median_range: 3.03,
          end_range: 3.78,
        },
      },
    },
  },
  {
    keyword: "vegan supplement",
    search_term_impression_rank: 12,
    search_term_impression_share: 0.17225180081428124,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 48,
        suggested_bid: 3.81,
        suggested_bid_range: {
          start_range: 2.86,
          median_range: 3.81,
          end_range: 4.76,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 48,
        suggested_bid: 4.58,
        suggested_bid_range: {
          start_range: 3.44,
          median_range: 4.58,
          end_range: 5.73,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 48,
        suggested_bid: 2.9,
        suggested_bid_range: {
          start_range: 2.35,
          median_range: 2.9,
          end_range: 3.57,
        },
      },
    },
  },
  {
    keyword: "organic real sea moss",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 49,
        suggested_bid: 4.5,
        suggested_bid_range: {
          start_range: 2.43,
          median_range: 4.5,
          end_range: 5.55,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 49,
        suggested_bid: 1.79,
        suggested_bid_range: {
          start_range: 1.34,
          median_range: 1.79,
          end_range: 2.24,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 49,
        suggested_bid: 1.79,
        suggested_bid_range: {
          start_range: 1.34,
          median_range: 1.79,
          end_range: 2.24,
        },
      },
    },
  },
  {
    keyword: "burdock root organic",
    search_term_impression_rank: 36,
    search_term_impression_share: 0.321501819995935,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 50,
        suggested_bid: 1.66,
        suggested_bid_range: {
          start_range: 1.41,
          median_range: 1.66,
          end_range: 2.42,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 50,
        suggested_bid: 2.45,
        suggested_bid_range: {
          start_range: 1.84,
          median_range: 2.45,
          end_range: 3.06,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 50,
        suggested_bid: 2.97,
        suggested_bid_range: {
          start_range: 2.23,
          median_range: 2.97,
          end_range: 3.71,
        },
      },
    },
  },
  {
    keyword: "sea moss vitamins",
    search_term_impression_rank: 4,
    search_term_impression_share: 1.7733230531996915,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 51,
        suggested_bid: 5.71,
        suggested_bid_range: {
          start_range: 4.28,
          median_range: 5.71,
          end_range: 7.14,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 51,
        suggested_bid: 5.19,
        suggested_bid_range: {
          start_range: 3.89,
          median_range: 5.19,
          end_range: 6.49,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 51,
        suggested_bid: 5.74,
        suggested_bid_range: {
          start_range: 4.31,
          median_range: 5.74,
          end_range: 7.18,
        },
      },
    },
  },
  {
    keyword: "irish sea moss raw organic",
    search_term_impression_rank: 32,
    search_term_impression_share: 0.04639592505577383,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 52,
        suggested_bid: 3.59,
        suggested_bid_range: {
          start_range: 2.85,
          median_range: 3.59,
          end_range: 4.43,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 52,
        suggested_bid: 4.01,
        suggested_bid_range: {
          start_range: 3.01,
          median_range: 4.01,
          end_range: 5.01,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 52,
        suggested_bid: 3.63,
        suggested_bid_range: {
          start_range: 3.16,
          median_range: 3.63,
          end_range: 4.35,
        },
      },
    },
  },
  {
    keyword: "organic nature sea moss",
    search_term_impression_rank: 5,
    search_term_impression_share: 1.0452961672473868,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 53,
        suggested_bid: 4,
        suggested_bid_range: {
          start_range: 3,
          median_range: 4,
          end_range: 5,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 53,
        suggested_bid: 3.84,
        suggested_bid_range: {
          start_range: 2.87,
          median_range: 3.84,
          end_range: 4.24,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 53,
        suggested_bid: 4.03,
        suggested_bid_range: {
          start_range: 3.02,
          median_range: 4.03,
          end_range: 5.04,
        },
      },
    },
  },
  {
    keyword: "real sea moss",
    search_term_impression_rank: 23,
    search_term_impression_share: 0.0328515111695138,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 54,
        suggested_bid: 5.73,
        suggested_bid_range: {
          start_range: 4.3,
          median_range: 5.73,
          end_range: 7.16,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 54,
        suggested_bid: 2.67,
        suggested_bid_range: {
          start_range: 2,
          median_range: 2.67,
          end_range: 3.34,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 54,
        suggested_bid: 3.29,
        suggested_bid_range: {
          start_range: 2.47,
          median_range: 3.29,
          end_range: 4.11,
        },
      },
    },
  },
  {
    keyword: "sea moss vitamins women",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 55,
        suggested_bid: 5.01,
        suggested_bid_range: {
          start_range: 3.76,
          median_range: 5.01,
          end_range: 6.26,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 55,
        suggested_bid: 4.96,
        suggested_bid_range: {
          start_range: 3.72,
          median_range: 4.96,
          end_range: 6.2,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 55,
        suggested_bid: 4.87,
        suggested_bid_range: {
          start_range: 3.65,
          median_range: 4.87,
          end_range: 6.09,
        },
      },
    },
  },
  {
    keyword: "organic sea moss elderberry",
    search_term_impression_rank: 1,
    search_term_impression_share: 100,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 56,
        suggested_bid: 2.64,
        suggested_bid_range: {
          start_range: 1.98,
          median_range: 2.64,
          end_range: 3.3,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 56,
        suggested_bid: 3.47,
        suggested_bid_range: {
          start_range: 2.33,
          median_range: 3.47,
          end_range: 4.03,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 56,
        suggested_bid: 5.07,
        suggested_bid_range: {
          start_range: 3.8,
          median_range: 5.07,
          end_range: 6.34,
        },
      },
    },
  },
  {
    keyword: "sea moss advanced capsules",
    search_term_impression_rank: 8,
    search_term_impression_share: 0.3141831238779174,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 57,
        suggested_bid: 7.14,
        suggested_bid_range: {
          start_range: 6.43,
          median_range: 7.14,
          end_range: 7.85,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 57,
        suggested_bid: 2.71,
        suggested_bid_range: {
          start_range: 2.03,
          median_range: 2.71,
          end_range: 3.39,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 57,
        suggested_bid: 10,
        suggested_bid_range: {
          start_range: 9,
          median_range: 10,
          end_range: 11,
        },
      },
    },
  },
  {
    keyword: "burdock root",
    search_term_impression_rank: 90,
    search_term_impression_share: 0.0009809066520184606,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 58,
        suggested_bid: 2.59,
        suggested_bid_range: {
          start_range: 1.94,
          median_range: 2.59,
          end_range: 3.24,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 58,
        suggested_bid: 2.82,
        suggested_bid_range: {
          start_range: 2.11,
          median_range: 2.82,
          end_range: 3.21,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 58,
        suggested_bid: 2.58,
        suggested_bid_range: {
          start_range: 1.94,
          median_range: 2.58,
          end_range: 3.23,
        },
      },
    },
  },
  {
    keyword: "seamoss raw organic",
    search_term_impression_rank: 58,
    search_term_impression_share: 0.0023575532638640973,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 59,
        suggested_bid: 2.85,
        suggested_bid_range: {
          start_range: 2.14,
          median_range: 2.85,
          end_range: 3.56,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 59,
        suggested_bid: 2.98,
        suggested_bid_range: {
          start_range: 2.24,
          median_range: 2.98,
          end_range: 3.73,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 59,
        suggested_bid: 2.83,
        suggested_bid_range: {
          start_range: 2.12,
          median_range: 2.83,
          end_range: 3.54,
        },
      },
    },
  },
  {
    keyword: "sea moss capsules extra strength",
    search_term_impression_rank: 8,
    search_term_impression_share: 1.9607843137254901,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 60,
        suggested_bid: 2.99,
        suggested_bid_range: {
          start_range: 2.24,
          median_range: 2.99,
          end_range: 3.8,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 60,
        suggested_bid: 3.78,
        suggested_bid_range: {
          start_range: 2.56,
          median_range: 3.78,
          end_range: 4.69,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 60,
        suggested_bid: 2.99,
        suggested_bid_range: {
          start_range: 2.24,
          median_range: 2.99,
          end_range: 3.8,
        },
      },
    },
  },
  {
    keyword: "sea moss complex",
    search_term_impression_rank: 11,
    search_term_impression_share: 0.04620953456729905,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 61,
        suggested_bid: 3.72,
        suggested_bid_range: {
          start_range: 2.68,
          median_range: 3.72,
          end_range: 4.91,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 61,
        suggested_bid: 4.77,
        suggested_bid_range: {
          start_range: 3.22,
          median_range: 4.77,
          end_range: 5.73,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 61,
        suggested_bid: 4.74,
        suggested_bid_range: {
          start_range: 3.34,
          median_range: 4.74,
          end_range: 6.23,
        },
      },
    },
  },
  {
    keyword: "sea moss gel capsules",
    search_term_impression_rank: 16,
    search_term_impression_share: 0.5527406725011516,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 62,
        suggested_bid: 3.75,
        suggested_bid_range: {
          start_range: 2.53,
          median_range: 3.75,
          end_range: 4.45,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 62,
        suggested_bid: 3.38,
        suggested_bid_range: {
          start_range: 2.54,
          median_range: 3.38,
          end_range: 4.22,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 62,
        suggested_bid: 5.18,
        suggested_bid_range: {
          start_range: 3.89,
          median_range: 5.18,
          end_range: 6.48,
        },
      },
    },
  },
  {
    keyword: "sea moss raw organic",
    search_term_impression_rank: 75,
    search_term_impression_share: 0.004051884379479231,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 63,
        suggested_bid: 4.44,
        suggested_bid_range: {
          start_range: 2.86,
          median_range: 4.44,
          end_range: 5.62,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 63,
        suggested_bid: 3.09,
        suggested_bid_range: {
          start_range: 2.29,
          median_range: 3.09,
          end_range: 4.38,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 63,
        suggested_bid: 4.48,
        suggested_bid_range: {
          start_range: 2.91,
          median_range: 4.48,
          end_range: 5.13,
        },
      },
    },
  },
  {
    keyword: "raw sea moss",
    search_term_impression_rank: 28,
    search_term_impression_share: 0.02559836170485089,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 64,
        suggested_bid: 4.59,
        suggested_bid_range: {
          start_range: 3.44,
          median_range: 4.59,
          end_range: 5.74,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 64,
        suggested_bid: 4.27,
        suggested_bid_range: {
          start_range: 3.2,
          median_range: 4.27,
          end_range: 5.34,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 64,
        suggested_bid: 3.19,
        suggested_bid_range: {
          start_range: 2.43,
          median_range: 3.19,
          end_range: 4.55,
        },
      },
    },
  },
  {
    keyword: "vegan life moss",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 65,
        suggested_bid: 4.3,
        suggested_bid_range: {
          start_range: 3.22,
          median_range: 4.3,
          end_range: 5.38,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 65,
        suggested_bid: 4.93,
        suggested_bid_range: {
          start_range: 3.7,
          median_range: 4.93,
          end_range: 6.16,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 65,
        suggested_bid: 4.31,
        suggested_bid_range: {
          start_range: 3.23,
          median_range: 4.31,
          end_range: 5.39,
        },
      },
    },
  },
  {
    keyword: "pure supplement",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 66,
        suggested_bid: 10,
        suggested_bid_range: {
          start_range: 9,
          median_range: 10,
          end_range: 11,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 66,
        suggested_bid: 1.92,
        suggested_bid_range: {
          start_range: 1.44,
          median_range: 1.92,
          end_range: 2.4,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 66,
        suggested_bid: 4.5,
        suggested_bid_range: {
          start_range: 3.38,
          median_range: 4.5,
          end_range: 5.63,
        },
      },
    },
  },
  {
    keyword: "sea moss advanced",
    search_term_impression_rank: 25,
    search_term_impression_share: 0.04837149306675266,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 67,
        suggested_bid: 10,
        suggested_bid_range: {
          start_range: 9,
          median_range: 10,
          end_range: 11,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 67,
        suggested_bid: 3.13,
        suggested_bid_range: {
          start_range: 2.35,
          median_range: 3.13,
          end_range: 3.91,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 67,
        suggested_bid: 7.14,
        suggested_bid_range: {
          start_range: 6.43,
          median_range: 7.14,
          end_range: 7.85,
        },
      },
    },
  },
  {
    keyword: "ocean wild sea moss capsules",
    search_term_impression_rank: 19,
    search_term_impression_share: 0.1802776275464215,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 68,
        suggested_bid: 4.3,
        suggested_bid_range: {
          start_range: 2.51,
          median_range: 4.3,
          end_range: 4.91,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 68,
        suggested_bid: 4.3,
        suggested_bid_range: {
          start_range: 2.5,
          median_range: 4.3,
          end_range: 4.97,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 68,
        suggested_bid: 3.72,
        suggested_bid_range: {
          start_range: 2.14,
          median_range: 3.72,
          end_range: 4.29,
        },
      },
    },
  },
  {
    keyword: "seamoss gel",
    search_term_impression_rank: 57,
    search_term_impression_share: 0.0020579095754532546,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 69,
        suggested_bid: 0.99,
        suggested_bid_range: {
          start_range: 0.74,
          median_range: 0.99,
          end_range: 1.24,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 69,
        suggested_bid: 2.54,
        suggested_bid_range: {
          start_range: 2.24,
          median_range: 2.54,
          end_range: 3.15,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 69,
        suggested_bid: 2.94,
        suggested_bid_range: {
          start_range: 2.21,
          median_range: 2.94,
          end_range: 3.68,
        },
      },
    },
  },
  {
    keyword: "organic sea moss gel",
    search_term_impression_rank: 39,
    search_term_impression_share: 0.0035249278491330882,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 70,
        suggested_bid: 2.3,
        suggested_bid_range: {
          start_range: 1.62,
          median_range: 2.3,
          end_range: 2.89,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 70,
        suggested_bid: 1.88,
        suggested_bid_range: {
          start_range: 1.47,
          median_range: 1.88,
          end_range: 2.33,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 70,
        suggested_bid: 1.91,
        suggested_bid_range: {
          start_range: 1.49,
          median_range: 1.91,
          end_range: 2.7,
        },
      },
    },
  },
  {
    keyword: "true sea moss",
    search_term_impression_rank: 8,
    search_term_impression_share: 0.0342583076396026,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 71,
        suggested_bid: 4.32,
        suggested_bid_range: {
          start_range: 3.24,
          median_range: 4.32,
          end_range: 5.4,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 71,
        suggested_bid: 2.74,
        suggested_bid_range: {
          start_range: 2.01,
          median_range: 2.74,
          end_range: 3.43,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 71,
        suggested_bid: 2.74,
        suggested_bid_range: {
          start_range: 2,
          median_range: 2.74,
          end_range: 3.43,
        },
      },
    },
  },
  {
    keyword: "green sea moss capsules",
    search_term_impression_rank: 4,
    search_term_impression_share: 4.45859872611465,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 72,
        suggested_bid: 2.2,
        suggested_bid_range: {
          start_range: 1.83,
          median_range: 2.2,
          end_range: 2.75,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 72,
        suggested_bid: 2.23,
        suggested_bid_range: {
          start_range: 1.83,
          median_range: 2.23,
          end_range: 2.79,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 72,
        suggested_bid: 2.34,
        suggested_bid_range: {
          start_range: 1.83,
          median_range: 2.34,
          end_range: 3.44,
        },
      },
    },
  },
  {
    keyword: "sea moss advanced superfood",
    search_term_impression_rank: 13,
    search_term_impression_share: 0.07923930269413629,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 73,
        suggested_bid: 3.93,
        suggested_bid_range: {
          start_range: 2.39,
          median_range: 3.93,
          end_range: 4.74,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 73,
        suggested_bid: 10,
        suggested_bid_range: {
          start_range: 9,
          median_range: 10,
          end_range: 11,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 73,
        suggested_bid: 5.47,
        suggested_bid_range: {
          start_range: 4.1,
          median_range: 5.47,
          end_range: 6.84,
        },
      },
    },
  },
  {
    keyword: "sea moss capsules dr sebi",
    search_term_impression_rank: 12,
    search_term_impression_share: 0.3787878787878788,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 74,
        suggested_bid: 2.98,
        suggested_bid_range: {
          start_range: 2.63,
          median_range: 2.98,
          end_range: 3.67,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 74,
        suggested_bid: 2.92,
        suggested_bid_range: {
          start_range: 2.19,
          median_range: 2.92,
          end_range: 3.65,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 74,
        suggested_bid: 2.98,
        suggested_bid_range: {
          start_range: 2.24,
          median_range: 2.98,
          end_range: 3.73,
        },
      },
    },
  },
  {
    keyword: "transformation factory sea moss",
    search_term_impression_rank: 15,
    search_term_impression_share: 0.21978021978021978,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 75,
        suggested_bid: 3.33,
        suggested_bid_range: {
          start_range: 2.5,
          median_range: 3.33,
          end_range: 4.16,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 75,
        suggested_bid: 4.08,
        suggested_bid_range: {
          start_range: 2.21,
          median_range: 4.08,
          end_range: 4.79,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 75,
        suggested_bid: 3.81,
        suggested_bid_range: {
          start_range: 2.86,
          median_range: 3.81,
          end_range: 4.38,
        },
      },
    },
  },
  {
    keyword: "purple sea moss capsules",
    search_term_impression_rank: 5,
    search_term_impression_share: 0.19011406844106463,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 76,
        suggested_bid: 3.98,
        suggested_bid_range: {
          start_range: 3.27,
          median_range: 3.98,
          end_range: 4.98,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 76,
        suggested_bid: 3.97,
        suggested_bid_range: {
          start_range: 3.26,
          median_range: 3.97,
          end_range: 4.97,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 76,
        suggested_bid: 3.61,
        suggested_bid_range: {
          start_range: 2.71,
          median_range: 3.61,
          end_range: 4.51,
        },
      },
    },
  },
  {
    keyword: "wild sourced sea moss",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 77,
        suggested_bid: 4.27,
        suggested_bid_range: {
          start_range: 3.2,
          median_range: 4.27,
          end_range: 5.34,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 77,
        suggested_bid: 4.28,
        suggested_bid_range: {
          start_range: 3.21,
          median_range: 4.28,
          end_range: 5.35,
        },
      },
    },
  },
  {
    keyword: "mary ruth organic sea moss",
    search_term_impression_rank: 1,
    search_term_impression_share: 100,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 78,
        suggested_bid: 2.3,
        suggested_bid_range: {
          start_range: 1.47,
          median_range: 2.3,
          end_range: 3.04,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 78,
        suggested_bid: 2.29,
        suggested_bid_range: {
          start_range: 1.48,
          median_range: 2.29,
          end_range: 2.69,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 78,
        suggested_bid: 1.8,
        suggested_bid_range: {
          start_range: 1.35,
          median_range: 1.8,
          end_range: 2.42,
        },
      },
    },
  },
  {
    keyword: "sea moss advance",
    search_term_impression_rank: 10,
    search_term_impression_share: 0.2840175574490059,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 79,
        suggested_bid: 7.14,
        suggested_bid_range: {
          start_range: 6.43,
          median_range: 7.14,
          end_range: 7.85,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 79,
        suggested_bid: 4.94,
        suggested_bid_range: {
          start_range: 3.03,
          median_range: 4.94,
          end_range: 6.18,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 79,
        suggested_bid: 4.99,
        suggested_bid_range: {
          start_range: 3.74,
          median_range: 4.99,
          end_range: 6.24,
        },
      },
    },
  },
  {
    keyword: "stella sea moss",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 80,
        suggested_bid: 4.02,
        suggested_bid_range: {
          start_range: 3.01,
          median_range: 4.02,
          end_range: 5.02,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 80,
        suggested_bid: 4.01,
        suggested_bid_range: {
          start_range: 3.01,
          median_range: 4.01,
          end_range: 5.01,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 80,
        suggested_bid: 2.41,
        suggested_bid_range: {
          start_range: 1.81,
          median_range: 2.41,
          end_range: 3.01,
        },
      },
    },
  },
  {
    keyword: "sea moss gel organic raw",
    search_term_impression_rank: 43,
    search_term_impression_share: 0.0073788485306867865,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 81,
        suggested_bid: 2.81,
        suggested_bid_range: {
          start_range: 2.11,
          median_range: 2.81,
          end_range: 3.51,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 81,
        suggested_bid: 2.47,
        suggested_bid_range: {
          start_range: 1.85,
          median_range: 2.47,
          end_range: 3.09,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 81,
        suggested_bid: 2.86,
        suggested_bid_range: {
          start_range: 2.15,
          median_range: 2.86,
          end_range: 3.58,
        },
      },
    },
  },
  {
    keyword: "102 minerals sea moss",
    search_term_impression_rank: 7,
    search_term_impression_share: 1.6528925619834711,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 82,
        suggested_bid: 4.3,
        suggested_bid_range: {
          start_range: 3.22,
          median_range: 4.3,
          end_range: 5.74,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 82,
        suggested_bid: 4.53,
        suggested_bid_range: {
          start_range: 3.4,
          median_range: 4.53,
          end_range: 5.66,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 82,
        suggested_bid: 4.34,
        suggested_bid_range: {
          start_range: 3.26,
          median_range: 4.34,
          end_range: 5.43,
        },
      },
    },
  },
  {
    keyword: "sea",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 83,
        suggested_bid: 5.75,
        suggested_bid_range: {
          start_range: 4.31,
          median_range: 5.75,
          end_range: 7.19,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 83,
        suggested_bid: 4.46,
        suggested_bid_range: {
          start_range: 2.94,
          median_range: 4.46,
          end_range: 5.71,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 83,
        suggested_bid: 1.96,
        suggested_bid_range: {
          start_range: 1.47,
          median_range: 1.96,
          end_range: 2.45,
        },
      },
    },
  },
  {
    keyword: "sea moss gel",
    search_term_impression_rank: 86,
    search_term_impression_share: 0.002870041851963241,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 84,
        suggested_bid: 1.65,
        suggested_bid_range: {
          start_range: 1.25,
          median_range: 1.65,
          end_range: 1.88,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 84,
        suggested_bid: 2.06,
        suggested_bid_range: {
          start_range: 1.33,
          median_range: 2.06,
          end_range: 2.83,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 84,
        suggested_bid: 2.33,
        suggested_bid_range: {
          start_range: 1.63,
          median_range: 2.33,
          end_range: 3.14,
        },
      },
    },
  },
  {
    keyword: "zoyava sea moss supplement",
    search_term_impression_rank: 6,
    search_term_impression_share: 1.8203883495145632,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 85,
        suggested_bid: 5.14,
        suggested_bid_range: {
          start_range: 3.85,
          median_range: 5.14,
          end_range: 6.43,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 85,
        suggested_bid: 5.09,
        suggested_bid_range: {
          start_range: 3.51,
          median_range: 5.09,
          end_range: 5.68,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 85,
        suggested_bid: 5.06,
        suggested_bid_range: {
          start_range: 3.8,
          median_range: 5.06,
          end_range: 6.32,
        },
      },
    },
  },
  {
    keyword: "trinity sea moss",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 86,
        suggested_bid: 2.38,
        suggested_bid_range: {
          start_range: 1.9,
          median_range: 2.38,
          end_range: 2.86,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 86,
        suggested_bid: 3.19,
        suggested_bid_range: {
          start_range: 1.89,
          median_range: 3.19,
          end_range: 3.77,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 86,
        suggested_bid: 1.86,
        suggested_bid_range: {
          start_range: 1.4,
          median_range: 1.86,
          end_range: 2.33,
        },
      },
    },
  },
  {
    keyword: "vivanutra regeneration sea moss supplement",
    search_term_impression_rank: 1,
    search_term_impression_share: 100,
    bid_info: [],
  },
  {
    keyword: "sea moss powder",
    search_term_impression_rank: 68,
    search_term_impression_share: 0.00235200037632006,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 88,
        suggested_bid: 3.47,
        suggested_bid_range: {
          start_range: 2.51,
          median_range: 3.47,
          end_range: 4.06,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 88,
        suggested_bid: 3.46,
        suggested_bid_range: {
          start_range: 2.6,
          median_range: 3.46,
          end_range: 4.33,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 88,
        suggested_bid: 3.47,
        suggested_bid_range: {
          start_range: 2.51,
          median_range: 3.47,
          end_range: 4.06,
        },
      },
    },
  },
  {
    keyword: "dr sebi sea moss",
    search_term_impression_rank: 44,
    search_term_impression_share: 0.00174128053770743,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 89,
        suggested_bid: 2.59,
        suggested_bid_range: {
          start_range: 1.94,
          median_range: 2.59,
          end_range: 3.24,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 89,
        suggested_bid: 2.77,
        suggested_bid_range: {
          start_range: 2.08,
          median_range: 2.77,
          end_range: 3.46,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 89,
        suggested_bid: 2.7,
        suggested_bid_range: {
          start_range: 2.03,
          median_range: 2.7,
          end_range: 3.38,
        },
      },
    },
  },
  {
    keyword: "antiparasitic humans",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 90,
        suggested_bid: 0.8,
        suggested_bid_range: {
          start_range: 0.6,
          median_range: 0.8,
          end_range: 1,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 90,
        suggested_bid: 1.87,
        suggested_bid_range: {
          start_range: 1.4,
          median_range: 1.87,
          end_range: 2.34,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 90,
        suggested_bid: 0.8,
        suggested_bid_range: {
          start_range: 0.6,
          median_range: 0.8,
          end_range: 1,
        },
      },
    },
  },
  {
    keyword: "sea moss gummies adults",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 91,
        suggested_bid: 4.38,
        suggested_bid_range: {
          start_range: 3.24,
          median_range: 4.38,
          end_range: 4.85,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 91,
        suggested_bid: 4.3,
        suggested_bid_range: {
          start_range: 3.19,
          median_range: 4.3,
          end_range: 4.91,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 91,
        suggested_bid: 4.38,
        suggested_bid_range: {
          start_range: 3.24,
          median_range: 4.38,
          end_range: 4.84,
        },
      },
    },
  },
  {
    keyword: "sea moss gummies",
    search_term_impression_rank: 47,
    search_term_impression_share: 0.012771494025335452,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 92,
        suggested_bid: 3.16,
        suggested_bid_range: {
          start_range: 1.92,
          median_range: 3.16,
          end_range: 3.95,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 92,
        suggested_bid: 3.48,
        suggested_bid_range: {
          start_range: 2.77,
          median_range: 3.48,
          end_range: 4.56,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 92,
        suggested_bid: 4.39,
        suggested_bid_range: {
          start_range: 3.17,
          median_range: 4.39,
          end_range: 5.46,
        },
      },
    },
  },
  {
    keyword: "infinite age sea moss advanced",
    search_term_impression_rank: 25,
    search_term_impression_share: 0.04149760236075249,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 93,
        suggested_bid: 10,
        suggested_bid_range: {
          start_range: 9,
          median_range: 10,
          end_range: 11,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 93,
        suggested_bid: 12.58,
        suggested_bid_range: {
          start_range: 11.32,
          median_range: 12.58,
          end_range: 13.84,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 93,
        suggested_bid: 7.14,
        suggested_bid_range: {
          start_range: 6.43,
          median_range: 7.14,
          end_range: 7.85,
        },
      },
    },
  },
  {
    keyword: "sea moss black seed oil",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 94,
        suggested_bid: 3.35,
        suggested_bid_range: {
          start_range: 2.03,
          median_range: 3.35,
          end_range: 4.11,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 94,
        suggested_bid: 5.77,
        suggested_bid_range: {
          start_range: 4.33,
          median_range: 5.77,
          end_range: 7.21,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 94,
        suggested_bid: 3.35,
        suggested_bid_range: {
          start_range: 2.06,
          median_range: 3.35,
          end_range: 4.14,
        },
      },
    },
  },
  {
    keyword: "club moss herb",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 95,
        suggested_bid: 0.79,
        suggested_bid_range: {
          start_range: 0.59,
          median_range: 0.79,
          end_range: 0.99,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 95,
        suggested_bid: 0.79,
        suggested_bid_range: {
          start_range: 0.59,
          median_range: 0.79,
          end_range: 0.99,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 95,
        suggested_bid: 0.79,
        suggested_bid_range: {
          start_range: 0.59,
          median_range: 0.79,
          end_range: 0.99,
        },
      },
    },
  },
  {
    keyword: "sea moss weight loss",
    search_term_impression_rank: 7,
    search_term_impression_share: 0.7547169811320755,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 96,
        suggested_bid: 1.55,
        suggested_bid_range: {
          start_range: 1.2,
          median_range: 1.55,
          end_range: 1.89,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 96,
        suggested_bid: 2.33,
        suggested_bid_range: {
          start_range: 1.38,
          median_range: 2.33,
          end_range: 3.07,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 96,
        suggested_bid: 2.5,
        suggested_bid_range: {
          start_range: 1.88,
          median_range: 2.5,
          end_range: 3.13,
        },
      },
    },
  },
  {
    keyword: "stella sea moss weight loss",
    search_term_impression_rank: 5,
    search_term_impression_share: 0.7651715039577837,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 97,
        suggested_bid: 2.29,
        suggested_bid_range: {
          start_range: 1.72,
          median_range: 2.29,
          end_range: 2.86,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 97,
        suggested_bid: 2.29,
        suggested_bid_range: {
          start_range: 1.72,
          median_range: 2.29,
          end_range: 2.86,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 97,
        suggested_bid: 2.19,
        suggested_bid_range: {
          start_range: 1.64,
          median_range: 2.19,
          end_range: 2.74,
        },
      },
    },
  },
  {
    keyword: "sea more life",
    search_term_impression_rank: 1,
    search_term_impression_share: 100,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 98,
        suggested_bid: 2.55,
        suggested_bid_range: {
          start_range: 1.87,
          median_range: 2.55,
          end_range: 3.17,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 98,
        suggested_bid: 1.87,
        suggested_bid_range: {
          start_range: 1.4,
          median_range: 1.87,
          end_range: 2.34,
        },
      },
    },
  },
  {
    keyword: "organic burdock root powder",
    search_term_impression_rank: 11,
    search_term_impression_share: 1.098901098901099,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 99,
        suggested_bid: 2.34,
        suggested_bid_range: {
          start_range: 1.43,
          median_range: 2.34,
          end_range: 2.66,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 99,
        suggested_bid: 0.64,
        suggested_bid_range: {
          start_range: 0.47,
          median_range: 0.64,
          end_range: 0.8,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 99,
        suggested_bid: 2.13,
        suggested_bid_range: {
          start_range: 1.6,
          median_range: 2.13,
          end_range: 2.59,
        },
      },
    },
  },
  {
    keyword: "celtic sea salt organic",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 100,
        suggested_bid: 1.41,
        suggested_bid_range: {
          start_range: 0.74,
          median_range: 1.41,
          end_range: 1.91,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 100,
        suggested_bid: 1.97,
        suggested_bid_range: {
          start_range: 1.48,
          median_range: 1.97,
          end_range: 2.18,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 100,
        suggested_bid: 2.14,
        suggested_bid_range: {
          start_range: 1.61,
          median_range: 2.14,
          end_range: 2.68,
        },
      },
    },
  },
  {
    keyword: "sarsaparilla capsules",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 101,
        suggested_bid: 0.94,
        suggested_bid_range: {
          start_range: 0.72,
          median_range: 0.94,
          end_range: 1.06,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 101,
        suggested_bid: 1.16,
        suggested_bid_range: {
          start_range: 0.87,
          median_range: 1.16,
          end_range: 1.45,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 101,
        suggested_bid: 1.12,
        suggested_bid_range: {
          start_range: 0.84,
          median_range: 1.12,
          end_range: 1.4,
        },
      },
    },
  },
  {
    keyword: "zotrim",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 102,
        suggested_bid: 2.32,
        suggested_bid_range: {
          start_range: 1.74,
          median_range: 2.32,
          end_range: 2.77,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 102,
        suggested_bid: 2.77,
        suggested_bid_range: {
          start_range: 2.08,
          median_range: 2.77,
          end_range: 3.46,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 102,
        suggested_bid: 2.32,
        suggested_bid_range: {
          start_range: 1.73,
          median_range: 2.32,
          end_range: 2.77,
        },
      },
    },
  },
  {
    keyword: "insea2",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 103,
        suggested_bid: 3.5,
        suggested_bid_range: {
          start_range: 2.63,
          median_range: 3.5,
          end_range: 4.38,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 103,
        suggested_bid: 3.51,
        suggested_bid_range: {
          start_range: 2.63,
          median_range: 3.51,
          end_range: 4.39,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 103,
        suggested_bid: 1.19,
        suggested_bid_range: {
          start_range: 1.06,
          median_range: 1.19,
          end_range: 1.32,
        },
      },
    },
  },
  {
    keyword: "vervain",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 104,
        suggested_bid: 3.44,
        suggested_bid_range: {
          start_range: 2.58,
          median_range: 3.44,
          end_range: 4.3,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 104,
        suggested_bid: 1.66,
        suggested_bid_range: {
          start_range: 1.24,
          median_range: 1.66,
          end_range: 2.07,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 104,
        suggested_bid: 0.4,
        suggested_bid_range: {
          start_range: 0.32,
          median_range: 0.4,
          end_range: 0.5,
        },
      },
    },
  },
  {
    keyword: "musgo marino organico",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 105,
        suggested_bid: 2.26,
        suggested_bid_range: {
          start_range: 1.69,
          median_range: 2.26,
          end_range: 2.83,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 105,
        suggested_bid: 2.26,
        suggested_bid_range: {
          start_range: 1.69,
          median_range: 2.26,
          end_range: 2.83,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 105,
        suggested_bid: 2.26,
        suggested_bid_range: {
          start_range: 1.69,
          median_range: 2.26,
          end_range: 2.83,
        },
      },
    },
  },
  {
    keyword: "sea miss",
    search_term_impression_rank: 3,
    search_term_impression_share: 2.9914529914529915,
    bid_info: {
      EXACT: {
        match_type: "EXACT",
        rank: 106,
        suggested_bid: 1.54,
        suggested_bid_range: {
          start_range: 1.16,
          median_range: 1.54,
          end_range: 1.93,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 106,
        suggested_bid: 1.35,
        suggested_bid_range: {
          start_range: 1.01,
          median_range: 1.35,
          end_range: 1.69,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 106,
        suggested_bid: 1.58,
        suggested_bid_range: {
          start_range: 1.29,
          median_range: 1.58,
          end_range: 1.8,
        },
      },
    },
  },
  {
    keyword: "aciclovir tablets",
    search_term_impression_rank: 7,
    search_term_impression_share: 0.8928571428571429,
    bid_info: {
      BROAD: {
        match_type: "BROAD",
        rank: 107,
        suggested_bid: 1.63,
        suggested_bid_range: {
          start_range: 1.16,
          median_range: 1.63,
          end_range: 1.82,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 107,
        suggested_bid: 2.01,
        suggested_bid_range: {
          start_range: 1.51,
          median_range: 2.01,
          end_range: 2.51,
        },
      },
      PHRASE: {
        match_type: "PHRASE",
        rank: 107,
        suggested_bid: 1.63,
        suggested_bid_range: {
          start_range: 1.15,
          median_range: 1.63,
          end_range: 1.82,
        },
      },
    },
  },
  {
    keyword: "fucoidan made japan",
    search_term_impression_rank: null,
    search_term_impression_share: null,
    bid_info: {
      PHRASE: {
        match_type: "PHRASE",
        rank: 108,
        suggested_bid: 1.53,
        suggested_bid_range: {
          start_range: 1.15,
          median_range: 1.53,
          end_range: 1.91,
        },
      },
      BROAD: {
        match_type: "BROAD",
        rank: 108,
        suggested_bid: 1.21,
        suggested_bid_range: {
          start_range: 0.91,
          median_range: 1.21,
          end_range: 1.51,
        },
      },
      EXACT: {
        match_type: "EXACT",
        rank: 108,
        suggested_bid: 1.52,
        suggested_bid_range: {
          start_range: 1.14,
          median_range: 1.52,
          end_range: 1.9,
        },
      },
    },
  },
];

export const optimizationStrategy = [
  {
    label: <span>Reach</span>,
    key: "REACH",
    display_label: {
      header: "Reach",
      content:
        "We'll optimize your bids for higher viewable impressions. Drive brand or product awareness by showing your ad to relevant audiences to maximize reach.",
      description:
        "Cost type: Cost per 1,000 viewable impressions (VCPM) | Conversion attributed to viewable impressions and clicks",
    },
  },
  {
    label: <span>Page Visits</span>,
    key: "PAGE_VISITS",
    display_label: {
      header: "Page Visits",
      content:
        "We'll optimize your bids for higher page visits. Drive brand or product consideration by showing your ads to customers more likely to click.",
      description:
        "Cost type: Cost per click (CPC) | Conversion attributed to clicks",
    },
  },
  {
    label: <span>Conversions</span>,
    key: "CONVERSIONS",
    display_label: {
      header: "Conversions",
      content:
        "We'll optimize your bids for higher conversion rates. Drive sales by showing your ad to shoppers more likely to purchase your product.",
      description:
        "Cost type: Cost per click (CPC) | Conversion attributed to clicks",
    },
  },
];

export const targetingList = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    records: [
      {
        id: 1,
        item_name: "Contextual",
        description:
          "Reach audiences who are browsing products and content matching > criteria you choose.",
        created_at: 1718190379,
        updated_at: 1719277339,
      },
      {
        id: 2,
        item_name: "Remarketing audiences",
        description:
          "Reach relevant audiences who have viewed, purchased, or are browsing products and content matching criteria you choose.",
        created_at: 1718190379,
        updated_at: 1719277339,
      },
      {
        id: 3,
        item_name: "In-market audiences",
        description:
          "Reach audiences whose recent activity suggests they're likely to buy > products in a certain category.",
        created_at: 1718190379,
        updated_at: 1719277339,
      },
      {
        id: 4,
        item_name: "Interest and lifestyle audiences",
        description:
          "Reach audiences whose shopping and entertainment activity suggests certain interest or lifestyle preferences.",
        created_at: 1718190379,
        updated_at: 1719277339,
      },
    ],
    pagination: {
      totalCount: 4,
      page: 1,
      page_size: 10,
      page_count: 1,
    },
  },
  error: [],
};

export const dummyDropDownTargetList = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    records: {
      "In-market categories": [],
      "CDs & Vinyl": [
        {
          id: 1,
          item_name: "Vehicle GPS Units & Equipment",
          created_at: 1718190379,
          updated_at: 1719277339,
        },
        {
          id: 2,
          item_name: "Golf course GPS Units",
          created_at: 1718190379,
          updated_at: 1719277339,
        },
        {
          id: 3,
          item_name: "Running GPS Units",
          description:
            "Reach audiences whose recent activity suggests they're likely to buy > products in a certain category.",
          created_at: 1718190379,
          updated_at: 1719277339,
        },
        {
          id: 4,
          item_name: "Cycling GPS Units",
          created_at: 1718190379,
          updated_at: 1719277339,
        },
      ],
    },
    pagination: {
      totalCount: 4,
      page: 1,
      page_size: 10,
      page_count: 1,
    },
  },
  error: [],
};

export const dummyProductList = {
  code: "200",
  status: true,
  message: "Data Found.",
  data: {
    records: [
      {
        id: 6,
        system_event_process_id: 1257,
        brand_id: 0,
        brand_name: "Aclivia",
        vendor_id: null,
        marketplace_id: "ATVPDKIKX0DER",
        marketplace: "USA",
        item_name:
          "AcliMove – Joint Support Supplement for Mobility  Flexibility  Noni Fruit Capsules 4000mg Extract 81 Organic NonGMO Gluten Free Herbal Supplement",
        item_description: "",
        status: "Incomplete",
        listing_id: "0426B5Y10ZX",
        seller_sku: "ACL104105",
        product_weight: 0,
        universal_sku: null,
        price: 0,
        stock_quantity: 0,
        stock_availability: 0,
        gst_slab: 0,
        cogs: 0,
        quantity: 0,
        sales_rank: 0,
        open_date: "2024-04-25 19:01:08 PDT",
        image_url: "https://m.media-amazon.com/images/I/51VfvPdO0VL._SL75_.jpg",
        item_is_marketplace: "y",
        product_id_type: 1,
        zshop_shipping_fee: "",
        item_note: "",
        item_condition: "11",
        zshop_category1: "",
        zshop_browse_path: "",
        zshop_storefront_feature: "",
        asin1: "B0D2PGXJ73",
        asin2: "",
        asin3: "",
        will_ship_internationally: "",
        expedited_shipping: "",
        zshop_boldface: "",
        product_id: "B0D2PGXJ73",
        bid_for_featured_placement: "",
        add_delete: "",
        pending_quantity: "0",
        fulfillment_channel: "DEFAULT",
        merchant_shipping_group: "Migrated Template",
        product_width: 0,
        product_length: 0,
        product_height: 0,
        product_unit_weight: 0,
        package_weight: null,
        storage_fee: 0,
        fba_fee: 0,
        commission_fee: 0,
        fba_fees: 0,
        referral_fee: 0,
        total_fees: 0,
        source: "Amazon",
        region: null,
        created_at: 1718190379,
        updated_at: 1719277339,
      },
      {
        id: 4,
        system_event_process_id: 1257,
        brand_id: 0,
        brand_name: "Aclivia",
        vendor_id: null,
        marketplace_id: "ATVPDKIKX0DER",
        marketplace: "USA",
        item_name:
          "Aclivia AcliMove – Joint Support Supplement for Mobility  Flexibility – Joint Supplement with Hyaluronic Acid Collagen Egg Shell Membrane Calcium  Astaxanthin – NonGMO GlutenFree 30 Tablets",
        item_description:
          "Imagine waking up daily to a life where joint discomfort doesnt dictate your activities With AcliMove that vision becomes closer to reality Being more than a health supplement its your daily companion for excellent joint mobility and wellness What sets AcliMove apart is its unique composition featuring a blend of Hyaluronic Acid Collagen Collavant n2 Egg Shell Membrane Ovomet Boswellia Serrata and Astaxanthin combined with Vitamin D Calcium Turmeric and Black Pepper Our bone strength supplements are a leap to your joy of movement making our collagen hyaluronic acid complex tablet a musthave for your daily routine AcliMove provides the nutritional support your joints need so you can move freely and comfortablybrbrAt Aclivia were not just about formulating supplements but crafting a legacy of quality and integrity Every eggshell membrane supplement tablet shows our commitment to excellence using only the finest ingredients sourced globally Our oral hyaluronic acid supplement is manufactured in GMPcertified facilities with high standards Our mission extends beyond the collagen and hyaluronic acid supplements to the promise of a better more active life for our customersbrbrAcliMoves oral hyaluronic acid for joints is designed for ease of use with just one easytoswallow tablet once a day making it a convenient addition to your daily health regimenbrbrKey featuresbr9 key ingredients for joint health  wellnessbrFeaturing Ovomet An innovative undenatured collagen type I V  XbrScientifically Studied Collavant n2 Native undenatured type II collagenbrGlutenfree soyfree nonGMO shellfishfree peanutfree and lactosefreebrEasytoswallow onceaday tabletbrManufactured in GMPcertified facilities with globally sourced ingredientsbrbrAt Aclivia supplements are made from only the best ingredients With its convenient easytoswallow oral hyaluronic acid tablet form youre one step closer to enhanced joint mobility and wellness daily",
        status: "Active",
        listing_id: "0229BS6NMNM",
        seller_sku: "ACL105",
        product_weight: 0.13,
        universal_sku: null,
        price: 44.95,
        stock_quantity: 76,
        stock_availability: 1,
        gst_slab: 0,
        cogs: 0,
        quantity: 0,
        sales_rank: 0,
        open_date: "2024-02-28 19:17:15 PST",
        image_url: "https://m.media-amazon.com/images/I/41PDNctNPOL._SL75_.jpg",
        item_is_marketplace: "y",
        product_id_type: 3,
        zshop_shipping_fee: "",
        item_note: "",
        item_condition: "11",
        zshop_category1: "",
        zshop_browse_path: "",
        zshop_storefront_feature: "",
        asin1: "B0CWQJWRB9",
        asin2: "",
        asin3: "",
        will_ship_internationally: "",
        expedited_shipping: "",
        zshop_boldface: "",
        product_id: "611851287733",
        bid_for_featured_placement: "",
        add_delete: "",
        pending_quantity: "",
        fulfillment_channel: "AMAZON_NA",
        merchant_shipping_group: "Migrated Template",
        product_width: 0,
        product_length: 0,
        product_height: 0,
        product_unit_weight: 0,
        package_weight: null,
        storage_fee: 0,
        fba_fee: 0,
        commission_fee: 0,
        fba_fees: 0,
        referral_fee: 0,
        total_fees: 0,
        source: "Amazon",
        region: null,
        created_at: 1718190379,
        updated_at: 1719277339,
      },
      {
        id: 3,
        system_event_process_id: 1257,
        brand_id: 0,
        brand_name: "Aclivia",
        vendor_id: null,
        marketplace_id: "ATVPDKIKX0DER",
        marketplace: "USA",
        item_name:
          "Aclivia Noni Fruit Capsules 4000mg Extract 81 Organic NonGMO Gluten Free No Artificial Flavors Sweeteners or Preservatives Traditional Herbal Supplement  Morinda Citrifolia Capsules",
        item_description:
          "Adding the nutritional goodness of noni fruit to your daily diet has never been easier Our organic noni fruit capsules provide a hasslefree way to incorporate the benefits of noni into your routine without the need to consume the fruit directly or deal with its strong taste Now you can enjoy the potential advantages without any inconvenience Aclivia noni capsules are a rich source of vitamin C A E and B vitamins and contain essential minerals such as potassium calcium and magnesium Noni pills are made with a clean and natural formula suitable for various dietary preferences When it comes to quality we dont compromise Our dietary supplement is manufactured in the USA in a stateoftheart facility that follows strict Good Manufacturing Practices GMP We subject our product to rigorous thirdparty lab testing to ensure purity and safety This comprehensive testing guarantees that you receive a premium health supplement of the highest quality",
        status: "Active",
        listing_id: "0716YJBEMDM",
        seller_sku: "ACL104",
        product_weight: 0,
        universal_sku: null,
        price: 17.99,
        stock_quantity: 31,
        stock_availability: 1,
        gst_slab: 0,
        cogs: 0,
        quantity: 0,
        sales_rank: 0,
        open_date: "2021-07-15 22:57:19 PDT",
        image_url: "https://m.media-amazon.com/images/I/416-CIu6OgL._SL75_.jpg",
        item_is_marketplace: "y",
        product_id_type: 3,
        zshop_shipping_fee: "",
        item_note: "",
        item_condition: "11",
        zshop_category1: "",
        zshop_browse_path: "",
        zshop_storefront_feature: "",
        asin1: "B099MQX1MX",
        asin2: "",
        asin3: "",
        will_ship_internationally: "",
        expedited_shipping: "",
        zshop_boldface: "",
        product_id: "611851287726",
        bid_for_featured_placement: "",
        add_delete: "",
        pending_quantity: "",
        fulfillment_channel: "AMAZON_NA",
        merchant_shipping_group: "Migrated Template",
        product_width: 0,
        product_length: 0,
        product_height: 0,
        product_unit_weight: 0,
        package_weight: null,
        storage_fee: 0,
        fba_fee: 0,
        commission_fee: 0,
        fba_fees: 0,
        referral_fee: 0,
        total_fees: 0,
        source: "Amazon",
        region: null,
        created_at: 1718190379,
        updated_at: 1719277339,
      },
    ],
    pagination: {
      totalCount: 6,
      page: 1,
      page_size: 10,
      page_count: 1,
    },
  },
  error: [],
};

export const dummyKeywordTSuggestion = {
  code: "200",
  status: true,
  message: "Suggested Keyword Bids fetched.",
  data: [
    {
      rec_id: "a377bc15-42e2-4f61-b1c5-07411cc42f42",
      keyword: "aclivia",
      search_term_impression_rank: 1,
      search_term_impression_share: 100,
      bid_info: {
        EXACT: {
          match_type: "EXACT",
          rank: 1,
          suggested_bid: 3.26,
          suggested_bid_range: {
            start_range: 2.44,
            median_range: 3.26,
            end_range: 4.08,
          },
        },
        BROAD: {
          match_type: "BROAD",
          rank: 1,
          suggested_bid: 2.6,
          suggested_bid_range: {
            start_range: 2.08,
            median_range: 2.6,
            end_range: 3.37,
          },
        },
        PHRASE: {
          match_type: "PHRASE",
          rank: 1,
          suggested_bid: 2.6,
          suggested_bid_range: {
            start_range: 2.08,
            median_range: 2.6,
            end_range: 3.36,
          },
        },
      },
    },
    {
      rec_id: "833c7380-0375-469c-beaa-9136be07805e",
      keyword: "seamoss capsules",
      search_term_impression_rank: null,
      search_term_impression_share: null,
      bid_info: {
        EXACT: {
          match_type: "EXACT",
          rank: 2,
          suggested_bid: 4.3,
          suggested_bid_range: {
            start_range: 3.22,
            median_range: 4.3,
            end_range: 5.38,
          },
        },
        PHRASE: {
          match_type: "PHRASE",
          rank: 2,
          suggested_bid: 3.46,
          suggested_bid_range: {
            start_range: 2.59,
            median_range: 3.46,
            end_range: 4.33,
          },
        },
        BROAD: {
          match_type: "BROAD",
          rank: 2,
          suggested_bid: 5.59,
          suggested_bid_range: {
            start_range: 4.19,
            median_range: 5.59,
            end_range: 6.99,
          },
        },
      },
    },
    {
      rec_id: "16cca26f-75b1-42a9-9d6b-28e928b78a61",
      keyword: "seamoss bladderwrack burdock capsules",
      search_term_impression_rank: 15,
      search_term_impression_share: 0.04658963846440552,
      bid_info: {
        PHRASE: {
          match_type: "PHRASE",
          rank: 3,
          suggested_bid: 5.57,
          suggested_bid_range: {
            start_range: 4.18,
            median_range: 5.57,
            end_range: 6.96,
          },
        },
        EXACT: {
          match_type: "EXACT",
          rank: 3,
          suggested_bid: 5.05,
          suggested_bid_range: {
            start_range: 3.78,
            median_range: 5.05,
            end_range: 6.31,
          },
        },
        BROAD: {
          match_type: "BROAD",
          rank: 3,
          suggested_bid: 5.59,
          suggested_bid_range: {
            start_range: 4.19,
            median_range: 5.59,
            end_range: 6.99,
          },
        },
      },
    },
    {
      rec_id: "2b4fd5d0-d2c6-4e6f-b3bb-bc17df84bfe9",
      keyword: "irish moss",
      search_term_impression_rank: 19,
      search_term_impression_share: 0.31992687385740404,
      bid_info: {
        EXACT: {
          match_type: "EXACT",
          rank: 4,
          suggested_bid: 3.16,
          suggested_bid_range: {
            start_range: 2.37,
            median_range: 3.16,
            end_range: 3.96,
          },
        },
        BROAD: {
          match_type: "BROAD",
          rank: 4,
          suggested_bid: 6.96,
          suggested_bid_range: {
            start_range: 5.22,
            median_range: 6.96,
            end_range: 7.85,
          },
        },
        PHRASE: {
          match_type: "PHRASE",
          rank: 4,
          suggested_bid: 3.2,
          suggested_bid_range: {
            start_range: 2.84,
            median_range: 3.2,
            end_range: 3.68,
          },
        },
      },
    },
  ],
  error: [],
};

export const dummyProductTSuggestion = {
  code: "200",
  status: true,
  message: "Suggested products fetched.",
  data: [
    {
      recommendedAsin: "B09C14QPJH",
      min_bid: 0,
      suggested_bid: 0,
      max_bid: 0,
    },
    {
      recommendedAsin: "B0BWPY12SD",
      min_bid: 0,
      suggested_bid: 0,
      max_bid: 0,
    },
    {
      recommendedAsin: "B0CZ9SXD1G",
      min_bid: 0,
      suggested_bid: 0,
      max_bid: 0,
    },
    {
      recommendedAsin: "B09BYY8G58",
      min_bid: 0,
      suggested_bid: 0,
      max_bid: 0,
    },
  ],
  error: [],
};

export const dummyCategoriesTSuggestion = {
  code: "200",
  status: true,
  message: "Suggested categories fetched.",
  data: [
    {
      category_id: "3773931",
      parent_category_id: "23675621011",
      category_name: "Blended Vitamin & Mineral Supplements",
      category_translated_name: null,
      category_path:
        "/Health & Household/Vitamins, Minerals & Supplements/Blended Vitamin & Mineral Supplements",
      category_translated_path: null,
      products: {
        min: 21675,
        max: 36125,
      },
      min_bid: 0,
      suggested_bid: 0,
      max_bid: 0,
    },
  ],
  error: [],
};

export const adminCentralLogEventStatus = [
  {
    label: "PENDING",
    value: "PENDING",
  },
  {
    label: "IN_PROGRESS",
    value: "IN_PROGRESS",
  },
  {
    label: "DONE",
    value: "DONE",
  },
  {
    label: "FAILED",
    value: "FAILED",
  },
  {
    label: "SCHEDULED",
    value: "SCHEDULED",
  },
  {
    label: "FATAL",
    value: "FATAL",
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
  },
  {
    label: "NOT_FOUND",
    value: "NOT_FOUND",
  },
  {
    label: "NO DATA",
    value: "NO DATA",
  },
];

export const ruleKeywordDD = [
  {
    label: "Decrease bids (%)",
    value: "decrease_bids_percent",
  },
  {
    label: "Decrease bids (value)",
    value: "decrease_bids_value",
  },
  {
    label: "Increase bids (%)",
    value: "increase_bids_percent",
  },
  {
    label: "Increase bids (value)",
    value: "increase_bids_value",
  },
  { label: "Set bids", value: "set_bids" },
  { label: "Pause", value: "paused" },
  { label: "Enable", value: "enabled" },
  { label: "Do nothing", value: "do_nothing" },
];
export const ruleCampaignDD = [
  {
    label: "Decrease budget (%)",
    value: "decrease_budget_percent",
  },
  {
    label: "Decrease budget (value)",
    value: "decrease_budget_value",
  },
  {
    label: "Increase budget (%)",
    value: "increase_budget_percent",
  },
  {
    label: "Increase budget (value)",
    value: "increase_budget_value",
  },
  { label: "Set budget", value: "set_budget" },
  { label: "Add Labels", value: "add_labels" },
  { label: "Remove Labels", value: "remove_labels" },
  { label: "Clear and Replace Labels", value: "clear_and_replace_labels" },
  { label: "Pause", value: "paused" },
  { label: "Enable", value: "enabled" },
  { label: "Do nothing", value: "do_nothing" },
];
export const rulePortfolioDD = [
  {
    label: "Decrease budget caps(%)",
    value: "decrease_budget_caps_percent",
  },
  {
    label: "Decrease budget caps(value)",
    value: "decrease_budget_caps_value",
  },
  {
    label: "Increase budget caps(%)",
    value: "increase_budget_caps_percent",
  },
  {
    label: "Increase budget caps(value)",
    value: "increase_budget_caps_value",
  },
  { label: "Set budget caps", value: "set_budget_caps" },
  { label: "Add Labels", value: "add_labels" },
  { label: "Remove Labels", value: "remove_labels" },
  { label: "Clear and Replace Labels", value: "clear_and_replace_labels" },
  { label: "Do nothing", value: "do_nothing" },
];

// const RuleFilterListData = {
//   campaigns: [""],
//   portfolios: [""],
//   keywords: [""],
//   ad_groups: [""],
//   targets: [""],
// };
