// import { lazy } from "react";
// import RuleManagement from "../modules/pages/rule-management";
// export default [
//   {
//     path: "/rule-management",
//     component: <RuleManagement />,
//     exact: true,
//   },
// ];

import Dayparting from "../modules/pages/day-parting/day-parting";
import RuleBook from "../modules/pages/rule-book/rule-book";
import KeywordTargeting from "../modules/pages/keyword-management/keyword-targeting/keyword-targeting";

export default [
  {
    path: "/automation/rule-book",
    component: <RuleBook />,
    exact: true,
  },
  {
    path: "/automation/day-parting",
    component: <Dayparting />,
    exact: true,
  },
  {
    path: "/automation/keyword-harvesting",
    component: <KeywordTargeting />,
    exact: true,
  },
  // {
  //   path: "/keyword-management/keyword",
  //   component: <Keywords />,
  //   exact: true,
  // },
];
