// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/product-analytics",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-chart-pie-4 fs-1"></i>
      </span>
    ),
    label: GetLinks("/product-analytics", "Product Analytics"),
    admin: true,
  },
];
