import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../commonContext";
import { MakeApiCall } from "../../../apis/make-api-call";
import { DisplayNotification } from "../../../config";
import { Alert, Input, message } from "antd";

function SignUp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [password, set_password] = useState("");
  const [userID, setUserID] = useState("");
  const [isShowOTP, setIsShowOTP] = useState(false);
  const [verifyUserData, setVerifyUserData] = useState({});
  const [optValue, setOtpValue] = useState("");
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    contact_no: "",
  });

  useEffect(() => {
    if (localStorage.getItem("verify_user")) {
      const data = JSON.parse(localStorage.getItem("verify_user") || {});
      setVerifyUserData(data);
      setUserInfo({
        name: data?.name,
        email: data?.email,
        contact_no: data?.contact_no,
      });
      setUserID(data?.id);
    }
  }, []);

  const submit = async () => {
    setLoading(true);
    const response = await MakeApiCall("sign-up", "post", {
      ...userInfo,
      password: password,
    });
    console.log("response of sign up", response);
    if (response?.status) {
      setIsShowOTP(true);
      setUserID(response?.data?.user_data?.id);
      setVerifyUserData(response?.data?.user_data);
      setLoading(false);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const VerifyUserOTP = async () => {
    setLoading(true);
    const response = await MakeApiCall("check-verification-code", "post", {
      user_id: userID,
      verification_code: optValue,
    });
    console.log("response of sign up", response);
    if (response?.status) {
      localStorage.clear();
      DisplayNotification("success", "Success", response?.message);
      setLoading(false);
      navigate("/sign-in");
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  console.log("otp value are their", optValue);
  const onChange = (text) => {
    console.log("onChange:", text);
    setOtpValue(" " + text);
  };

  const sharedProps = {
    onChange,
  };

  if (localStorage.getItem("token")) {
    return (
      <Navigate
        to={
          localStorage.getItem("user_type") == 1 ? `/manage-user` : "/dashboard"
        }
      />
    );
  }

  return (
    <>
      <div
        className="d-flex flex-column flex-column-fluid flex-lg-row bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat"
        style={{
          height: "100%",
          backgroundImage: `url(/assets/media/login_bg_3.jpg)`,
          // backgroundSize: "auto",
          // backgroundRepeat: "repeat",
        }}
      >
        <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-40 mx-auto position-relative">
          <div className="b1" />
          <div className="b2" />

          <div
            className="d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px py-20 px-10 "
            style={{
              backgroundColor: "#a3a3a3a3",
              backdropFilter: "blur(11px)",
              border: "2px solid #6c6c6c",
            }}
          >
            <div className=" text-center">
              <img
                src="/assets/media/bidbison_logo.png"
                // className="h-40px"
                style={{ height: "150px" }}
                alt
              />
            </div>
            <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-10">
              <div className="form w-100">
                <div className="text-center mb-8">
                  <h1 className="tracking-in-expand-fwd  text-gray-100 fw-bold fs-2x mb-3">
                    Welcome in <span className="text-primary">Bid Bison</span>
                  </h1>

                  <div className="text-gray-200 fw-semibold fs-6">
                    Please enter your account details.
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12">
                    <Alert
                      message={
                        Object.entries(verifyUserData)?.length > 0
                          ? "Please check your email and verify your verification code"
                          : "Please check your email and verify your verification code  after completing your registration."
                      }
                      type="warning"
                      closable={false}
                      className="mb-5"
                    />
                  </div>
                </div>

                <div className="row g-9 mb-5 align-items-center">
                  <div className="col-md-6 fv-row my-7">
                    {/* <label className={`fs-6 fw-bold mb-2 text-white required`}>
                      First Name
                    </label> */}
                    <Input
                      disabled={Object.entries(verifyUserData)?.length > 0}
                      className="ant_common_input"
                      placeholder="Name"
                      size="large"
                      value={userInfo?.name}
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-6 fv-row my-7">
                    {/* <label className={`fs-6 fw-bold mb-2 text-white required`}>
                      Contact No
                    </label> */}
                    <Input
                      disabled={Object.entries(verifyUserData)?.length > 0}
                      className="ant_common_input"
                      placeholder="Contact No"
                      size="large"
                      value={userInfo?.contact_no}
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          contact_no: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row g-9 mb-5 align-items-center">
                  <div className="col-md-12 fv-row my-7">
                    {/* <label className={`fs-6 fw-bold mb-2 text-white required`}>
                      Email
                    </label> */}
                    <Input
                      type="text"
                      disabled={Object.entries(verifyUserData)?.length > 0}
                      className="ant_common_input"
                      placeholder="Email"
                      size="large"
                      value={userInfo?.email}
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          email: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {Object.entries(verifyUserData)?.length === 0 && (
                    <div className="col-md-12 fv-row mt-3 mb-7">
                      {/* <label className={`fs-6 fw-bold mb-2 text-white required`}>
                      Password
                    </label> */}
                      <Input.Password
                        className="ant_common_input"
                        disabled={isShowOTP === true ? "disabled" : null}
                        placeholder="Password"
                        size="large"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => {
                          set_password(e.target.value);
                        }}
                      />
                    </div>
                  )}

                  {Object.entries(verifyUserData)?.length > 0 && (
                    <>
                      {" "}
                      <div className="mt-10 d-grid">
                        <Input.OTP variant="filled" {...sharedProps} />
                      </div>
                      <div className="d-grid mt-10">
                        <button
                          // disabled={userInfo?.email || userInfo?.password}
                          className="btn btn-primary h-50px"
                          onClick={() => {
                            VerifyUserOTP();
                          }}
                        >
                          {loading ? (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                          ) : (
                            <span className="indicator-label">Verify Code</span>
                          )}
                        </button>
                      </div>
                    </>
                  )}

                  {Object.entries(verifyUserData)?.length === 0 && (
                    <>
                      <div className="d-grid mt-10">
                        <button
                          // disabled={userInfo?.email || userInfo?.password}
                          className="btn btn-primary h-50px"
                          onClick={() => {
                            submit();
                          }}
                        >
                          {loading ? (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                          ) : (
                            <span className="indicator-label">Sign Up</span>
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </div>

                {/* -------------------------------------------------------------------------- Old Code */}
              </div>
            </div>
            <div className="d-flex flex-stack px-lg-10 justify-content-center ">
              <div className="text-gray-200 text-center fw-semibold fs-6">
                Already have an account ?
                <Link to="/sign-in">
                  <span className="text-light ms-1">Sign In</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
