import { Input, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis/make-api-call";
import { GlobalContext } from "../../../../commonContext";
import EsfTable from "../../../../component/esf-table";
import Pagination from "../../../../component/esf-table/lib/pagination";
import NoDataTable from "../../../../component/no-data-table";
import {
  ConvertParams,
  DefaultPerPage,
  DisplayNotification,
  GetMarketplaceDropdown,
  GetMarketplaceList,
  NumberWithCommas,
} from "../../../../config";

const ProductAudienceTargeting = () => {
  const contextValue = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [list, set_list] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");

  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    search: "",
    marketplace_id: null,
  });

  useEffect(() => {
    localStorage.setItem("token_type", "");
    if (contextValue?.data?.user_type == 2) {
      if (localStorage.getItem("sub_user_list")) {
        GetMarketplace();
      } else {
        setLoading(false);
        DisplayNotification("error", "Error", "User not found");
      }
    } else {
      GetMarketplace();
    }
  }, [contextValue?.data?.selected_sub_user]);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_all_filters({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      setLoading(false);
      //   GetProductList({
      //     ...all_filters,
      //     marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      //   });
    } else {
      setLoading(false);
    }
  };

  const GetProductList = async (filters) => {
    setLoading(true);
    const response = await MakeApiCall(
      `report/product-list${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      set_list(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: e,
      "per-page": all_filters?.["per-page"],
    };
    // GetProductList(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: 1,
      "per-page": e,
    };
    // GetProductList(apiObj);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Target",
      dataIndex: "target",
      key: "target",
      width: 150,
    },
    {
      title: "Amazon Resource ID",
      dataIndex: "amazon_resource_id",
      key: "amazon_resource_id",
      width: 180,
    },
    {
      title: "Target State",
      dataIndex: "target_state",
      key: "target_state",
      width: 120,
    },
    {
      title: "Suggested Bid",
      dataIndex: "suggested_bid",
      key: "suggested_bid",
      width: 120,
    },
    {
      title: "Target Bid",
      dataIndex: "target_bid",
      key: "target_bid",
      width: 120,
    },
    {
      title: "Active Bid",
      dataIndex: "active_bid",
      key: "active_bid",
      width: 120,
    },
    {
      title: "Ad Group Name",
      dataIndex: "ad_group_name",
      key: "ad_group_name",
      width: 150,
    },
    // {
    //   title: "Advertising Account",
    //   dataIndex: "advertising_account",
    //   key: "advertising_account",
    //   width: 180,
    // },
    // {
    //   title: "Country",
    //   dataIndex: "country",
    //   key: "country",
    //   width: 120,
    // },
    // {
    //   title: "Region",
    //   dataIndex: "region",
    //   key: "region",
    //   width: 120,
    // },
    {
      title: "Campaign Name",
      dataIndex: "campaign_name",
      key: "campaign_name",
      width: 150,
    },
    {
      title: "Campaign Type",
      dataIndex: "campaign_type",
      key: "campaign_type",
      width: 150,
    },
    {
      title: "Campaign Targeting Type",
      dataIndex: "campaign_targeting_type",
      key: "campaign_targeting_type",
      width: 180,
    },
    {
      title: "Campaign State",
      dataIndex: "campaign_state",
      key: "campaign_state",
      width: 150,
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: 100,
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      width: 100,
    },
    {
      title: "ACoS",
      dataIndex: "acos",
      key: "acos",
      width: 100,
    },
    {
      title: "Sales (14 Day)",
      dataIndex: "sales_14_day",
      key: "sales_14_day",
      width: 150,
      render: (sales) => (
        <span className="fw-bold">
          {selected_currency_sign}
          {NumberWithCommas(sales || 0)}
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid" id="kt_content_container">
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0">
                <div className="d-flex flex-stack flex-wrap gap-4 p-2">
                  <div className="position-relative">
                    <Input
                      prefix={
                        <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                      }
                      placeholder="Search by ASIN or SKU"
                      size="large"
                      value={all_filters?.search}
                      onChange={(e) => {
                        set_all_filters({
                          ...all_filters,
                          search: e.target.value,
                        });
                      }}
                      onPressEnter={(e) => {
                        const apiObj = {
                          ...all_filters,
                          page: 1,
                          "per-page": DefaultPerPage,
                          search: e.target.value,
                        };
                        setLoading(true);
                        GetProductList(apiObj);
                      }}
                    />
                  </div>
                </div>
                <div className="card-toolbar">
                  <div class="d-flex flex-stack flex-wrap gap-4">
                    <div className="position-relative me-2">
                      <Select
                        className="w-200px"
                        size="large"
                        placeholder="Select Marketplace"
                        options={GetMarketplaceList(marketplace_list)}
                        value={selected_marketplace}
                        onChange={(e) => {
                          set_selected_marketplace(e);
                          set_selected_currency_sign(currency_list[e]);
                          const apiObj = {
                            ...all_filters,
                            page: 1,
                            "per-page": DefaultPerPage,
                            marketplace_id: e,
                          };
                          set_all_filters(apiObj);
                          setLoading(true);
                          GetProductList(apiObj);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body py-2">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  {loading ? (
                    <NoDataTable columns={columns} type="loading" />
                  ) : list?.length > 0 ? (
                    <EsfTable
                      columns={columns?.map((d) => ({
                        ...d,
                        // width: d?.title?.length * 13,
                      }))}
                      sticky={{
                        offsetHeader: "10px",
                      }}
                      scroll={{
                        x: "max-content",
                        y: "calc(100vh - 300px)",
                      }}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                    />
                  ) : (
                    <>
                      <NoDataTable columns={columns} type="no-data" />
                    </>
                  )}
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={all_filters?.["per-page"]}
                  page={all_filters?.page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </div>
    </>
  );
};

export default ProductAudienceTargeting;
