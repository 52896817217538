import {
  Checkbox,
  DatePicker,
  Input,
  Radio,
  Select,
  Skeleton,
  Transfer,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MakeApiCall } from "../../../../../apis/make-api-call";
import {
  DateFormat,
  DisplayNotification,
  PhotoUrl,
} from "../../../../../config";
import dayjs from "dayjs";

const { Option } = Select;
export default function ({ show, onHide, data, marketplace_id }) {
  const [sending, setSending] = useState(false);
  const [portfolioData, setPortfolioData] = useState({
    name: "",
    state: "", // ENABLED or PAUSED
    //"amount": "",
    start_date: dayjs()?.format("YYYY-MM-DD"),
    end_date: dayjs().add(30, "d")?.format("YYYY-MM-DD"),
    currency_code: "",
    policy: "",
  });

  useEffect(() => {
    if (data?.portfolioId) {
      setPortfolioData({
        ...portfolioData,
        name: data?.name,
        state: data?.state,
        start_date: dayjs(data?.budget?.startDate)?.format("YYYY-MM-DD"),
        end_date: dayjs(data?.budget?.endDate)?.format("YYYY-MM-DD"),
        policy: data?.budget?.policy,
        amount: data?.budget?.amount,
      });
    }
  }, [data]);

  const CreatePortfolio = async (body) => {
    message.destroy();
    message.loading("Loading", 0);
    const url = data?.portfolioId
      ? "/advertising/update-portfolio"
      : "advertising/create-portfolio";
    const response = await MakeApiCall(
      url,
      data?.portfolioId ? "put" : "post",
      body,
      true,
      false
    );
    if (response?.status) {
      setSending(false);
      message.destroy();
      DisplayNotification("success", "Success", response?.message);
      onHide();
    } else {
      message.destroy();
      DisplayNotification("error", "Error", response?.message);
      setSending(false);
    }
  };

  const setPortfolioDataValue = (key, value) => {
    const common_data = {
      ...portfolioData,
      [key]: value,
    };
    setPortfolioData(common_data);
  };

  console.log(`Setting`, portfolioData);
  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {data?.portfolioId ? "Update " : "Create "} Portfolio
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ minHeight: "300px" }}>
            <div>
              <div className="row g-9 mb-5 align-items-center">
                <div className="col-12 fv-row">
                  <label className="fs-6 fw-bold mb-2 required">
                    Portfolio Name
                  </label>
                  <Input
                    type="text"
                    className="ant_common_input form-control"
                    size="large"
                    value={portfolioData?.name}
                    onChange={(e) => {
                      setPortfolioDataValue("name", e.target.value);
                    }}
                  />
                </div>
                <div className="col-4 fv-row">
                  <label className="fs-6 fw-bold mb-2 required">State</label>
                  <Select
                    options={[
                      {
                        label: "Enabled",
                        value: "ENABLED",
                      },
                      {
                        label: "Paused",
                        value: "PAUSED",
                      },
                    ]}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    size="large"
                    className="w-100"
                    value={portfolioData?.state}
                    onChange={(e) => {
                      setPortfolioDataValue("state", e);
                    }}
                  />
                </div>
                <div className="col-4 fv-row">
                  <label className="fs-6 fw-bold mb-2 required">policy</label>
                  <Select
                    options={[
                      {
                        label: "Date Range",
                        value: "DATE_RANGE",
                      },
                      {
                        label: "Monthly Recurring",
                        value: "MONTHLY_RECURRING",
                      },
                      {
                        label: "No Cap",
                        value: "NO_CAP",
                      },
                    ]}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    size="large"
                    variant="filled"
                    className="w-100 "
                    value={portfolioData?.policy}
                    onChange={(e) => {
                      setPortfolioDataValue("policy", e);
                    }}
                  />
                </div>
                <div className="col-4 fv-row">
                  <label className="fs-6 fw-bold mb-2 required">Amount</label>
                  <Input
                    type="text"
                    className="ant_common_input form-control"
                    size="large"
                    value={portfolioData?.amount}
                    onChange={(e) => {
                      setPortfolioDataValue("amount", e.target.value);
                    }}
                  />
                </div>
                <div className="col-6">
                  <label className="fs-6 fw-bold mb-2 required">
                    Start Date
                  </label>
                  <DatePicker
                    format={DateFormat}
                    onChange={(date) => {
                      console.log(date, "date");
                      setPortfolioDataValue({
                        ...portfolioData,
                        start_date: date,
                      });
                    }}
                    style={{ width: "100%" }}
                    size="large"
                    className="ant_common_input form-control"
                    value={dayjs(portfolioData?.start_date)}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </div>
                <div className="col-6">
                  <label className="fs-6 fw-bold mb-2 required">End Date</label>
                  <DatePicker
                    format={DateFormat}
                    onChange={(date, dateString) => {
                      setPortfolioDataValue({
                        ...portfolioData,
                        end_date: date,
                      });
                    }}
                    style={{ width: "100%" }}
                    size="large"
                    className="ant_common_input form-control"
                    value={dayjs(portfolioData?.end_date)}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-light-danger fs-7"
            onClick={onHide}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary fs-7"
            onClick={() => {
              setSending(true);
              console.log(portfolioData, "-portfolioData");
              CreatePortfolio({
                ...portfolioData,
                marketplace_id: marketplace_id,
              });
            }}
            disabled={
              !portfolioData?.name ||
              !portfolioData?.state ||
              !portfolioData?.policy ||
              !portfolioData?.start_date ||
              !portfolioData?.end_date
            }
          >
            Submit
            {sending && (
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
