import { Input, Select, Switch, message } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { MakeApiCall } from "../../../apis/make-api-call";
import { GlobalContext } from "../../../commonContext";
import EsfTable from "../../../component/esf-table";
import Pagination from "../../../component/esf-table/lib/pagination";
import NoDataTable from "../../../component/no-data-table";
import {
  ConvertParams,
  DefaultPerPage,
  DisplayNotification,
  GetMarketplaceDropdown,
  GetMarketplaceList,
} from "../../../config";
import AddRuleBookModal from "./lib/add-rule";
import CampaignModal from "./lib/campaign-modal";
import DateWiseActionlogModal from "./lib/date-wise-actionlog-modal";
import ActionLogModal from "./lib/action-log-modal";
const RuleBook = () => {
  const contextValue = useContext(GlobalContext);

  const [dropdownList, setDropdownList] = useState({});
  const [dropdownLoading, setDropdownLoading] = useState(true);
  const [selectedCamp, setSelectedCamp] = useState([]);
  const [campaignView, setCampaignView] = useState(false);
  const [selectedActionLog, setSelectedActionLog] = useState({});
  const [actionLogView, setActionLogView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [ruleList, setRuleList] = useState([]);
  const [updatingRuleStatus, setUpdatingRuleStatus] = useState(false);
  const [isOpenRuleBookModal, setIsOpenRuleBookModal] = useState(false);
  const [rule_details, set_rule_details] = useState({});
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");
  const [portfolioList, setPortfolioList] = useState([]);
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    name: "",
    marketplace_id: null,
  });
  const [allCampaignList, setAllCampaignList] = useState([]);
  const [allCampaignListLoading, setAllCampaignListLoading] = useState(true);
  const [adGroupList, setAdGroupList] = useState([]);
  const [keywordList, setKeywordList] = useState([]);

  useEffect(() => {
    localStorage.setItem("token_type", "");
    if (contextValue?.data?.user_type == 2) {
      if (localStorage.getItem("sub_user_list")) {
        GetMarketplace();
        GetAllDropdownValue();
      } else {
        setLoading(false);
        DisplayNotification("error", "Error", "User not found");
      }
    } else {
      GetMarketplace();
      GetAllDropdownValue();
    }
  }, [contextValue?.data?.selected_sub_user]);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_all_filters({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      GetCampaignList(marketplace?.marketplace_list[0]?.marketplace_id);
      GetRuleManagementList({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      GetPortfolioList(marketplace?.marketplace_list[0]?.marketplace_id);
    } else {
      setLoading(false);
    }
  };

  const GetPortfolioList = async (marketplace_id) => {
    const response = await MakeApiCall(
      `advertising/all-portfolios?marketplace_id=${marketplace_id}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setPortfolioList(response?.data);
    } else {
      setPortfolioList([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetRuleManagementList = async (filters) => {
    const response = await MakeApiCall(
      `rule-management/get-rules${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      setRuleList(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetAllDropdownValue = async () => {
    setLoading(true);
    const response = await MakeApiCall(
      `rule-management/get-create-rule-dd-values`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setDropdownList(response?.data);
      setDropdownLoading(false);
    } else {
      // setDropdownList({});
      setDropdownList({});
      setDropdownLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetCampaignList = async (marketplace_id) => {
    setLoading(true);
    const response = await MakeApiCall(
      `advertising/get-all-campaigns?state=ENABLED&campaign_type=SPONSORED_PRODUCTS&marketplace_id=${marketplace_id}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setAllCampaignList(response?.data || []);
      setAllCampaignListLoading(false);

      const newArray = response?.data?.map((d) => {
        return {
          campaign_type: d?.campaign_type,
          campaign_id: d?.campaign_id,
        };
      });
      GetAdGroupList(newArray, marketplace_id);
      GetAllKeywordList(newArray);
    } else {
      setAllCampaignList([]);
      setAllCampaignListLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const DeleteRuleAction = async (ruleId) => {
    const response = await MakeApiCall(
      `rule-management/master-rule/${ruleId}`,
      `DELETE`,
      null,
      true
    );
    if (response?.status) {
      setLoading(true);
      message.destroy();
      GetRuleManagementList(all_filters);
      DisplayNotification("success", "Success", response?.message);
    } else {
      setLoading(true);
      message.destroy();
      GetRuleManagementList(all_filters);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const UpdateRuleStatusAction = async (ruleId, data) => {
    const response = await MakeApiCall(
      `rule-management/update-status-rule/${ruleId}`,
      `put`,
      data,
      true
    );
    if (response?.status) {
      setLoading(true);
      setUpdatingRuleStatus(false);
      message.destroy();
      GetRuleManagementList(all_filters);
      DisplayNotification("success", "Success", response?.message);
    } else {
      setLoading(true);
      setUpdatingRuleStatus(false);
      message.destroy();
      GetRuleManagementList(all_filters);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetRuleDetailsData = async (id) => {
    const response = await MakeApiCall(
      `rule-management/get-rule/${id}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      message.destroy();
      set_rule_details(response?.data);
      setIsOpenRuleBookModal(true);
    } else {
      message.destroy();
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const columns = [
    {
      title: "#",
      width: 80,
      fixed: "left",
      render: (_, __, i) => {
        return (
          <span>
            {(all_filters?.page - 1) * all_filters?.["per-page"] + 1 + i}
          </span>
        );
      },
    },

    {
      title: "Rule Name",
      width: 200,
      render: (text) => {
        return (
          <span className=" fw-bolder text-gray-900">{text?.name || "-"}</span>
        );
      },
    },

    // {
    //   title: "Rule Type",
    //   width: 100,
    //   render: (text) => (
    //     <div className="d-flex align-items-center">
    //       <Tag
    //         color={`${
    //           text?.rule_type === "bid"
    //             ? "blue"
    //             : text?.rule_type === "budget"
    //             ? "magenta"
    //             : "cyan"
    //         }`}
    //         style={{ textTransform: "capitalize" }}
    //       >
    //         {text?.rule_type?.split("_")?.join(" ") || "-"}
    //       </Tag>
    //     </div>
    //   ),
    // },

    {
      title: "Campaigns",
      width: 300,
      render: (text) => {
        // if (text?.rule_type === "budget") {
        //   return (
        //     <div style={{ display: "flex", alignItems: "center" }}>
        //       <div className="two-line">
        //         {Object.values(text?.campaign_list?.[0]) || "-"}
        //       </div>

        //       <span
        //         className="ms-2 "
        //         onClick={() => {
        //           setSelectedCamp(text?.campaign_list || []);
        //           setCampaignView(true);
        //         }}
        //       >
        //         <i className="ki-outline ki-eye fs-3 text-dark" />
        //       </span>
        //     </div>
        //   );
        // }

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="two-line">
              {JSON.parse(text?.campaign_list || [])?.[0]?.campaign_name || "-"}
            </div>

            <span
              className="ms-2 cursor-pointer"
              onClick={() => {
                setSelectedCamp(JSON.parse(text?.campaign_list || []));
                setCampaignView(true);
              }}
            >
              <i className="ki-outline ki-eye fs-3 text-gray-700" />
            </span>
          </div>
        );
      },
    },

    {
      title: "Rule Status",
      width: 120,
      render: (text) => {
        return (
          <Switch
            disabled={updatingRuleStatus}
            checked={text?.rule_status === "ACTIVE" ? true : false}
            onChange={(e) => {
              console.log(e);
              setUpdatingRuleStatus(true);
              UpdateRuleStatusAction(text?.id, {
                rule_status:
                  text?.rule_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
              });
              setRuleList((prev) => {
                return prev.map((item) => {
                  if (item?.id === text?.id) {
                    return {
                      ...item,
                      rule_status:
                        text?.rule_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                    };
                  }
                  return item;
                });
              });
            }}
          />
        );
      },
    },
    // {
    //   title: "Status",

    //   render: (text) => {
    //     if (
    //       text?.rule_type === "Search Term Management" ||
    //       text?.rule_type === "Bid Management" ||
    //       text?.rule_type === "bid" ||
    //       text?.rule_type === "search_term"
    //     ) {
    //       return;
    //     }

    //     return (
    //       <>
    //         <label
    //           onChange={() => {
    //             message.destroy();

    //             message.loading("Updating...", 0);

    //             UpdateRuleStatusAction(text?.id, {
    //               rule_status:
    //                 text?.rule_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
    //             });

    //             setRuleList((prev) => {
    //               return prev.map((item) => {
    //                 if (item.id === text?.id) {
    //                   return {
    //                     ...item,

    //                     rule_status:
    //                       text?.rule_status === "ACTIVE"
    //                         ? "INACTIVE"
    //                         : "ACTIVE",
    //                   };
    //                 }

    //                 return item;
    //               });
    //             });
    //           }}
    //         >
    //           <input
    //             defaultValue="on"
    //             name="dummy"
    //             type="checkbox"
    //             className="bubble"
    //             checked={text?.rule_status === "ACTIVE"}
    //           />
    //         </label>
    //       </>
    //     );
    //   },
    // },

    // {
    //   title: "Scheduled",
    //   width: 150,
    //   render: (text) => {
    //     if (!text?.next_execution_time) {
    //       return "-";
    //     }

    //     return (
    //       <span>
    //         {dayjs(parseInt(text?.next_execution_time) * 1000).format(
    //           "MMM DD, YYYY"
    //         )}
    //       </span>
    //     );
    //   },
    // },

    {
      title: "Action Log",
      width: 150,
      render: (text) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedActionLog({
              ...text,
              type_action: "master",
            });
            setActionLogView(true);
          }}
        >
          <i className="fas fa-history text-primary fs-3" />
        </span>
      ),
    },
    {
      title: "Created at",
      width: 150,
      render: (text) => {
        if (!text?.created_at) {
          return "-";
        }

        return (
          <span>
            {dayjs(parseInt(text?.created_at) * 1000).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      title: "Updated at",
      width: 150,
      render: (text) => {
        if (!text?.updated_at) {
          return "-";
        }

        return (
          <span>
            {dayjs(parseInt(text?.updated_at) * 1000).format("MMM DD, YYYY")}
          </span>
        );
      },
    },
    {
      title: "Action",
      width: 150,
      render: (text) => (
        <>
          <span
            className="btn btn-icon bg-light btn-active-color-success btn-sm me-2"
            onClick={() => {
              message.destroy();
              message.loading("Loading...", 0);
              GetRuleDetailsData(text?.id);
            }}
          >
            <i className="ki-outline ki-pencil fs-2 text-success" />
          </span>{" "}
        </>
      ),
    },
  ];

  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: e,
      "per-page": all_filters?.["per-page"],
    };
    GetRuleManagementList(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: 1,
      "per-page": e,
    };
    GetRuleManagementList(apiObj);
  };

  const GetAdGroupList = async (campaign_list, marketplace_id) => {
    const data = {
      marketplace_id: marketplace_id,
      listing_type: "manual_campaign", // all_campaign, manual_campaign
      campaign_list: campaign_list,
    };
    const response = await MakeApiCall(
      `advertising/get-ad-group-list`,
      `post`,
      data,
      true
    );
    if (response?.status) {
      const data = response?.data?.map((d) => {
        const newObj = d?.ad_group_list?.map((al) => {
          return {
            ...al,
            campaign_name: d?.campaign_name,
            campaign_type: d?.campaign_type,
          };
        });
        return newObj;
      });
      const adGroupList = data?.flat();
      setAdGroupList(adGroupList || []);
    } else {
      // DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetAllKeywordList = async (campaignIds) => {
    const cId = campaignIds?.map((d) => d?.campaign_id);
    const response = await MakeApiCall(
      `day-parting/get-keyword-list?type=ad_group&campaign_ids=${JSON.stringify(
        cId
      )}&campaign_type=SPONSORED_PRODUCTS&keyword_type=["BROAD","PHRASE","EXACT"]`,
      `GET`,
      null,
      true
    );
    if (response?.status) {
      // const data = response?.data?.map((d) => ({
      //   ...d,
      //   campaign_type: "SPONSORED_PRODUCTS",
      // }));
      setKeywordList(response?.data);
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };
  return (
    <>
      <div className="container-fluid" id="kt_content_container">
        <div className="row gy-5 g-xl-5">
          <div className="col-xxl-12">
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              <div className="card-header border-0">
                <div className="d-flex flex-stack flex-wrap gap-4">
                  <div className="position-relative mr-4">
                    <Input
                      style={{ width: "250px" }}
                      prefix={
                        <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                      }
                      placeholder="Search by Rule name"
                      size="large"
                      value={all_filters?.name}
                      onChange={(e) => {
                        set_all_filters({
                          ...all_filters,
                          name: e.target.value,
                        });
                      }}
                      onPressEnter={(e) => {
                        const apiObj = {
                          ...all_filters,
                          page: 1,
                          "per-page": DefaultPerPage,
                          name: e.target.value,
                        };
                        setLoading(true);
                        GetRuleManagementList(apiObj);
                      }}
                    />
                  </div>
                </div>
                <div className="card-toolbar">
                  <div className="position-relative me-2">
                    <Select
                      className="w-200px"
                      size="large"
                      placeholder="Select Marketplace"
                      options={GetMarketplaceList(marketplace_list)}
                      value={selected_marketplace}
                      onChange={(e) => {
                        set_selected_marketplace(e);
                        set_selected_currency_sign(currency_list[e]);
                        const apiObj = {
                          ...all_filters,
                          page: 1,
                          "per-page": DefaultPerPage,
                          marketplace_id: e,
                        };
                        set_all_filters(apiObj);
                        setLoading(true);
                        GetRuleManagementList(apiObj);
                      }}
                    />
                  </div>
                  <button
                    className="btn  fs-7 btn-primary fw-bold"
                    onClick={() => {
                      setIsOpenRuleBookModal(true);
                    }}
                  >
                    Add Rule Book
                  </button>
                </div>
              </div>

              <div className="card-body py-2 main-box">
                <div className="table-responsive">
                  {loading ? (
                    <NoDataTable columns={columns} type="loading" />
                  ) : ruleList?.length > 0 ? (
                    <EsfTable
                      columns={columns}
                      sticky={{
                        offsetHeader: "10px",
                      }}
                      scroll={{
                        x: "max-content",
                        y: "calc(100vh - 270px)",
                      }}
                      fixed={true}
                      dataSource={ruleList}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                    />
                  ) : (
                    <>
                      <NoDataTable columns={columns} type="no-data" />
                    </>
                  )}
                </div>
                <Pagination
                  loading={loading || ruleList?.length === 0}
                  pageSize={all_filters?.["per-page"]}
                  page={all_filters?.page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
            </div>
          </div>
        </div>

        {campaignView && (
          <CampaignModal
            selectedCamp={selectedCamp}
            show={campaignView}
            onHide={() => setCampaignView(false)}
          />
        )}
        {/* 
        {actionLogView && (
          <ActionLogModal
            show={actionLogView}
            selectedActionLog={selectedActionLog}
            onHide={() => {
              setActionLogView(false);
              setSelectedActionLog({});
            }}
            selectedMarketplace={selected_marketplace}
          />
        )} */}

        {actionLogView && (
          <DateWiseActionlogModal
            show={actionLogView}
            selectedActionLog={selectedActionLog}
            onHide={() => {
              setActionLogView(false);
              setSelectedActionLog({});
            }}
          />
        )}

        {isOpenRuleBookModal && (
          <AddRuleBookModal
            show={isOpenRuleBookModal}
            onHide={() => {
              set_rule_details({});
              setIsOpenRuleBookModal(false);
            }}
            campaign_list={allCampaignList}
            adGroupList={adGroupList}
            keywordList={keywordList}
            dropdownList={dropdownList}
            currencySign={selected_currency_sign}
            selectedMarketplace={selected_marketplace}
            rule_details={rule_details}
            portfolioList={portfolioList}
            onSuccess={() => {
              setLoading(true);
              set_rule_details({});
              setIsOpenRuleBookModal(false);
              GetRuleManagementList(all_filters);
            }}
          />
        )}
      </div>
    </>
  );
};

export default RuleBook;
