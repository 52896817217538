import { Radio } from "antd";
import React, { useState } from "react";
import CampaignList from "./campaign-list/campaign-list";
import CampaignCreation from "./campaign-creation/campaign-creation";
import Campaign from "./campaign";
import CampaignDetails from "./campaign-details";

export default function (props) {
  const [mode, setMode] = useState("campaign-list");
  const [selctedCampaignData, setSelctedCampaignData] = useState({});

  // const handleModeChange = (e) => {
  //   setMode(e.target.value);
  // };

  const [isVisible, setIsVisible] = useState("campaign");

  return isVisible === "campaign" ? (
    <Campaign
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      setSelctedCampaignData={setSelctedCampaignData}
    />
  ) : (
    <CampaignDetails
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      selctedCampaignData={selctedCampaignData}
    />
  );
}
