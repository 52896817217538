import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../commonContext";
import dayjs from "dayjs";
import {
  ConvertParams,
  DateFormat,
  DefaultPerPage,
  DisplayNotification,
  GetMarketplaceDropdown,
  GetMarketplaceList,
  NumberWithCommas,
} from "../../../config";
import { MakeApiCall } from "../../../apis/make-api-call";
import RangePickerESF from "../../../component/range-picker";
import { Button, Input, Popover, Select, Tag } from "antd";
import NoDataTable from "../../../component/no-data-table";
import EsfTable from "../../../component/esf-table";
import Pagination from "../../../component/esf-table/lib/pagination";
import CampaignModal from "./lib/campaign-modal";
import { metrics_filter } from "../../../config";
const ProductAnalytics = (props) => {
  const { selectedData, setSelectedData, isVisible, setIsVisible } = props;
  const contextValue = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [list, set_list] = useState([]);
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [selectedCamp, setSelectedCamp] = useState([]);
  const [campaignView, setCampaignView] = useState(false);
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    marketplace_id: null,
    start_date: dayjs().add(-16, "d"),
    end_date: dayjs().add(-10, "d"),
  });

  // Metrics Filter Start
  const [isOpenMetricsPopover, setIsOpenMetricsPopover] = useState(false);
  const [metrics_filter_obj, set_metrics_filter_obj] = useState([]);

  // const [metrics_filter, set_metrics_filter] = useState({
  //   attributes_dd_data: {
  //     acos: "ACOS",
  //     roas: "ROAS",
  //     spends: "Spends",
  //     sales: "Total Sales",
  //     cpc: "CPC",
  //     cvr: "Conversion Rate",
  //     unit_ordered: "Orders",
  //     clicks: "Clicks",
  //     impressions: "Impressions",
  //     ctr: "CTR",
  //     ad_sales_percentage: "Ad Sales Percentage",
  //     total_sales: "Total Sales",
  //     total_orders: "Total Orders",
  //     total_acos: "Total ACOS",
  //   },
  //   operators_dd_data: {
  //     ">=": ">=",
  //     "<=": "<=",
  //     ">": ">",
  //     "<": "<",
  //     "==": "=",
  //   },
  // });
  // Metrics Filter Start

  const [sortFilters, setSortFilters] = useState("");
  const [sortOrderColumns, setSortOrderColumns] = useState("");

  useEffect(() => {
    localStorage.setItem("token_type", "");
    if (contextValue?.data?.user_type == 2) {
      if (localStorage.getItem("sub_user_list")) {
        GetMarketplace();
      } else {
        setLoading(false);
        DisplayNotification("error", "Error", "User not found");
      }
    } else {
      GetMarketplace();
    }
  }, [contextValue?.data?.selected_sub_user]);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_all_filters({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      GetProductsList({
        ...all_filters,
        start_date: dayjs(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: dayjs(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
        metric_filter:
          metrics_filter_obj?.length > 0
            ? JSON.stringify(metrics_filter_obj)
            : [],
        order_key: sortOrderColumns,
        order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
      });
    } else {
      setLoading(false);
    }
  };

  const GetProductsList = async (data) => {
    setLoading(true);
    const response = await MakeApiCall(
      `product-analytics/get-product-list${ConvertParams(data)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      set_list(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      // DisplayNotification("error", "Error", response?.message);
    }
  };
  console.log(list);

  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: e,
      "per-page": all_filters?.["per-page"],
      start_date: dayjs(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: dayjs(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
      metric_filter:
        metrics_filter_obj?.length > 0
          ? JSON.stringify(metrics_filter_obj)
          : [],
      order_key: sortOrderColumns,
      order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
    };
    GetProductsList(apiObj);
  };
  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: 1,
      "per-page": e,
      start_date: dayjs(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: dayjs(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
      metric_filter:
        metrics_filter_obj?.length > 0
          ? JSON.stringify(metrics_filter_obj)
          : [],
      order_key: sortOrderColumns,
      order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
    };
    GetProductsList(apiObj);
  };

  const columns = [
    {
      title: "#",
      width: 20,
      fixed: "left",
      render: (text) => (
        <span>
          {(all_filters?.page - 1) * all_filters?.["per-page"] + 1 + text?.key}
        </span>
      ),
    },

    {
      title: "Product",
      dataIndex: "item_name",
      key: "item_name",
      fixed: "left",
      sortOn: true,
      render: (text, record) => (
        <div className="d-flex align-items-center border border-dashed border-gray-300 p-3 rounded bdr-left-user1">
          <div className="symbol symbol-60px overflow-hidden me-3 shadow-sm">
            <img src={record?.image_url} />
          </div>
          <div className="d-flex flex-column">
            <Popover
              content={
                <div
                  style={{
                    height: "max-content",
                    maxWidth: "400px",
                  }}
                >
                  {record?.item_name}
                </div>
              }
              placement="bottom"
            >
              <span
                className="text-gray-900 mb-1 fw-bolder popoverActionIconSingleLine fs-6"
                style={{ width: "300px" }}
              >
                {record?.item_name}
              </span>
            </Popover>

            <div>
              <span
                className="fs-7 cursor-pointer"
                // onClick={() => {
                //   window.open(`https://www.amazon.com/dp/${record?.asin1}`);
                // }}
              >
                <b>ASIN:</b>{" "}
                <span className="text-primary">{record?.asin1}</span>
              </span>{" "}
              |
              <span className="fs-7">
                <b>SKU:</b>{" "}
                <span className="text-primary">{record?.seller_sku}</span>
              </span>
            </div>
          </div>
        </div>
      ),
      width: 300,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          className="fw-bold"
          color={
            status === "Active"
              ? "#4e8935"
              : status === "Inactive"
              ? "#f00"
              : "#f50"
          }
        >
          {status}
        </Tag>
      ),
      width: 125,
    },
    {
      title: "Product Ad State",
      width: 180,
      render: (record) =>
        record?.campaign_name?.length > 0 ? (
          <Tag className="fw-bold" color={"#4e8935"}>
            Advertised
          </Tag>
        ) : (
          <Tag className="fw-bold" color={"#574e21"}>
            Non Advertised
          </Tag>
        ),
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      width: 180,
    },
    // {
    //   title: "Fulfillment Channel",
    //   dataIndex: "fulfillment_channel",
    //   key: "fulfillment_channel",
    //   width: 180,
    //   render: (text) => {
    //     return (
    //       <Tag
    //         className="fw-bold"
    //         color={text === "DEFAULT" ? "#f50" : "#531dab"}
    //       >
    //         {text === "DEFAULT" ? "FBM" : "FBA"}
    //       </Tag>
    //     );
    //   },
    // },
    // {
    //   title: "Product Id",
    //   dataIndex: "product_id",
    //   key: "product_id",
    //   width: 120,
    // },
    {
      title: "Sales Price",
      dataIndex: "price",
      key: "price",
      sortOn: true,
      width: 130,
      render: (price) => (
        <div>
          <span className="fw-bold">
            {selected_currency_sign}
            {NumberWithCommas(price)}
          </span>
        </div>
      ),
    },
    {
      title: "Stock Quantity",
      dataIndex: "stock_quantity",
      key: "stock_quantity",
      width: 150,
      sortOn: true,
      render: (text) => (
        <div>
          <span className="fw-bold">{text}</span>
        </div>
      ),
    },
    {
      title: "Sales Rank",
      dataIndex: "sales_rank",
      key: "sales_rank",
      width: 130,
      sortOn: true,
      //   sorter: (a, b) => a.salesRank - b.salesRank, // Optional: sorting by sales rank
    },
    // {
    //   title: "Organic Sales",
    //   dataIndex: "organicSales",
    //   key: "organicSales",
    //   width: 120,
    //   //   render: (text) => `$${text.toLocaleString()}`, // Format as currency
    // },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      width: 120,
      sortOn: true,
      render: (data) => (
        <div>
          <span className="">
            {selected_currency_sign}
            {NumberWithCommas(data)}
          </span>
        </div>
      ),
      //   render: (text) => `$${text.toLocaleString()}`,
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: 120,
      sortOn: true,
    },
    {
      title: "Impression",
      dataIndex: "impressions",
      key: "impressions",
      width: 120,
      sortOn: true,
    },
    {
      title: "Ads Unit Sold",
      dataIndex: "ads_unit_sold",
      key: "ads_unit_sold",
      width: 120,
      sortOn: true,
    },
    {
      title: "Ads sales ",
      dataIndex: "ads_sales",
      key: "ads_sales",
      width: 150,
      sortOn: true,
      render: (data) => (
        <div>
          <span className="">
            {selected_currency_sign}
            {NumberWithCommas(data)}
          </span>
        </div>
      ),
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      width: 120,
      sortOn: true,
      render: (text) => {
        return <span> {text || "0"}</span>;
      },
      //   render: (text) => `$${text.toFixed(2)}`, // Format CPC to two decimal places
    },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      width: 120,
      sortOn: true,
      render: (text) => {
        return <span>{text || "0"}%</span>;
      },
      //   render: (text) => `${text}%`, // Format as percentage
    },
    {
      title: "ACoS",
      dataIndex: "ACoS",
      key: "ACoS",
      sortOn: true,
      width: 120,
      render: (data) => (
        <div>
          <span className="">{NumberWithCommas(data)}%</span>
        </div>
      ),
      //   render: (text) => `$${text.toLocaleString()}`,
    },
    {
      title: "Unit Sold",
      dataIndex: "units_sold",
      key: "units_sold",
      width: 120,
      sortOn: true,
      render: (data) => (
        <div>
          <span className="">{NumberWithCommas(data)}</span>
        </div>
      ),
    },
    {
      title: "Organic Sales",
      dataIndex: "organic_sales",
      key: "organic_sales",
      width: 150,
      sortOn: true,
      render: (data) => (
        <div>
          <span className="">
            {selected_currency_sign}
            {NumberWithCommas(data)}
          </span>
        </div>
      ),
    },
    {
      title: "TACoS",
      dataIndex: "TACoS",
      key: "TACoS",
      width: 120,
      sortOn: true,
      render: (data) => (
        <div>
          <span className="">{NumberWithCommas(data)}%</span>
        </div>
      ),
    },
    // {
    //   title: "Ratings",
    //   dataIndex: "ratings",
    //   key: "ratings",
    // },
    // {
    //   title: "Reviews",
    //   dataIndex: "reviews",
    //   key: "reviews",
    // },

    {
      title: "Campaigns",
      width: 300,
      render: (text) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="two-line">
              {text?.campaign_name?.[0]?.campaign_name || "-"}
            </div>
            {text?.campaign_name?.length > 0 && (
              <div>
                {" "}
                <span
                  className="ms-2 cursor-pointer d-flex"
                  onClick={() => {
                    setSelectedCamp(text?.campaign_name || []);
                    setCampaignView(true);
                  }}
                >
                  <i className="ki-outline ki-eye fs-3 text-dark" />
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    // {
    //   title: "Marketplace Id",
    //   dataIndex: "marketplace_id",
    //   key: "marketplace_id",
    //   width: 120,
    // },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
      width: 120,
    },

    // {
    //   title: "Description",
    //   dataIndex: "item_description",
    //   key: "item_description",
    //   width: 150,
    //   render: (text) => {
    //     return !text ? (
    //       "-"
    //     ) : (
    //       <Popover
    //         content={
    //           <div
    //             style={{
    //               height: "max-content",
    //               maxWidth: "600px",
    //             }}
    //           >
    //             {text}
    //           </div>
    //         }
    //         placement="bottom"
    //       >
    //         <span
    //           className="text-dark mb-1 fw-bolder popoverActionIconSingleLine fs-6"
    //           style={{ width: "150px" }}
    //         >
    //           {text || "-"}
    //         </span>
    //       </Popover>
    //     );
    //   },
    // },
    // {
    //   title: "Created at",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   render: (created_at) => (
    //     <span>
    //       {moment(new Date(created_at * 1000)).format("MMM DD, YYYY hh:mm A")}
    //       <br />
    //       <small className="text-gray-500">
    //         ({moment(created_at * 1000).fromNow()})
    //       </small>
    //     </span>
    //   ),
    //   width: 200,
    // },
    // {
    //   title: "Updated At",
    //   dataIndex: "updated_at",
    //   render: (updated_at) => (
    //     <span>
    //       {moment(new Date(updated_at * 1000)).format("MMM DD, YYYY hh:mm A")}
    //       <br />
    //       <small className="text-gray-500">
    //         ({moment(updated_at * 1000).fromNow()})
    //       </small>
    //     </span>
    //   ),
    //   width: 200,
    // },
  ];

  return (
    <>
      <div className="container-fluid" id="kt_content_container">
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0">
                <div className="d-flex flex-stack flex-wrap gap-4 p-2">
                  <div className="position-relative">
                    <Input
                      prefix={
                        <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                      }
                      placeholder="Search by ASIN or SKU"
                      className="form-control d-flex"
                      variant="filled"
                      size="large"
                      value={all_filters?.search}
                      onChange={(e) => {
                        set_all_filters({
                          ...all_filters,
                          search: e.target.value,
                        });
                      }}
                      onPressEnter={(e) => {
                        const apiObj = {
                          ...all_filters,
                          page: 1,
                          "per-page": DefaultPerPage,
                          search: e.target.value,
                          start_date: dayjs(all_filters?.start_date?.$d).format(
                            "YYYY-MM-DD"
                          ),
                          end_date: dayjs(all_filters?.end_date?.$d).format(
                            "YYYY-MM-DD"
                          ),
                        };
                        setLoading(true);
                        GetProductsList(apiObj);
                      }}
                    />
                  </div>
                </div>
                <div className="card-toolbar">
                  <div class="d-flex flex-stack flex-wrap gap-4">
                    <div className="position-relative ">
                      <RangePickerESF
                        className="ant_common_input form-control d-flex"
                        id="aalacart_date_picker"
                        size="large"
                        allowClear={false}
                        style={{ width: "250px" }}
                        format={DateFormat}
                        value={[all_filters?.start_date, all_filters?.end_date]}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onChange={(e) => {
                          const filters_ = all_filters;
                          filters_.start_date = e?.[0];
                          filters_.end_date = e?.[1];
                          set_all_filters({
                            ...filters_,
                            page: 1,
                            "per-page": DefaultPerPage,
                          });
                          const apiObj = {
                            ...all_filters,
                            page: 1,
                            "per-page": DefaultPerPage,
                            start_date: dayjs(e?.[0]?.$d).format("YYYY-MM-DD"),
                            end_date: dayjs(e?.[1]?.$d).format("YYYY-MM-DD"),
                            metric_filter:
                              metrics_filter_obj?.length > 0
                                ? JSON.stringify(metrics_filter_obj)
                                : [],
                            order_key: sortOrderColumns,
                            order_value: sortFilters?.startsWith("-")
                              ? "SORT_ASC"
                              : "SORT_DESC",
                          };
                          setLoading(true);
                          GetProductsList(apiObj);
                        }}
                      />
                    </div>
                    <div className="position-relative me-2">
                      <Select
                        className="w-200px"
                        size="large"
                        placeholder="Select Marketplace"
                        options={GetMarketplaceList(marketplace_list)}
                        value={selected_marketplace}
                        onChange={(e) => {
                          set_selected_marketplace(e);
                          set_selected_currency_sign(currency_list[e]);
                          set_all_filters({
                            ...all_filters,
                            page: 1,
                            "per-page": DefaultPerPage,
                            marketplace_id: e,
                          });
                          const apiObj = {
                            page: 1,
                            "per-page": DefaultPerPage,
                            marketplace_id: e,
                            start_date: dayjs(
                              all_filters?.start_date?.$d
                            ).format("YYYY-MM-DD"),
                            end_date: dayjs(all_filters?.end_date?.$d).format(
                              "YYYY-MM-DD"
                            ),
                            metric_filter:
                              metrics_filter_obj?.length > 0
                                ? JSON.stringify(metrics_filter_obj)
                                : [],
                            order_key: sortOrderColumns,
                            order_value: sortFilters?.startsWith("-")
                              ? "SORT_ASC"
                              : "SORT_DESC",
                          };
                          setLoading(true);
                          GetProductsList(apiObj);
                        }}
                      />
                    </div>
                    <div className="position-relative me-2">
                      <Popover
                        placement="bottom"
                        trigger="click"
                        open={isOpenMetricsPopover}
                        content={
                          <div className="">
                            {metrics_filter_obj?.map((d, index) => {
                              return (
                                <div className="mt-3 d-flex">
                                  <Select
                                    placeholder="Select Metric"
                                    allowClear
                                    options={Object.entries(
                                      metrics_filter?.attributes_dd_data
                                    )?.map((d) => {
                                      return {
                                        label: d[1],
                                        value: d[0],
                                      };
                                    })}
                                    value={d?.attribute}
                                    onChange={(e) => {
                                      set_metrics_filter_obj((prev) => {
                                        return prev?.map((d, mindex) => {
                                          if (mindex === index) {
                                            return {
                                              ...d,
                                              attribute: e,
                                            };
                                          }
                                          return d;
                                        });
                                      });
                                    }}
                                    style={{ width: "200px" }}
                                  />
                                  <Select
                                    className="ms-1"
                                    allowClear
                                    options={Object.entries(
                                      metrics_filter?.operators_dd_data
                                    )?.map((d) => {
                                      return {
                                        label: d[1],
                                        value: d[0],
                                      };
                                    })}
                                    value={d?.operator}
                                    onChange={(e) => {
                                      set_metrics_filter_obj((prev) => {
                                        return prev?.map((d, mindex) => {
                                          if (mindex === index) {
                                            return {
                                              ...d,
                                              operator: e,
                                            };
                                          }
                                          return d;
                                        });
                                      });
                                    }}
                                    style={{ width: "100px" }}
                                    placeholder="Select Condition"
                                  />
                                  <Input
                                    style={{ width: "120px" }}
                                    className="ms-1"
                                    value={d?.value}
                                    onChange={(e) => {
                                      set_metrics_filter_obj((prev) => {
                                        return prev?.map((d, mindex) => {
                                          if (mindex === index) {
                                            return {
                                              ...d,
                                              value: e.target.value,
                                            };
                                          }
                                          return d;
                                        });
                                      });
                                    }}
                                    placeholder="Enter Value"
                                  />
                                  {metrics_filter_obj?.length > 1 && (
                                    <span
                                      className="btn btn-icon btn-light-primary btn-outline w-30px h-30px position-relative border-0 cursor-pointer ms-1"
                                      onClick={() => {
                                        const deletedData =
                                          metrics_filter_obj?.filter(
                                            (d, i) => i !== index
                                          );
                                        set_metrics_filter_obj(deletedData);
                                      }}
                                    >
                                      <i className="ki-outline ki-cross fs-2" />
                                    </span>
                                  )}
                                </div>
                              );
                            })}
                            <div className="text-center">
                              <button
                                className="btn btn-light-primary btn-sm mt-5"
                                onClick={() => {
                                  set_metrics_filter_obj([
                                    ...metrics_filter_obj,
                                    {
                                      attribute: null,
                                      operator: null,
                                      value: "",
                                    },
                                  ]);
                                }}
                              >
                                <i className="ki-outline ki-plus fs-2" />
                                Add another
                              </button>
                            </div>

                            <div className="separator separator-dashed my-2"></div>
                            <div className="d-flex justify-content-between">
                              <button
                                className="btn btn-light-danger btn-sm"
                                onClick={() => {
                                  set_metrics_filter_obj([]);
                                  setIsOpenMetricsPopover(false);
                                }}
                              >
                                {/* <i className="ki-outline ki-cross fs-2" /> */}
                                Clear all
                              </button>
                              <button
                                disabled={
                                  metrics_filter_obj.filter(
                                    (item) =>
                                      !item.attribute ||
                                      !item.operator ||
                                      !item.value
                                  )?.length > 0
                                }
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  const apiObj = {
                                    ...all_filters,
                                    metric_filter: metrics_filter_obj || [],
                                  };
                                  set_all_filters({
                                    page: 1,
                                    "per-page": DefaultPerPage,
                                    ...all_filters,
                                  });
                                  setLoading(true);
                                  GetProductsList({
                                    ...apiObj,
                                    page: 1,
                                    "per-page": DefaultPerPage,
                                    start_date: dayjs(
                                      all_filters?.start_date?.$d
                                    ).format("YYYY-MM-DD"),
                                    end_date: dayjs(
                                      all_filters?.end_date?.$d
                                    ).format("YYYY-MM-DD"),
                                    metric_filter:
                                      metrics_filter_obj?.length > 0
                                        ? JSON.stringify(metrics_filter_obj)
                                        : [],
                                    order_key: sortOrderColumns,
                                    order_value: sortFilters?.startsWith("-")
                                      ? "SORT_ASC"
                                      : "SORT_DESC",
                                  });
                                  setIsOpenMetricsPopover(false);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        }
                      >
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            if (metrics_filter_obj?.length === 0) {
                              set_metrics_filter_obj([
                                {
                                  attribute: "",
                                  operator: "",
                                  value: "",
                                },
                              ]);
                            }
                            setIsOpenMetricsPopover(true);
                          }}
                        >
                          <i className="ki-outline ki-plus fs-2" />
                          Metrics{" "}
                        </button>
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
              {/*end::Header*/}{" "}
              <div className="separator separator-dashed my-2"></div>
              {/*begin::Body*/}
              <div className="card-body py-2">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  <EsfTable
                    columns={columns?.map((d) => ({
                      ...d,
                      // width: d?.title?.length * 13,
                    }))}
                    sticky={{
                      offsetHeader: "0px",
                    }}
                    scroll={{
                      x: "max-content",
                      y: "calc(100vh - 230px)",
                    }}
                    fixed="top"
                    dataSource={list}
                    rowKey="key"
                    loading={loading}
                    pagination={false}
                    setSortFilters={setSortFilters}
                    sortFilters={sortFilters}
                    setSortOrderColumns={setSortOrderColumns}
                    onSortChange={(sort, currentPage, pageSize, order_key) => {
                      set_all_filters({
                        ...all_filters,
                        page: 1,
                        "per-page": DefaultPerPage,
                      });
                      setLoading(true);
                      GetProductsList({
                        ...all_filters,
                        page: 1,
                        "per-page": DefaultPerPage,
                        start_date: dayjs(all_filters?.start_date?.$d).format(
                          "YYYY-MM-DD"
                        ),
                        end_date: dayjs(all_filters?.end_date?.$d).format(
                          "YYYY-MM-DD"
                        ),
                        order_key: order_key,
                        order_value: sort?.startsWith("-")
                          ? "SORT_ASC"
                          : "SORT_DESC",
                      });
                    }}
                  />
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={all_filters?.["per-page"]}
                  page={all_filters?.page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </div>
      {campaignView && (
        <CampaignModal
          selectedCamp={selectedCamp}
          show={campaignView}
          onHide={() => setCampaignView(false)}
        />
      )}
    </>
  );
};

export default ProductAnalytics;
