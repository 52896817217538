import React, { useState } from "react";
import { CampaignWrapper } from "./style";
import SponsoredProducts from "./sponsored-products/sponsored-products";
import SponsoredBrand from "./sponsored-brand/sponsored-brand";
import SponsoredDisplay from "./sponsored-display/sponsored-display";
import SponsoredTV from "./sponsored-tv/sponsored-tv";

const CampaignCreation = (props) => {
  const { setMode, selected_marketplace, selected_currency_sign } = props;
  const [selectedType, setSelectedType] = useState("sp");
  return (
    <CampaignWrapper>
      <div className="row mb-5">
        <div className="col-md-3">
          <label
            className={`btn btn-outline btn-outline-dashed btn-active-gray-800  p-3 d-flex align-items-center ${
              selectedType === "sp" ? "CampaignTypeBtnSelected" : ""
            }`}
            onClick={() => {
              setSelectedType("sp");
            }}
          >
            <div className="symbol symbol-35px me-5">
              <div className="symbol-label fs-5 fw-bold bg-primary text-gray-100">
                SP
              </div>
            </div>
            <span className="d-block fw-semibold text-start">
              <span className="text-gray-800 fw-bold d-block fs-5">
                Sponsored Products
              </span>
            </span>
          </label>
        </div>
        <div className="col-md-3">
          <label
            className={`btn btn-outline btn-outline-dashed btn-active-gray-800  p-3 d-flex align-items-center ${
              selectedType === "sb" ? "CampaignTypeBtnSelected" : ""
            }`}
            onClick={() => {
              setSelectedType("sb");
            }}
          >
            <div className="symbol symbol-35px me-5">
              <div className="symbol-label fs-5 fw-bold bg-primary text-gray-100">
                SB
              </div>
            </div>
            <span className="d-block fw-semibold text-start">
              <span className="text-gray-800 fw-bold d-block fs-5">
                Sponsored Brands
              </span>
            </span>
          </label>
        </div>
        <div className="col-md-3">
          <label
            className={`btn btn-outline btn-outline-dashed btn-active-gray-800  p-3 d-flex align-items-center ${
              selectedType === "sd" ? "CampaignTypeBtnSelected" : ""
            }`}
            onClick={() => {
              setSelectedType("sd");
            }}
          >
            <div className="symbol symbol-35px me-5">
              <div className="symbol-label fs-5 fw-bold bg-primary text-gray-100">
                SD
              </div>
            </div>
            <span className="d-block fw-semibold text-start">
              <span className="text-gray-800 fw-bold d-block fs-5">
                Sponsored Display
              </span>
            </span>
          </label>
        </div>
        <div className="col-md-3">
          <label
            className={`btn btn-outline btn-outline-dashed btn-active-gray-800  p-3 d-flex align-items-center ${
              selectedType === "stv" ? "CampaignTypeBtnSelected" : ""
            }`}
            onClick={() => {
              setSelectedType("stv");
            }}
          >
            <div className="symbol symbol-35px me-5">
              <div className="symbol-label fs-5 fw-bold bg-primary text-gray-100">
                STV
              </div>
            </div>
            <span className="d-block fw-semibold text-start">
              <span className="text-gray-800 fw-bold d-block fs-5">
                Sponsored TV
              </span>
            </span>
          </label>
        </div>
      </div>
      {selectedType === "sp" && (
        <SponsoredProducts
          setMode={setMode}
          selected_marketplace={selected_marketplace}
          selected_currency_sign={selected_currency_sign}
        />
      )}
      {selectedType === "sb" && <SponsoredBrand setMode={setMode} />}
      {selectedType === "sd" && <SponsoredDisplay setMode={setMode} />}
      {selectedType === "stv" && <SponsoredTV setMode={setMode} />}
    </CampaignWrapper>
  );
};

export default CampaignCreation;
