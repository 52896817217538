import { Skeleton, message, notification } from "antd";
import { MakeApiCall } from "../apis/make-api-call";
import axios from "axios";

export const ENDPOINT = "https://api.bidbison.com/api/v1/";
export const PhotoUrl = "https://api.bidbison.com";
export const DateFormat = "MMM DD, YYYY";
export const DefaultPerPage = 10;
export const pageDropdown = [10, 25, 50, 75, 100];
export const getUserId = () => {
  return JSON.parse(localStorage.getItem("user_data"))?.id;
};

export const isNot2000SeriesYear = (date) => {
  const year = new Date(date).getFullYear();
  return year < 2000 || year > 2099;
};

export const urlDecode = (url) => {
  let newObj = {};
  url?.search
    ?.split("?")[1]
    ?.split("&")
    ?.forEach((e) => {
      const obj = e?.split("=");
      newObj = { ...newObj, [obj[0]]: obj[1] };
    });
  return newObj || {};
};

export const NumberWithCommas = (n) => {
  const num_ = parseFloat(n || 0);
  if (num_) {
    if (Number(num_) === num_ && num_ % 1 !== 0) {
      return parseFloat(num_)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return parseInt(num_)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return num_;
};

export const ConvertParams = (values) => {
  return Object.entries(
    Object.entries(values)
      .filter(([_, value]) => value)
      .reduce((acc, [key, value]) => {
        acc[key] = encodeURIComponent(value);
        return acc;
      }, {})
  )
    ?.map((d, i) => {
      return `${i === 0 ? "?" : "&"}${d[0]}=${d[1] || ""}`;
    })
    ?.join("");
};

export const DisplayNotification = (
  type,
  title,
  message,
  placement,
  closeIcon
) => {
  notification.destroy();
  setTimeout(() => {
    if (type === "error") {
      notification.error({
        message: title || "Error",
        description: message || "Something went wrong",
        placement: placement || "topRight", // Set placement to 'topRight'
        // closeIcon: closeIcon || null,
        showProgress: true,
        duration: 5,
        style: {
          backgroundColor: "#ffb7b7", // Change the background color
          borderRadius: "7px",
        },
      });
    } else if (type === "success") {
      notification.success({
        message: title || "Error",
        description: message || "Something went wrong",
        placement: placement || "topRight", // Set placement to 'topRight'
        // closeIcon: closeIcon || null,
        showProgress: true,
        duration: 5,
        style: {
          backgroundColor: "#cdefcb", // Change the background color
          borderRadius: "7px",
        },
      });
    } else if (type === "warning") {
      notification.warning({
        message: title || "Warning",
        description: message || "Something went wrong",
        placement: placement || "topRight", // Set placement to 'topRight'
        // closeIcon: closeIcon || null,
        showProgress: true,
        duration: 5,
        style: {
          backgroundColor: "#fef5e6", // Change the background color
          borderRadius: "7px",
        },
      });
    } else if (type === "info") {
      notification.info({
        message: title || "Info",
        description: message || "Something went wrong",
        placement: placement || "topRight", // Set placement to 'topRight'
        // closeIcon: closeIcon || null,
        showProgress: true,
        duration: 5,
        style: {
          backgroundColor: "#d8e7ff", // Change the background color
          borderRadius: "7px",
        },
      });
    }
  }, 500);
};

export const getTableLoading = (numberOfColumns) => {
  const numberOfRows = 10;
  const renderedRows = [...Array(numberOfRows)].map((e, i) => (
    <tr key={i}>
      {[...Array(numberOfColumns)]?.map((d) => {
        return (
          <td>
            <Skeleton.Button active block />
          </td>
        );
      })}
    </tr>
  ));
  return renderedRows;
};

export const timeSince = (date) => {
  const units = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  const seconds = Math.floor((new Date() - date * 1000) / 1000);

  for (let i = 0; i < units.length; i++) {
    const interval = seconds / units[i].seconds;
    if (interval > 1) {
      return (
        Math.floor(interval) +
        " " +
        units[i].label +
        (interval > 1 ? "s" : "") +
        " ago"
      );
    }
  }

  return "just now";
};

export const configMarketplaceObj = {
  A2Q3Y263D00KWC: "Amazon.com.br",
  A2EUQ1WTGCTBG2: "Amazon.ca",
  A1AM78C64UM0Y8: "Amazon.com.mx",
  ATVPDKIKX0DER: "Amazon.com",
  A2VIGQ35RCS4UG: "Amazon.ae",
  A1PA6795UKMFR9: "Amazon.de",
  A1RKKUPIHCS9HS: "Amazon.es",
  A13V1IB3VIYZZH: "Amazon.fr",
  A1F83G8C2ARO7P: "Amazon.uk",
  A21TJRUUN4KGV: "Amazon.in",
  APJ6JRA9NG5V4: "Amazon.it",
  A1805IZSGTT6HS: "Amazon.nl",
  A17E79C6D8DWNP: "Amazon.sa",
  A33AVAJ2PDY3EV: "Amazon.tr",
  A19VAU5U5O7RUS: "Amazon.sg",
  A1VC38T7YXB528: "Amazon.jp",
  AAHKV2X7AFYLW: "Amazon.cn",
};

export const downloadFile = (href, name) => {
  const link = document.createElement("a");
  link.href = `${href}${name}`;
  link.setAttribute("download", name);
  link.click();
  link.remove();
  setTimeout(() => {
    message.destroy();
  }, 2000);
};

export const GetMarketplaceDropdown = async () => {
  try {
    const response = await MakeApiCall(
      "marketplaces/get-marketplace-by-credential?credential_type=Advertising-API",
      "get",
      null,
      true
    );

    if (response?.status) {
      return {
        marketplace_list: response?.data?.marketplace_list,
        currency_list: response?.data?.currency_symbols,
      };
    } else {
      // Handle error case and notify
      DisplayNotification(
        "error",
        "Error",
        response?.message || "Unknown error occurred"
      );
      return null; // Use null to signify no data
    }
  } catch (error) {
    // Catch any unexpected errors
    DisplayNotification(
      "error",
      "Error",
      error.message || "An error occurred while fetching data"
    );
    return null; // Use null to signify no data
  }
};

export const GetMarketplaceList = (marketplaceList) =>
  marketplaceList.map((d, i) => {
    return {
      label: (
        <>
          <img
            src={`/assets/media/domainImage/${d?.marketplace_id}.png`}
            style={{ height: "20px" }}
            loading="lazy"
            className="me-3"
            onError={(e) => {
              e.target.src = "/assets/media/domainImage/red-flag.png";
            }}
          />
          {d?.marketplace}({d?.sales_channel})
        </>
      ),
      value: d?.marketplace_id,
    };
  });

export const DownloadFileUsingApi = (url, filename) => {
  axios({
    url,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      const blob = response.data;
      // setIsFileDownload(false);
      console.log(blob, "-----------------");
      if (validateBlobFile(blob)) {
        // Create a temporary URL for the blob
        const fileURL = URL.createObjectURL(blob);
        console.log(fileURL, "blob");
        // Create a link element
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = `${filename}.xlsx`; // Specify the desired filename

        // Simulate a click on the link to initiate the download
        message.destroy();
        message.success("File downloaded successfully");
        link.click();
        // Clean up the temporary URL
        URL.revokeObjectURL(fileURL);
      } else {
        message.destroy();
        handleBlobError(blob);
      }
    })
    .catch((error) => {
      // setIsFileDownload(false);
      message.destroy();
      message.error("Error in file");
    });
};
function validateBlobFile(blob) {
  if (
    blob.type !==
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    return false;
  }
  return true;
}

const handleBlobError = async (blob) => {
  try {
    console.log(blob, "blob");
    const newBlob = await blob.text();
    message.destroy();
    message.error(newBlob.split(".")?.[0]);
  } catch (error) {
    return "Invalid File";
    // Handle the error accordingly
  }
};

export const metrics_filter = {
  attributes_dd_data: {
    total_acos: "Total ACOS",
    clicks: "Clicks",
    impressions: "Impressions",
    spend: "Cost",
    sales: "Total Sales",
    RoAS: "RoAS",
    RoAS_per: "RoAS %",
    ACoS: "ACoS",
    unit_ordered: "Orders",
    SPC: "SPC",
    CPC: "CPC",
    CVR: "Conversion Rate",
    CPM: "CPM",
    SPM: "SPM",
    CTR: "CTR",
    sales14d: "Attributed Sales (14 Day)",
    ad_sales_percentage: "Ad Sales Percentage",
  },
  operators_dd_data: {
    ">=": ">=",
    "<=": "<=",
    ">": ">",
    "<": "<",
    "==": "=",
  },
};
