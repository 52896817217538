import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  .ant-pagination-item {
    color: #fff !important;
        background-color: transparent;
    border: 1px solid transparent !important;
    &:hover {
      color: #009ef7 !important;
    }
  }
  .ant-pagination-item-active {
    font-weight: 500;
    ${
      "" /* background: #7e78ef !important;
    border-color: transparent !important; */
    }
    color: #FFF !important;
    border-radius: 0.65rem !important;
    &:hover {
      color: #fff !important;
    }
  }
  .ant-pagination-item-link {
    color: #5e6278 !important;
    border-color: transparent !important;
    span {
      position: relative;
      top: -2px;
    }
    &:hover {
      color: #4318ff !important;
    }
  }
  .ant-pagination-item-active {
    ${
      "" /* background-color: #7e78ef !important;
    border-color: #7e78ef !important; */
    }
    color: #fff !important;
  }
  .ant-pagination-item-active {
    background-color: var(--bs-gray-100) !important;
    border-color: var(--bs-gray-400)  !important;
  }
  .ant-pagination-item {
    &:hover {
      color: #4318ff !important;
      background-color: rgb(239 127 177 / 6%) !important;
    }
  }
 .ant-pagination-item:hover {
    color: #ffffff !important;
    background-color: rgb(255 255 255 / 6%) !important;
        border-color: #525252 !important;
}
.ant-pagination .ant-pagination-item-active:hover a {
    color: #fff
  }
  .ant-select .ant-select-selector {
       background: #1B1C22;
    border-radius: 0.65rem;
    border: 1px solid #1B1C22 !important;
    color: #fff !important;
  }
  .ant-pagination-item-link span{
    top : 0px !important
  }
.ant-select .ant-select-arrow{
    color: #fff !important;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
    color: rgba(255, 255, 255, 1);
}

.ant-select-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 4px;
    color: rgba(255, 255, 255, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: Inter, Helvetica, sans-serif;
    position: absolute;
    top: -9999px;
    z-index: 1050;
    overflow: hidden;
    font-variant: initial;
    background-color:#1C1D22;
    border-radius: 8px;
    outline: none;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
}
  .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgb(255 255 255 / 88%);
    font-weight: 600;
    background-color: #0f1014a6;
}
.ant-select-dropdown .ant-select-item{
    color: rgb(255 255 255 / 88%);
    }
`;

export default Wrapper;
