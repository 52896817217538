// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/automation",
    icon: (
      <span class="menu-icon">
        <i class="ki-outline ki-chart-simple-2 fs-1"></i>
      </span>
    ),
    label: "Automation",
    children: [
      {
        key: "/automation/rule-book",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/automation/rule-book", "Rule Book"),
      },
      {
        key: "/automation/day-parting",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/automation/day-parting", "Day Parting"),
      },
      {
        key: "/automation/keyword-harvesting",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/automation/keyword-harvesting", "Keyword Harvesting"),
      },
      // {
      //   key: "/keyword-management/keyword",
      //   icon: (
      //     <span className="menu-bullet">
      //       <span className="bullet bullet-dot" />
      //     </span>
      //   ),
      //   label: GetLinks("/keyword-management/keyword", "Keywords"),
      // },
    ],
  },
];

// export default [
//   {
//     key: "/automation",
//     icon: (
//       <span class="menu-icon">
//         <i class="ki-outline ki-cheque fs-1"></i>
//       </span>
//     ),
//     label: GetLinks("/automation", "Rule Management"),
//   },
// ];
