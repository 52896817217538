import { lazy } from "react";
import Campaign from "../modules/pages/campaign-management";
import ProductAnalytics from "../modules/pages/product-analytics";
import Keyword from "../modules/pages/targeting-report/keyword-report";
import KeywordSearchTermsReport from "../modules/pages/targeting-report/keyword-search-term-report";
import ProductAudienceTargeting from "../modules/pages/targeting-report/product-audience-targeting";
import ProductTargetSearchterm from "../modules/pages/targeting-report/product-target-search-term";
import Portfolio from "../modules/pages/portfolio";
import AdGroupList from "../modules/pages/campaign-analytics-details/ad_group";
import ProductAdsList from "../modules/pages/campaign-analytics-details/products_ads";
export default [
  {
    path: "/campaign",
    component: <Campaign />,
    exact: true,
  },
  {
    path: "/portfolio",
    component: <Portfolio />,
    exact: true,
  },
  {
    path: "/product-ads-list",
    component: <ProductAdsList />,
    exact: true,
  },
  {
    path: "/product-list",
    component: <ProductAnalytics />,
    exact: true,
  },
  {
    path: "/ad-groups",
    component: <AdGroupList />,
    exact: true,
  },
  {
    path: "/keyword-report",
    component: <Keyword />,
    exact: true,
  },
  {
    path: "/keyword-search-terms-report",
    component: <KeywordSearchTermsReport />,
    exact: true,
  },
  {
    path: "/product-audience-targeting",
    component: <ProductAudienceTargeting />,
    exact: true,
  },
  {
    path: "/product-target-search-term",
    component: <ProductTargetSearchterm />,
    exact: true,
  },
];
