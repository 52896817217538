import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../commonContext";
import dayjs from "dayjs";
import {
  ConvertParams,
  DateFormat,
  DefaultPerPage,
  DisplayNotification,
  GetMarketplaceDropdown,
  GetMarketplaceList,
  NumberWithCommas,
} from "../../../config";
import { MakeApiCall } from "../../../apis/make-api-call";
import RangePickerESF from "../../../component/range-picker";
import { Button, Input, message, Popover, Select, Table, Tag } from "antd";
import NoDataTable from "../../../component/no-data-table";
import EsfTable from "../../../component/esf-table";
import Pagination from "../../../component/esf-table/lib/pagination";
import { metrics_filter } from "../../../config";

const AdGroupList = (props) => {
  const { selctedCampaignData, isVisible, setIsVisible } = props;
  const contextValue = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [list, set_list] = useState([]);
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [column_data_sum, set_column_data_sum] = useState({});
  const [adGroupState, setAdGroupState] = useState(null);

  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    search: "",
    campaign_name: "",
    marketplace_id: null,
    start_date: dayjs().add(-90, "d"),
    end_date: dayjs(),
  });

  // Metrics Filter Start
  const [isOpenMetricsPopover, setIsOpenMetricsPopover] = useState(false);
  const [metrics_filter_obj, set_metrics_filter_obj] = useState([]);
  console.log(selctedCampaignData, "-selctedCampaignData");
  // const [metrics_filter, set_metrics_filter] = useState({
  //   attributes_dd_data: {
  //     acos: "ACOS",
  //     roas: "ROAS",
  //     spends: "Spends",
  //     sales: "Total Sales",
  //     cpc: "CPC",
  //     cvr: "Conversion Rate",
  //     unit_ordered: "Orders",
  //     clicks: "Clicks",
  //     impressions: "Impressions",
  //     ctr: "CTR",
  //     ad_sales_percentage: "Ad Sales Percentage",
  //     total_sales: "Total Sales",
  //     total_orders: "Total Orders",
  //     total_acos: "Total ACOS",
  //   },
  //   operators_dd_data: {
  //     ">=": ">=",
  //     "<=": "<=",
  //     ">": ">",
  //     "<": "<",
  //     "==": "=",
  //   },
  // });
  // Metrics Filter Start

  const [sortFilters, setSortFilters] = useState("");
  const [sortOrderColumns, setSortOrderColumns] = useState("");

  useEffect(() => {
    localStorage.setItem("token_type", "");
    if (contextValue?.data?.user_type == 2) {
      if (localStorage.getItem("sub_user_list")) {
        GetMarketplace();
      } else {
        setLoading(false);
        DisplayNotification("error", "Error", "User not found");
      }
    } else {
      GetMarketplace();
    }
  }, [contextValue?.data?.selected_sub_user]);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_all_filters({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
        campaign_id: selctedCampaignData?.campaign_id,
      });
      GetAdGroupList({
        ...all_filters,
        start_date: dayjs(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: dayjs(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
        metric_filter:
          metrics_filter_obj?.length > 0
            ? JSON.stringify(metrics_filter_obj)
            : [],
        campaign_id: selctedCampaignData?.campaign_id,
        order_key: sortOrderColumns,
        order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
      });
    } else {
      setLoading(false);
    }
  };

  const GetAdGroupList = async (data) => {
    setLoading(true);
    const response = await MakeApiCall(
      `advertising/ad-group-list${ConvertParams(data)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      set_list(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
      set_column_data_sum(response?.data?.sum_of_column_data);
    } else {
      setLoading(false);
      // DisplayNotification("error", "Error", response?.message);
    }
  };
  console.log(list);

  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: e,
      "per-page": all_filters?.["per-page"],
      start_date: dayjs(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: dayjs(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
      metric_filter:
        metrics_filter_obj?.length > 0
          ? JSON.stringify(metrics_filter_obj)
          : [],
      order_key: sortOrderColumns,
      order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
    };
    GetAdGroupList(apiObj);
  };
  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: 1,
      "per-page": e,
      start_date: dayjs(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: dayjs(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
      metric_filter:
        metrics_filter_obj?.length > 0
          ? JSON.stringify(metrics_filter_obj)
          : [],
      order_key: sortOrderColumns,
      order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
    };
    GetAdGroupList(apiObj);
  };

  const UpdateAdGroupState = async (body) => {
    message.destroy();
    message.loading("Loading", 0);
    const response = await MakeApiCall(
      "advertising/update-ad-group",
      "put",
      body,
      true,
      false
    );
    if (response?.status) {
      message.destroy();
      set_all_filters({ ...all_filters, page: 1, "per-page": DefaultPerPage });
      setLoading(true);
      const apiObj = {
        ...all_filters,
        page: 1,
        "per-page": DefaultPerPage,
        start_date: dayjs(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: dayjs(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
        metric_filter:
          metrics_filter_obj?.length > 0
            ? JSON.stringify(metrics_filter_obj)
            : [],
        order_key: sortOrderColumns,
        order_value: sortFilters?.startsWith("-") ? "SORT_ASC" : "SORT_DESC",
      };
      GetAdGroupList(apiObj);
      DisplayNotification("success", "Success", response?.message);
    } else {
      message.destroy();
      const errorMsg = Object.entries(response?.data);
      DisplayNotification(
        "error",
        "Error",
        errorMsg?.[0]?.[1] || response?.message
      );
    }
  };

  const columns = [
    {
      title: "Ad Group Name",
      dataIndex: "ad_group_name",
      key: "ad_group_name",
      width: 200,
      sortOn: true,
    },
    {
      title: "Campaign",
      dataIndex: "campaign_name",
      key: "campaign_name",
      fixed: "left",
      sortOn: true,
      render: (text, record) => (
        <div className="d-flex flex-column">
          <Popover
            content={
              <div
                style={{
                  height: "max-content",
                  maxWidth: "400px",
                }}
              >
                {text}
              </div>
            }
            placement="bottom"
          >
            <span
              className="text-gray-900 mb-1 fw-bolder popoverActionIconSingleLine fs-6"
              style={{ width: "300px" }}
            >
              {text}
            </span>
          </Popover>
          <div className="d-flex flex-column">
            <span
              className="fs-7 cursor-pointer"
              // onClick={() => {
              //   window.open(`https://www.amazon.com/dp/${record?.asin1}`);
              // }}
            >
              <b>Status:</b>{" "}
              <span className="text-primary">{record?.campaign_status}</span>
            </span>{" "}
            <span className="fs-7">
              <b>Campaign Type:</b>{" "}
              <span className="text-primary">{record?.campaign_type}</span>
            </span>
          </div>
        </div>
      ),
      width: 300,
    },
    {
      title: "Ad Group State",
      dataIndex: "ad_status",
      key: "ad_status",
      width: 150,
      render: (text, record) => {
        return (
          <div className="d-flex align-items-center">
            <Tag
              className="fw-bolder"
              color={
                text === "ENABLED"
                  ? "green"
                  : text === "PAUSED"
                  ? "orange"
                  : "error"
              }
            >
              {text}
            </Tag>{" "}
            <Popover
              title="Update State"
              onOpenChange={() => {
                setAdGroupState("");
              }}
              content={
                <div className="text-center">
                  <Select
                    className="w-100"
                    options={
                      record?.campaign_type === "SPONSORED_PRODUCTS"
                        ? [
                            { label: "Enabled", value: "ENABLED" },
                            { label: "Paused", value: "PAUSED" },
                            { label: "Archived", value: "PROPOSED" },
                          ]
                        : record?.campaign_type === "SPONSORED_DISPLAY"
                        ? [
                            { label: "Enabled", value: "enabled" },
                            { label: "Paused", value: "paused" },
                            { label: "Archived", value: "archived" },
                          ]
                        : record?.campaign_type === "SPONSORED_BRANDS"
                        ? [
                            { label: "Enabled", value: "ENABLED" },
                            { label: "Paused", value: "PAUSED" },
                          ]
                        : []
                    }
                    value={adGroupState}
                    onChange={(e) => {
                      setAdGroupState(e);
                    }}
                  />

                  <button
                    className="btn btn-primary btn-sm mt-4"
                    onClick={() => {
                      const data = {
                        marketplace_id: selected_marketplace,
                        campaign_type: record?.campaign_type, // SPONSORED_PRODUCTS, SPONSORED_BRANDS, SPONSORED_DISPLAY
                        adGroup_id: record?.ad_group_id,
                        state: adGroupState,
                        // default_bid: "1.08",
                      };
                      message.destroy();
                      message.loading("Loading...", 0);
                      UpdateAdGroupState(data);
                    }}
                    disabled={!adGroupState}
                  >
                    Save
                  </button>
                </div>
              }
              trigger="click"
            >
              <i className="ki-outline ki-pencil fs-2 text-success" />
            </Popover>
          </div>
        );
      },
    },
    {
      title: "Default Bid",
      dataIndex: "defaultBid",
      key: "defaultBid",
      sortOn: true,
      width: 150,
      render: (value) => (
        <span>
          {selected_currency_sign} {NumberWithCommas(value)}
        </span>
      ),
    },
    {
      title: "Active Default Bid",
      dataIndex: "activeDefaultBid",
      key: "activeDefaultBid",
      sortOn: true,
      width: 170,
      render: (value) => (
        <span>
          {selected_currency_sign} {NumberWithCommas(value)}
        </span>
      ),
    },
    {
      title: "Labels",
      dataIndex: "labels",
      key: "labels",
      sortOn: true,
      width: 150,
      render: (labels) => <span>{labels}</span>,
    },

    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: 100,
      sortOn: true,
      render: (text) => <span className="">{NumberWithCommas(text)}</span>,
    },
    {
      title: "Cost",
      dataIndex: "spend",
      key: "spend",
      width: 100,
      sortOn: true,
      render: (text) => (
        <span className="">
          {selected_currency_sign}
          {NumberWithCommas(text)}
        </span>
      ),
    },
    {
      title: "ACoS",
      dataIndex: "ACoS",
      key: "ACoS",
      width: 100,
      sortOn: true,
      render: (text) => <span className="">{NumberWithCommas(text)}%</span>,
    },
    {
      title: "Cost-per-click (CPC)",
      dataIndex: "CPC",
      key: "CPC",
      width: 200,
      sortOn: true,
      render: (text) => {
        return (
          <span>
            {selected_currency_sign}
            {text || "0"}
          </span>
        );
      },
    },
    {
      title: "Cost-per-mile(CPM)",
      dataIndex: "CPM",
      key: "CPM",
      width: 200,
      sortOn: true,
      render: (text) => {
        return (
          <span>
            {" "}
            {selected_currency_sign} {NumberWithCommas(text)}
          </span>
        );
      },
    },
    {
      title: "Click-through rate for views (CTR)",
      dataIndex: "CTR",
      key: "CTR",
      width: 280,
      sortOn: true,
      render: (text) => {
        return (
          <span>
            {selected_currency_sign} {NumberWithCommas(text)}
          </span>
        );
      },
    },
    {
      title: "Return on ad spend (ROAS)",
      dataIndex: "RoAS",
      key: "RoAS",
      width: 250,
      sortOn: true,

      render: (text, record) => {
        return (
          <span>
            {selected_currency_sign}
            {text}
          </span>
        );
      },
    },
    {
      title: "RoAS per",
      dataIndex: "RoAS_per",
      key: "RoAS_per",
      width: 130,
      sortOn: true,
      render: (text, record) => {
        return <span>{NumberWithCommas(text)}%</span>;
      },
    },
    {
      title: "SPC",
      dataIndex: "SPC",
      key: "SPC",
      width: 120,
      sortOn: true,
      render: (text, record) => {
        return <span>{NumberWithCommas(text)}%</span>;
      },
    },
    {
      title: "SPM",
      dataIndex: "SPM",
      key: "SPM",
      width: 120,
      sortOn: true,
      render: (text, record) => {
        return <span>{NumberWithCommas(text)}%</span>;
      },
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      width: 130,
      sortOn: true,
      render: (text) => <span className="">{NumberWithCommas(text)}</span>,
    },
    {
      title: "Attributed Sales (14 Day)",
      dataIndex: "sales14d",
      key: "sales14d",
      width: 220,
      sortOn: true,
      render: (text) => (
        <span className="">
          {selected_currency_sign}
          {NumberWithCommas(text)}
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid" id="kt_content_container">
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0">
                <div className="d-flex flex-stack flex-wrap gap-4 p-2">
                  <div className="position-relative">
                    <Input
                      style={{ width: "300px" }}
                      prefix={
                        <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                      }
                      className="form-control d-flex"
                      placeholder="Search by Ad Group"
                      size="large"
                      value={all_filters?.search}
                      onChange={(e) => {
                        const apiObj = {
                          ...all_filters,
                          search: e.target.value,
                        };
                        set_all_filters(apiObj);
                      }}
                      onPressEnter={(e) => {
                        const apiObj = {
                          ...all_filters,
                          page: 1,
                          "per-page": DefaultPerPage,
                          search: e.target.value,
                          start_date: dayjs(all_filters?.start_date?.$d).format(
                            "YYYY-MM-DD"
                          ),
                          end_date: dayjs(all_filters?.end_date?.$d).format(
                            "YYYY-MM-DD"
                          ),
                          metric_filter:
                            metrics_filter_obj?.length > 0
                              ? JSON.stringify(metrics_filter_obj)
                              : [],
                          order_key: sortOrderColumns,
                          order_value: sortFilters?.startsWith("-")
                            ? "SORT_ASC"
                            : "SORT_DESC",
                        };
                        setLoading(true);
                        GetAdGroupList(apiObj);
                      }}
                    />
                  </div>
                </div>
                <div className="card-toolbar">
                  <div class="d-flex flex-stack flex-wrap gap-4">
                    <div className="postition-relative">
                      <Input
                        style={{ width: "300px" }}
                        prefix={
                          <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                        }
                        className="form-control d-flex"
                        placeholder="Search by Campaign Name"
                        size="large"
                        value={all_filters?.campaign_name}
                        onChange={(e) => {
                          const apiObj = {
                            ...all_filters,
                            campaign_name: e.target.value,
                          };
                          set_all_filters(apiObj);
                        }}
                        onPressEnter={(e) => {
                          const apiObj = {
                            ...all_filters,
                            page: 1,
                            "per-page": DefaultPerPage,
                            campaign_name: e.target.value,
                            start_date: dayjs(
                              all_filters?.start_date?.$d
                            ).format("YYYY-MM-DD"),
                            end_date: dayjs(all_filters?.end_date?.$d).format(
                              "YYYY-MM-DD"
                            ),
                            metric_filter:
                              metrics_filter_obj?.length > 0
                                ? JSON.stringify(metrics_filter_obj)
                                : [],
                            order_key: sortOrderColumns,
                            order_value: sortFilters?.startsWith("-")
                              ? "SORT_ASC"
                              : "SORT_DESC",
                          };
                          setLoading(true);
                          GetAdGroupList(apiObj);
                        }}
                      />
                    </div>
                    <div className="position-relative ">
                      <RangePickerESF
                        className="ant_common_input form-control d-flex"
                        id="aalacart_date_picker"
                        size="large"
                        allowClear={false}
                        style={{ width: "250px" }}
                        format={DateFormat}
                        value={[all_filters?.start_date, all_filters?.end_date]}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onChange={(e) => {
                          const filters_ = all_filters;
                          filters_.start_date = e?.[0];
                          filters_.end_date = e?.[1];
                          set_all_filters({
                            ...filters_,
                            page: 1,
                            "per-page": DefaultPerPage,
                          });
                          const apiObj = {
                            ...all_filters,
                            page: 1,
                            "per-page": DefaultPerPage,
                            start_date: dayjs(e?.[0]?.$d).format("YYYY-MM-DD"),
                            end_date: dayjs(e?.[1]?.$d).format("YYYY-MM-DD"),
                            metric_filter:
                              metrics_filter_obj?.length > 0
                                ? JSON.stringify(metrics_filter_obj)
                                : [],
                            order_key: sortOrderColumns,
                            order_value: sortFilters?.startsWith("-")
                              ? "SORT_ASC"
                              : "SORT_DESC",
                          };
                          setLoading(true);
                          GetAdGroupList(apiObj);
                        }}
                      />
                    </div>
                    <div className="position-relative me-2">
                      <Select
                        className="w-200px"
                        size="large"
                        placeholder="Select Marketplace"
                        options={GetMarketplaceList(marketplace_list)}
                        value={selected_marketplace}
                        onChange={(e) => {
                          set_selected_marketplace(e);
                          set_selected_currency_sign(currency_list[e]);
                          set_all_filters({
                            ...all_filters,
                            page: 1,
                            "per-page": DefaultPerPage,
                            marketplace_id: e,
                          });
                          const apiObj = {
                            page: 1,
                            "per-page": DefaultPerPage,
                            marketplace_id: e,
                            start_date: dayjs(
                              all_filters?.start_date?.$d
                            ).format("YYYY-MM-DD"),
                            end_date: dayjs(all_filters?.end_date?.$d).format(
                              "YYYY-MM-DD"
                            ),
                            metric_filter:
                              metrics_filter_obj?.length > 0
                                ? JSON.stringify(metrics_filter_obj)
                                : [],
                            order_key: sortOrderColumns,
                            order_value: sortFilters?.startsWith("-")
                              ? "SORT_ASC"
                              : "SORT_DESC",
                          };
                          setLoading(true);
                          GetAdGroupList(apiObj);
                        }}
                      />
                    </div>
                    <div className="position-relative me-2">
                      <Popover
                        placement="bottom"
                        trigger="click"
                        open={isOpenMetricsPopover}
                        content={
                          <div className="">
                            {metrics_filter_obj?.map((d, index) => {
                              return (
                                <div className="mt-3 d-flex">
                                  <Select
                                    placeholder="Select Metric"
                                    allowClear
                                    options={Object.entries(
                                      metrics_filter?.attributes_dd_data
                                    )?.map((d) => {
                                      return {
                                        label: d[1],
                                        value: d[0],
                                      };
                                    })}
                                    value={d?.attribute}
                                    onChange={(e) => {
                                      set_metrics_filter_obj((prev) => {
                                        return prev?.map((d, mindex) => {
                                          if (mindex === index) {
                                            return {
                                              ...d,
                                              attribute: e,
                                            };
                                          }
                                          return d;
                                        });
                                      });
                                    }}
                                    style={{ width: "200px" }}
                                  />
                                  <Select
                                    className="ms-1"
                                    allowClear
                                    options={Object.entries(
                                      metrics_filter?.operators_dd_data
                                    )?.map((d) => {
                                      return {
                                        label: d[1],
                                        value: d[0],
                                      };
                                    })}
                                    value={d?.operator}
                                    onChange={(e) => {
                                      set_metrics_filter_obj((prev) => {
                                        return prev?.map((d, mindex) => {
                                          if (mindex === index) {
                                            return {
                                              ...d,
                                              operator: e,
                                            };
                                          }
                                          return d;
                                        });
                                      });
                                    }}
                                    style={{ width: "100px" }}
                                    placeholder="Select Condition"
                                  />
                                  <Input
                                    style={{ width: "120px" }}
                                    className="ms-1"
                                    value={d?.value}
                                    onChange={(e) => {
                                      set_metrics_filter_obj((prev) => {
                                        return prev?.map((d, mindex) => {
                                          if (mindex === index) {
                                            return {
                                              ...d,
                                              value: e.target.value,
                                            };
                                          }
                                          return d;
                                        });
                                      });
                                    }}
                                    placeholder="Enter Value"
                                  />
                                  {metrics_filter_obj?.length > 1 && (
                                    <span
                                      className="btn btn-icon btn-light-primary btn-outline w-30px h-30px position-relative border-0 cursor-pointer ms-1"
                                      onClick={() => {
                                        const deletedData =
                                          metrics_filter_obj?.filter(
                                            (d, i) => i !== index
                                          );
                                        set_metrics_filter_obj(deletedData);
                                      }}
                                    >
                                      <i className="ki-outline ki-cross fs-2" />
                                    </span>
                                  )}
                                </div>
                              );
                            })}
                            <div className="text-center">
                              <button
                                className="btn btn-light-primary btn-sm mt-5"
                                onClick={() => {
                                  set_metrics_filter_obj([
                                    ...metrics_filter_obj,
                                    {
                                      attribute: null,
                                      operator: null,
                                      value: "",
                                    },
                                  ]);
                                }}
                              >
                                <i className="ki-outline ki-plus fs-2" />
                                Add another
                              </button>
                            </div>

                            <div className="separator separator-dashed my-2"></div>
                            <div className="d-flex justify-content-between">
                              <button
                                className="btn btn-light-danger btn-sm"
                                onClick={() => {
                                  set_metrics_filter_obj([]);
                                  const apiObj = {
                                    ...all_filters,
                                    metric_filter: [],
                                  };
                                  set_all_filters({
                                    page: 1,
                                    "per-page": DefaultPerPage,
                                    ...all_filters,
                                  });
                                  setLoading(true);
                                  GetAdGroupList({
                                    ...apiObj,
                                    page: 1,
                                    "per-page": DefaultPerPage,
                                    start_date: dayjs(
                                      all_filters?.start_date?.$d
                                    ).format("YYYY-MM-DD"),
                                    end_date: dayjs(
                                      all_filters?.end_date?.$d
                                    ).format("YYYY-MM-DD"),
                                    metric_filter: [],
                                    order_key: sortOrderColumns,
                                    order_value: sortFilters?.startsWith("-")
                                      ? "SORT_ASC"
                                      : "SORT_DESC",
                                  });
                                  setIsOpenMetricsPopover(false);
                                }}
                              >
                                {/* <i className="ki-outline ki-cross fs-2" /> */}
                                Clear all
                              </button>
                              <button
                                disabled={
                                  metrics_filter_obj.filter(
                                    (item) =>
                                      !item.attribute ||
                                      !item.operator ||
                                      !item.value
                                  )?.length > 0
                                }
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  const apiObj = {
                                    ...all_filters,
                                    metric_filter: metrics_filter_obj || [],
                                  };
                                  set_all_filters({
                                    page: 1,
                                    "per-page": DefaultPerPage,
                                    ...all_filters,
                                  });
                                  setLoading(true);
                                  GetAdGroupList({
                                    ...apiObj,
                                    page: 1,
                                    "per-page": DefaultPerPage,
                                    start_date: dayjs(
                                      all_filters?.start_date?.$d
                                    ).format("YYYY-MM-DD"),
                                    end_date: dayjs(
                                      all_filters?.end_date?.$d
                                    ).format("YYYY-MM-DD"),
                                    metric_filter:
                                      metrics_filter_obj?.length > 0
                                        ? JSON.stringify(metrics_filter_obj)
                                        : [],
                                    order_key: sortOrderColumns,
                                    order_value: sortFilters?.startsWith("-")
                                      ? "SORT_ASC"
                                      : "SORT_DESC",
                                  });
                                  setIsOpenMetricsPopover(false);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        }
                      >
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            if (metrics_filter_obj?.length === 0) {
                              set_metrics_filter_obj([
                                {
                                  attribute: "",
                                  operator: "",
                                  value: "",
                                },
                              ]);
                            }
                            setIsOpenMetricsPopover(true);
                          }}
                        >
                          <i className="ki-outline ki-plus fs-2" />
                          Metrics{" "}
                        </button>
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
              {/*end::Header*/}{" "}
              <div className="separator separator-dashed my-2"></div>
              {/*begin::Body*/}
              <div className="card-body py-2">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  <EsfTable
                    columns={columns?.map((d) => ({
                      ...d,
                      // width: d?.title?.length * 13,
                    }))}
                    sticky={{
                      offsetHeader: "0px",
                    }}
                    scroll={{
                      x: "max-content",
                      y: "calc(100vh - 230px)",
                    }}
                    fixed="top"
                    dataSource={list}
                    rowKey="key"
                    loading={loading}
                    pagination={false}
                    setSortFilters={setSortFilters}
                    sortFilters={sortFilters}
                    setSortOrderColumns={setSortOrderColumns}
                    onSortChange={(sort, currentPage, pageSize, order_key) => {
                      set_all_filters({
                        ...all_filters,
                        page: 1,
                        "per-page": DefaultPerPage,
                      });
                      setLoading(true);
                      GetAdGroupList({
                        ...all_filters,
                        page: 1,
                        "per-page": DefaultPerPage,
                        start_date: dayjs(all_filters?.start_date?.$d).format(
                          "YYYY-MM-DD"
                        ),
                        end_date: dayjs(all_filters?.end_date?.$d).format(
                          "YYYY-MM-DD"
                        ),
                        order_key: order_key,
                        order_value: sort?.startsWith("-")
                          ? "SORT_ASC"
                          : "SORT_DESC",
                      });
                    }}
                    summary={() => (
                      <Table.Summary fixed>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0}>
                            <label className="fw-bold fs-5">
                              Total {totalPage} Ad groups
                            </label>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            index={1}
                            colSpan={4}
                          ></Table.Summary.Cell>
                          <Table.Summary.Cell index={2}>
                            {column_data_sum?.clicks}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={3}>
                            <label className="fw-bold fs-5">
                              {selected_currency_sign}
                              {column_data_sum?.spend}
                            </label>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={4}>
                            <label className="fw-bold fs-5">
                              {column_data_sum?.ACoS} %
                            </label>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={5}>
                            <label className="fw-bold fs-5">
                              {selected_currency_sign} {column_data_sum?.CPC}
                            </label>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={6}>
                            <label className="fw-bold fs-5">
                              {selected_currency_sign} {column_data_sum?.CPM}
                            </label>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={7}>
                            <label className="fw-bold fs-5">
                              {selected_currency_sign} {column_data_sum?.CTR}
                            </label>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={8}>
                            <label className="fw-bold fs-5">
                              {selected_currency_sign} {column_data_sum?.RoAS}
                            </label>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={9}>
                            <label className="fw-bold fs-5"></label>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={10}>
                            <label className="fw-bold fs-5">
                              {column_data_sum?.SPC} %
                            </label>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={11}>
                            <label className="fw-bold fs-5">
                              {column_data_sum?.SPM} %
                            </label>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={12}>
                            <label className="fw-bold fs-5">
                              {column_data_sum?.impressions}
                            </label>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={13}>
                            <label className="fw-bold fs-5">
                              {selected_currency_sign} {column_data_sum?.sales}
                            </label>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            index={13}
                            colSpan={2}
                          ></Table.Summary.Cell>
                          <Table.Summary.Cell index={14}></Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>
                    )}
                  />
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={all_filters?.["per-page"]}
                  page={all_filters?.page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </div>
    </>
  );
};

export default AdGroupList;
