import React from "react";

const SPSteps = (props) => {
  const { selectedStep, setSelectedStep, campaign_data_list } = props;
  return (
    <>
      <div
        className="d-flex flex-row-auto flex-column w-100 w-lg-250px border border-dashed border-gray-200 p-8 me-10 rounded"
        style={{ position: "sticky", top: 100 }}
      >
        <div className="mb-10">
          <h3>Campaign Creation</h3>
        </div>

        <div className="stepper-nav flex-cente ">
          <div
            className={`stepper-item me-5 ${
              selectedStep === "step1" && "current"
            }`}
            data-kt-stepper-element="nav"
          >
            <div className="stepper-wrapper d-flex align-items-center">
              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check" />
                <span className="stepper-number">1</span>
              </div>

              <div className="stepper-label">
                <h3 className="stepper-title">Step 1</h3>
                <div className="stepper-desc">Create Campaign</div>
              </div>
            </div>

            <div className="stepper-line h-40px" />
          </div>

          <div
            className={`stepper-item me-5 ${
              selectedStep === "step2" && "current"
            }`}
            data-kt-stepper-element="nav"
          >
            <div className="stepper-wrapper d-flex align-items-center">
              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check" />
                <span className="stepper-number">2</span>
              </div>

              <div className="stepper-label">
                <h3 className="stepper-title">Step 2</h3>
                <div className="stepper-desc">Add Items</div>
              </div>
            </div>

            <div className="stepper-line h-40px" />
          </div>
          <div
            className={`stepper-item me-5 ${
              selectedStep === "step3" && "current"
            }`}
            data-kt-stepper-element="nav"
          >
            <div className="stepper-wrapper d-flex align-items-center">
              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check" />
                <span className="stepper-number">3</span>
              </div>

              <div className="stepper-label">
                <h3 className="stepper-title">Step 3</h3>
                <div className="stepper-desc">Add Ad Group</div>
              </div>
            </div>

            <div className="stepper-line h-40px" />
          </div>

          <div
            className={`stepper-item me-5 ${
              selectedStep === "step4" && "current"
            }`}
            data-kt-stepper-element="nav"
          >
            <div className="stepper-wrapper d-flex align-items-center">
              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check" />
                <span className="stepper-number">4</span>
              </div>

              <div className="stepper-label">
                <h3 className="stepper-title">Step 4</h3>
                <div className="stepper-desc">Negative Targeting</div>
              </div>
            </div>
          </div>
          {campaign_data_list?.targeting_type === "MANUAL" && (
            <>
              <div className="stepper-line h-40px" />{" "}
              <div
                className={`stepper-item me-5 ${
                  selectedStep === "step5" && "current"
                }`}
                data-kt-stepper-element="nav"
              >
                <div className="stepper-wrapper d-flex align-items-center">
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check" />
                    <span className="stepper-number">5</span>
                  </div>

                  <div className="stepper-label">
                    <h3 className="stepper-title">Step 5</h3>
                    <div className="stepper-desc">Manual Targeting</div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SPSteps;
